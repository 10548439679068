import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Verify_Email } from "../action/user.action";
import { Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
const Email_Verification = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const [linKStatus, setLinkStatus] = useState(false);

  useEffect(() => {
    VerifyEmail();
  }, [params]);
  const VerifyEmail = async () => {
    try {
      const { status, result } = await Verify_Email(params);
      console.log(status, result);
      if (status) {
        navigate("/login", { state: { result: result.result } });
      } else {
        setLinkStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>

      {linKStatus && (
        <section className="login_page confrim_mail">
          {/* <div className="login"> */}
          {/* <div className="left" > */}
          <div className="login_content">
            <h4 className="mb-4">Link Expired </h4>
            <Alert
              key={"primary"}
              variant={"primary"}
              className="p-2 alert_email"
            >
              <i className="me-2 fa fa-info-circle"></i>
              This link has expired , please click the updated link
            </Alert>
            <div className="text-center mt-4">
              <Link to="/login" className="primary_btn">
                Sign In
              </Link>
            </div>
            {/* </div> */}
            {/* </div> */}
            {/* <div className="right d-none d-lg-flex">
    
                            </div> */}
          </div>
        </section>
      )}

      {/* <br></br>     <br></br>     <br></br><br></br>     <br></br>      */}
      <Footer />
    </>
  );
};

export default Email_Verification;
