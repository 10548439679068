

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSingleWebinarDetails, getWebinarDetails, searchMeetings } from "../../action/user.action";


const initialState = {}

export const Single_Webinar_Details = createAsyncThunk('UserWebinar', async (data) => {
    const { status, result } = await getSingleWebinarDetails(data)
    if (status) {
        return result.result
    }
})


const UserWebinarSlice = createSlice({
    name: "UserWebinar",
    initialState,
    reducers: {
        setSingleWebinar: (state, action) => {
            state = action.payload
            return state
        },
    },
    extraReducers: (build) => {
        build.addCase(Single_Webinar_Details.fulfilled, (state, action) => {
            state = action.payload
            return state
        })
    },
})

export const { setSingleWebinar } = UserWebinarSlice.actions

export default UserWebinarSlice.reducer