import React from "react";
import Innerpagenavbar from "../components/innerpage-navbar";
import Innerfooter from "../components/inner-footer";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import { height, width } from "@fortawesome/free-solid-svg-icons/fa0";
function Settings() {
  return (
    <div className="dashboard-page">
      <div className="dashboard">
        <Innerpagenavbar />
        <div className="dashbord-right mt-5">
          <div className="dashbord-right-body-1">
            <div className="white-box1 Settings-page">
              <Row className="mb-5">
                <Col xl="2" lg="2" md="3" sm="4" className="p-0">
                  <div className="profile-edit1">
                    <img
                      src={require("../assets/images/profile-img.png")}
                      className="img-fluid blur_img"
                      style={{ width: "120px", height: "120px" }}
                    />

                    <img
                      src={require("../assets/images/edit.png")}
                      className="img-fluid edit-btn"
                    />
                  </div>
                </Col>
                <Col
                  xl="3"
                  lg="3"
                  md="3"
                  sm="5"
                  className="p-0 setting-profile-detail"
                >
                  <div className="setting-input ">
                    <Form.Label>Email address</Form.Label>
                    <InputGroup className="mb-4">
                      <InputGroup.Text id="basic-addon1">
                        {" "}
                        <img
                          src={require("../assets/images/envelope_img.png")}
                          className="img-fluid "
                        />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Email address"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </div>
                  <Link to="#" className="primary_btn">
                    <img
                      src={require("../assets/images/chevron-right.png")}
                      height="16"
                      width="16"
                      className="img-fluid"
                    />{" "}
                    Enable MFA
                  </Link>
                </Col>
                <Col xl="4" lg="4" md="5" className="p-0">
                  <div className="bg-blue">
                    <h6>
                      Support code (Do not share){" "}
                      <span>
                        <img
                          src={require("../assets/images/icon_4.png")}
                          className="img-fluid edit-btn"
                        />
                      </span>
                    </h6>
                    <h3>U3NBSN</h3>
                    <p>Reset code</p>
                  </div>
                </Col>
              </Row>
              <div>
                <h5 className="heading_Business">
                  Business and Contact details
                </h5>
                <div className="setting-input Contact_details">
                  <InputGroup className="mb-2">
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <img
                        src={require("../assets/images/person_img.png")}
                        className="img-fluid "
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="First name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <InputGroup className="mb-2">
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <img
                        src={require("../assets/images/person_img.png")}
                        className="img-fluid "
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Last name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>

                  <Form.Select
                    id="webinar_lang"
                    aria-label="Default select example"
                    className="mb-2"
                  >
                    <option>English</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>

                  <InputGroup className="mb-2">
                    <InputGroup.Text id="basic-addon1">
                      {" "}
                      <img
                        src={require("../assets/images/company_img.png")}
                        className="img-fluid "
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Company Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </div>
                <div className="setting-input Contact_details ">
                  <div>
                    <label> Business</label>
                    <p>
                      <span>
                        <img
                          src={require("../assets/images/check-circle.png")}
                          className="img-fluid "
                        />
                      </span>
                      I'm a business customer with a Tax ID
                    </p>
                  </div>
                  <div className="Contact_details_width">
                    {" "}
                    <Form.Label>Sales tax ID</Form.Label>{" "}
                    <InputGroup>
                      <Form.Control
                        placeholder="Sales Tax ID (TIN)"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Innerfooter />
        </div>
      </div>
    </div>
  );
}
export default Settings;
