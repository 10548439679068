import React, { useEffect, useMemo, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../../../components/innerpage-navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LanguagesSelect from "react-languages-select";
import "react-languages-select/css/react-languages-select.css";
import { decryptString, encryptString, isEmpty } from "../../../helper/helper";
import { Button } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

import {
  Basic_validation,
  persenter_validation,
  schedule_Valid,
  sessionValid,
  session_recurringValid,
  session_singleValid,
} from "../../../lib/validation";
import { Modal } from "react-bootstrap";

import {
  Create_Webinar,
  add_webinar_schedule,
  getWebinarDetails,
  editEmailTemplate,
  update_Webinar,
  getWebinarData,
  editWebinerBasic,
  editWebinarSchedule,
  getMailByID,
  checkForEmail_verification,
} from "../../../action/user.action";
import { toastAlert } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  Webinar_Details,
  getWebinar,
  setWebinar,
} from "../../../redux/features/webinar_details";

import Datetime from "react-datetime";

import "react-datetime/css/react-datetime.css";

import moment from "moment";

import config from "../../../lib/config";
import Innerfooter from "../../../components/inner-footer";
import PropagateLoader from "react-spinners/PropagateLoader";
import timezones from "timezones-list";

import * as timeconverter from "moment-timezone";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import { toast } from "react-toastify";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

const initalForm = {
  Webinar_title: "",
  Webinar_language: "",
  Webinar_description: "",
  Webinar_name: "",
  edit: false,
};

const initalsession = {
  sessionType: "",
  sessionstartDate: "",
  timeZone: "",
  Day: "",
  recurringLimt: "0",
  NoOfSession: "",
  sessionid: "",
};

const WebinarpageEdit = () => {
  const options = [];
  timezones.forEach(function (value, key) {
    let obj = { name: value.label, value: value.tzCode };
    options.push(obj);
  });

  const { id } = useParams();
  const { state } = useLocation();

  const editorRef = useRef(null);

  const languageRef = useState(null);

  const fileRef = useRef();
  const dispatch = useDispatch();
  const [activeBody, setActiveBody] = useState(null);

  const handleBodyClick = (bodyId) => {
    console.log(bodyId, "bodyIdbodyIdbodyId");
    setActiveBody(bodyId);
  };

  console.log(timezones, "timezones");

  // const [timeZone,setTimeZone]=useState(timezones.map())

  const [edit_presenter, setEdit_Presenter] = useState(true);
  const [isOTPSent, setIsotpSent] = useState(false);

  const [selected, setSelected] = useState("");

  const [previewUrl, setpreviewUrl] = useState("");

  const [imageFIle, setImageFile] = useState("");

  const [showBasic_edit, setShow_Basicedit] = useState(true);
  const [show_schedule_edit, set_show_schedule_edit] = useState(true);
  const [show_notification_edit, set_show_notification_edit] = useState(true);

  const webinarDetails = useSelector((state) => state.Webinar);

  console.log(webinarDetails, "hhhhhhhhhhh");

  const navigate = useNavigate();

  const [initialState, setInitialState] = useState(initalForm);

  const [schedule, setSchedule] = useState({});

  const { Webinar_title, Webinar_language, Webinar_description, Webinar_name } =
    initialState;

  const [values, setValues] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });
  const [sesseionDate, setSessionDate] = useState();

  const [newPresenter, setnewPresenter] = useState({
    preview: "",
    profile_image: "",
    Presenters_name: "",
    Presenters_email: "",
    edit: false,
  });

  const { preview, profile_image, Presenters_name, Presenters_email } =
    newPresenter;

  const [error, setError] = useState();
  const [selectedSession, setSelectedSession] = useState(null);
  const [isSession, setisSession] = useState(false);

  const [AddedSession, setAddedSession] = useState([]);
  const [newSession, setNewSession] = useState({});
  const [EditData, setEditData] = useState([]);
  const [schedule_edit_index, setSchedule_Edit_index] = useState("");

  const [sessionCtrl, setsessionCtrl] = useState(initalsession);

  const [completdshow, setCompletedShow] = useState(false);

  const handleCompleteClose = () => setCompletedShow(false);
  const handleCompleteShow = () => setCompletedShow(true);

  const completeWebinar = () => {
    //navigate("/webinar");
    window.location.href = `/webinar`;
  };

  const [initialDate, setInitialDate] = useState();
  const {
    sessionType,
    sessionstartDate,
    timeZone,
    sessionid,
    Day,
    recurringLimt,
    NoOfSession,
  } = sessionCtrl;

  const [email, setEmail] = useState(false);
  const [show, setShow] = useState(false);

  const [templateData, setTemplateData] = useState();
  const [responseData, setResponseData] = useState(null);

  const [defaultAcc, setdefaultAcc] = useState(null);

  const [loading, setloading] = useState(false);

  const handleEmailClose = () => setEmail(false);
  const handleEmailShow = () => setEmail(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSessionSelect = (session) => {
    console.log(session, "sessionsession");
    setSelectedSession(session);
    setInitialState({ ...initialState, schedule_type: session });
    setsessionCtrl({ ...sessionCtrl, sessionType: session });
    setAddedSession([]);
    setSchedule({ schedule_type: session });
  };

  // useEffect(() => {
  //   if (webinarDetails) {
  //     console.log(webinarDetails)
  //     setValues(webinarDetails.Presenters)
  //   }
  // }, [])

  const [startDate, setStartDate] = useState(new Date());

  const Changes_Lang_Handler = (value) => {
    setInitialState({ ...initialState, Webinar_language: value });
  };

  const changesHandler = async (e) => {
    const { id, value } = e.target;
    setInitialState({ ...initialState, [id]: value });
  };

  console.log(state, "typetypetype-100");

  const addClick = async () => {
    values.isOTPSent = isOTPSent;
    const dat = await persenter_validation(values);
    console.log(dat, values, "newPresenter");
    if (isEmpty(dat)) {
      const payload = {
        email: values.Presenters_email,
        webinarid: id,
      };

      if (values.otp && isOTPSent) {
        payload.OTP = values.otp;
      }

      const { status, result } = await checkForEmail_verification(payload);

      console.log(status, result, "status,result");
      if (status) {
        if (result.isMailSent) {
          setIsotpSent(true);
          toastAlert("success", result.message);
        } else {
          if (edit_presenter) {
            newPresenter.edit = true;
          }
          // setValues([...values, newPresenter]);
          setnewPresenter(values);
          setValues({
            preview: "",
            image: "",
            Presenters_name: "",
            Presenters_email: "",
            edit: false,
          });
          setEdit_Presenter(false);
          setpreviewUrl(newPresenter.preview);
          setError();
          setIsotpSent(false);
          toastAlert("success", "Presenter verified");
        }
      } else {
        toastAlert("error", result.message);
      }
    } else {
      setError(dat);
    }
  };

  console.log(values, "values11111111111111", previewUrl);

  console.log(selectedSession, "selectedSession", schedule);

  const scheduleHandler = async (e) => {
    const { id, value } = e.target;

    console.log(id, value, "id, value ");
    if (id == "all_timezone") {
      setSchedule({ ...schedule, [id]: !schedule.all_timezone });
    } else {
      setSchedule({ ...schedule, [id]: value });
    }
  };

  const sessionHandler = async (e) => {
    console.log(new Date(), "sessionHandler");
    console.log(new Date(e._d), "sessionHandler");
    const data = moment(e._d).format("LLL");
    console.log(data, "sessionHandler");
    setSessionDate(new Date(e._d));
    setsessionCtrl({ ...sessionCtrl, sessionstartDate: new Date(e._d) });
  };

  const session_timeHandler = async (e) => {
    console.log(e, "sessionHandler");
    setSessionDate(new Date(e._d));
    setsessionCtrl({ ...sessionCtrl, sessionstartDate: new Date(e._d) });
  };

  const HandleZone = (e) => {
    const { value } = e.target;
    setsessionCtrl({ ...sessionCtrl, timeZone: value });
  };

  function getNextDayByName(dayName, startDate, day, timeZone) {
    console.log(dayName, startDate, "dayName, startDate");

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const targetDayIndex = daysOfWeek.indexOf(dayName);

    console.log(targetDayIndex, "targetDayIndex");

    // moment().tz().format()

    // if (targetDayIndex === -1) {
    //     toastAlert(error, "Invalid day name");
    // }
    let currentDate;
    currentDate = new Date(startDate);

    const currentDayIndex = currentDate.getDay();
    let daysUntilNext = targetDayIndex - currentDayIndex;

    console.log(daysUntilNext, "daysUntilNext----11111");

    const currentTime = new Date();
    console.log(
      currentDate > currentTime,
      "currentDate",
      currentTime,
      currentDate
    );

    let nextDate;

    if (currentTime < currentDate) {
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 0);

    } else if (dayName == 'All') {
      console.log('currentDate 1111')
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
    } else {

      const currentDayIndex = currentDate.getDay();
      let daysUntilNext = targetDayIndex - currentDayIndex;

      if (daysUntilNext <= 0) {
        daysUntilNext += 7;
      }
      console.log(daysUntilNext, 'currentDate')
      console.log('currentDate')
      nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + daysUntilNext);
    }





    // const presentTime = timeconverter.tz(timeZone).format()

    // const convertedTime = timeconverter(nextDate).tz(timeZone).format()

    // console.log(presentTime < convertedTime, "ttttt", presentTime, convertedTime)

    //   if(presentTime  )

    // if (currentTime < currentDate) {

    //     nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + 0);

    // }else if (dayName == 'All') {
    //     console.log('currentDate 1111')
    //      nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + 1);
    // } else {

    //     const currentDayIndex = currentDate.getDay();
    //     let daysUntilNext = targetDayIndex - currentDayIndex;

    //     if (daysUntilNext <= 0) {
    //         daysUntilNext += 7;
    //     }
    //     console.log(daysUntilNext, 'currentDate')
    //     console.log('currentDate')
    //     nextDate = new Date(currentDate);
    //     nextDate.setDate(currentDate.getDate() + daysUntilNext);
    // }

    console.log(nextDate, "nextDate");
    return nextDate;

    if (daysUntilNext <= 0) {
      daysUntilNext += 7;
    }
    console.log(daysUntilNext, "currentDate");
    console.log("currentDate");
    nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + daysUntilNext);
  }

  // Example usage:
  // console.log(,'chekc'); // Outputs the next Monday with the current time

  // console.log(,"check"); // Outputs the next Monday

  const addSession = async () => {
    console.log(
      selectedSession,
      sessionType,
      recurringLimt,
      Day,
      timeZone,
      "setAddedSession"
    );

    var dataChack;
    if (selectedSession == "Recurring") {
      var datareccrring = getNextDayByName(
        Day,
        sessionstartDate,
        Day,
        timeZone
      );

      dataChack = schStartMeeting(datareccrring);
    } else {
      dataChack = schStartMeeting(sessionstartDate);
    }

    console.log(datareccrring, "datareccrring");

    console.log("dataChack====", dataChack);
    if (!dataChack) {
      toastAlert("warning", "The session date has expired");
      return false;
    }

    let dat;
    if (selectedSession != "Recurring") {
      dat = await session_singleValid({
        session_date: sessionstartDate,
        timezone: timeZone,
        AddedSession,
        edit: schedule_edit_index,
      });
    } else {
      dat = await session_recurringValid({
        session_date: datareccrring,
        timezone: timeZone,
        NoOfSession: NoOfSession,
        recurringLimt: recurringLimt,
        Day: Day,
        edit: schedule_edit_index,
        ...newSession,
      });
    }
    console.log(dat, "addSession");
    if (isEmpty(dat)) {
      setError({});

      const convertToUTC = (dateString) => new Date(dateString).toISOString();

      const normalizedCurrentSessionDate = convertToUTC(
        selectedSession == "Recurring" ? datareccrring : sessionstartDate
      );

      const exist_data = AddedSession.find(
        (val) => convertToUTC(val.session_date) === normalizedCurrentSessionDate
      );

      console.log(exist_data, "exist_data");
      if (exist_data) {
        toastAlert("error", "Date already submitted");
        return false;
      }

      if (!isEmpty(schedule_edit_index)) {
        AddedSession.splice(schedule_edit_index, 1, {
          sessionid: sessionid,
          session_date:
            selectedSession == "Recurring" ? datareccrring : sessionstartDate,
          timezone: timeZone,
          Day,
          recurringLimt,
          NoOfSession,
        });
      } else {
        setAddedSession([
          ...AddedSession,
          {
            sessionid: sessionid,
            session_date:
              selectedSession == "Recurring" ? datareccrring : sessionstartDate,
            timezone: timeZone,
            Day,
            recurringLimt,
            NoOfSession,
          },
        ]);
      }

      setsessionCtrl({
        ...sessionCtrl,
        sessionstartDate: "",
        timeZone: "",
        Day: "",
        recurringLimt: "0",
        NoOfSession: "",
      });

      setSchedule_Edit_index();
    } else {
      setError(dat);
    }

    //  setsessionCtrl(initalsession);
  };

  console.log(AddedSession, "AddedSession");

  const submitFormTwo = async (e) => {
    try {
      e.preventDefault();
      // console.log(schedule, AddedSession, 'submitFormTwo')

      if (show_schedule_edit) {
        console.log(schedule, "schedule");
      } else {
        const det = await schedule_Valid(schedule);
        if (isEmpty(det)) {
          setError({});
          schedule.id = webinarDetails._id;
          schedule.session = AddedSession;
          const { status, result } = await add_webinar_schedule(schedule);
          console.log(status, result);
          dispatch(setWebinar(result.result));
        } else {
          setError(det);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const set_Basic_Edit_Show = () => {
    setShow_Basicedit(!showBasic_edit);
  };

  const editPresenters = async (type) => {
    console.log(type, "val, i");
    setValues(newPresenter);
    setEdit_Presenter(false);
    // if (type) {
    // }
  };

  useEffect(() => {
    if (state?.type == "new") {
      set_show_schedule_edit(false);
      setdefaultAcc(1);
    }
    if (id) {
      getWebinarDetails(id);
    }
  }, [selectedSession]);

  console.log(newPresenter, "newPresenter");

  useEffect(() => {
    if (Object?.keys?.(EditData)?.length > 0) {
      languageRef.current.updateSelected(EditData.langugae);
      setnewPresenter({
        profile_image: EditData.presenterTempImage
          ? EditData.presenterTempImage
          : "",
        Presenters_name: EditData.presenterTempName,
        Presenters_email: EditData.presenterTempEmail,
      });
      setInitialState({
        Webinar_description: EditData.description,
        Webinar_language: EditData.langugae,
        Webinar_title: EditData.title,
      });
    }
  }, [EditData]);

  const getWebinarDetails = async (id) => {
    try {
      let getdata = await getWebinarData({ id: id });
      console.log(getdata, "getdatagetdata");
      setEditData(getdata?.result?.result);
      let presenter = getdata?.result?.result;

      // languageRef.current.updateSelected(getdata?.result?.result.langugae);

      // console.log(languageRef.current.updateSelected("it"))

      // setnewPresenter({
      //     profile_image: getdata?.result?.result.presenterTempImage,
      //     Presenters_name: getdata?.result?.result.presenterTempName,
      //     Presenters_email: getdata?.result?.result.presenterTempEmail,
      // });

      var SSessionType =
        presenter.sessionType == 0
          ? "single"
          : presenter.sessionType == 1
            ? "multiple"
            : "Recurring";
      console.log(SSessionType, "SSessionType");
      setisSession(presenter?.sessions?.length == 0 ? false : true);
      if (presenter?.sessions?.length != 0) {
        setSelectedSession(SSessionType);
      }

      if (SSessionType == sessionType || sessionType == "") {
        if (presenter.sessionType == 0) {
          setsessionCtrl({
            ...sessionCtrl,
            sessionType:
              presenter.sessionType == 0
                ? "single"
                : presenter.sessionType == 1
                  ? "multiple"
                  : "Recurring",
            sessionid: presenter.sessions[0]._id,
            sessionstartDate: presenter.sessions[0].startDate,
            timeZone: presenter.sessions[0].timeZone,
          });

          // setAddedSession([
          //     ...AddedSession,
          //     {
          //         session_date: presenter.sessions[0].startDate,
          //         timezone: presenter.sessions[0].timeZone,
          //     },
          // ]);
        } else if (presenter.sessionType == 1) {
          setsessionCtrl({
            ...sessionCtrl,
            sessionType:
              presenter.sessionType == 0
                ? "single"
                : presenter.sessionType == 1
                  ? "multiple"
                  : "Recurring",
          });

          const sessions = presenter?.sessions.map((data) => ({
            sessionid: data._id,
            session_date: data.startDate,
            timezone: data.timeZone,
            status: data.status
          }));

          setAddedSession(sessions);
        } else if (presenter.sessionType == 2) {
          setSelectedSession("Recurring");

          // sessionType = "Recurring"

          setsessionCtrl({
            sessionType: "Recurring",
          });

          const sessions = presenter?.sessions.map((data) => ({
            sessionid: data._id,
            session_date: data.startDate,
            timezone: data.timeZone,
            Day: data.Day,
            recurringLimt: data.recurringLimt,
            NoOfSession: data.NoOfSession,
          }));

          setAddedSession(sessions);

          // setsessionCtrl({
          //     ...sessionCtrl,
          //     sessionType:
          //         presenter.sessionType == 0
          //             ? "single"
          //             : presenter.sessionType == 1
          //                 ? "multiple"
          //                 : "Recurring",

          //     sessionstartDate: presenter.sessions[0].startDate,
          //     timeZone: presenter.sessions[0].timeZone,
          //     sessionid: presenter.sessions[0]._id,
          //     Day: presenter.sessions[0].Day,
          //     recurringLimt: presenter.sessions[0].recurringLimt,
          //     NoOfSession: presenter.sessions[0].NoOfSession,
          // });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleListChange(event) {
    const { id } = event.target;
    if (id == "image") {
      const { files } = event.target;

      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log(fileType, fileSize, "fileSizefileSizefileSize");

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        setpreviewUrl(URL.createObjectURL(files[0]));
        setValues({
          ...values,
          profile_image: files[0],
          preview: URL.createObjectURL(files[0]),
        });
      } else {
        toastAlert("error", "The file must be less than 1 MB.");

        return false;
      }
    } else {
      setValues({ ...values, [id]: event.target.value });
    }
  }

  const submitBasicSetUp = async () => {
    try {
      const dat = await Basic_validation(initialState);

      if (isEmpty(dat)) {
        setloading(true);
        const payload = {
          id,
          profile_image: newPresenter.profile_image,
          Presenters_name: newPresenter.Presenters_name,
          Presenters_email: newPresenter.Presenters_email,
          Webinar_title,
          Webinar_language,
          Webinar_description,
        };

        console.log(payload, "payload");

        const { status, result } = await editWebinerBasic(payload);
        //Expand collapse
        setShow_Basicedit(!showBasic_edit);
        set_show_schedule_edit(false);
        setdefaultAcc(1);
        if (status) {
          toastAlert("success", result?.message);
        }
        console.log(status, result, "edit webinar");
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setloading(false);
    }
  };

  console.log(values, "vvvvvvvvvv-------------------nnnnnn");

  const schStartMeeting = (currentSessionDate) => {

    console.log(currentSessionDate, 'currentSessionDate')

    let currSessionDate = new Date(currentSessionDate);
    const milliseconds = currSessionDate.getTime();

    const CURRDATE = Date.now();

    let response = false;
    response = milliseconds > CURRDATE ? true : false;

    console.log(response, "CURRDATE");

    return response;
  };

  const DeleteSession = async (type, val, i) => {
    const newSessions = [...AddedSession];
    newSessions.splice(i, 1);
    setAddedSession(newSessions);
  };

  const EditSession = (type, val, i) => {
    console.log(type, val, i, "type, val, i");
    if (type) {
      const newSessions = [...AddedSession];
      const dataChack = schStartMeeting(newSessions[i].session_date);

      // if (!dataChack) {
      //     toastAlert("warning", "You can't Edit this session!,The session date has expired");
      //     return false
      // }

      setInitialDate();
      setsessionCtrl();
      setInitialDate(new Date(newSessions[i].session_date));

      setsessionCtrl({
        ...sessionCtrl,
        sessionid: newSessions[i].sessionid,
        sessionstartDate: newSessions[i].session_date,
        timeZone: newSessions[i].timezone,
      });

      setsessionCtrl({
        ...sessionCtrl,
        sessionType: "Recurring",
        sessionstartDate: newSessions[i].session_date,
        timeZone: newSessions[i].timezone,
        sessionid: newSessions[i].sessionid,
        Day: newSessions[i].Day,
        recurringLimt: newSessions[i].recurringLimt,
        NoOfSession: newSessions[i].NoOfSession,
      });

      newSessions.splice(i, 1);

      setAddedSession(newSessions);



    } else {
      const newSessions = [...AddedSession];
      const dataChack = schStartMeeting(newSessions[i].session_date);
      if (!dataChack) {
        toastAlert(
          "warning",
          "You can't Edit this session!,The session date has expired"
        );
        return false;
      }

      setInitialDate();
      setsessionCtrl();
      setInitialDate(new Date(newSessions[i].session_date));

      setsessionCtrl({
        ...sessionCtrl,
        sessionid: newSessions[i].sessionid,
        sessionstartDate: newSessions[i].session_date,
        timeZone: newSessions[i].timezone,
      });

      // newSessions.splice(i, 1);
      setAddedSession(newSessions);
      setSchedule_Edit_index(i);
    }
  };

  const submitWebinarSchedule = async () => {
    try {

      console.log(AddedSession, sessionCtrl, "kkkk");
      // const sesionValidation = await NewValidation(AddedSession)
      setloading(true);

      // sessionstartDate,
      // timeZone,
      console.log("AddedSession", AddedSession)
      if (AddedSession.length != 0) {
        // const payloadData = {
        //   id: EditData._id,
        //   SessionData: AddedSession,
        //   selectedSession: selectedSession,
        // };
        const payloadData = {
          id: EditData._id,
          SessionData: AddedSession.filter((obj) => {
            return obj.status != 2;
          }),
          selectedSession: selectedSession,
        };
        console.log(payloadData, "payloadData");

        var { status, result } = await editWebinarSchedule(payloadData);


        set_show_schedule_edit(true);
        set_show_notification_edit(false);
        setdefaultAcc(2);
        toastAlert("success", "Schedule updated");
      } else {
        toastAlert("error", "Sessions is required");
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading(false);
    }
  };

  const registerConfirmMessage = async (ids, type) => {
    const payload = {
      id: ids,
      type: type,
      webinarId: id,
    };
    const { status, result } = await getMailByID(payload);

    console.log(status, result, "status, result");
    // setTemplateData()
    setResponseData(result);
    // if (status) {
    setTemplateData(result?.result?.content);
    handleEmailShow();
    // }
  };

  const handleSubmit = async (e) => {
    try {
      if (editorRef.current) {
        setloading(true);

        const payload = {
          type: responseData?.result?.identifier,
          content: editorRef.current.getContent(),
          langCode: responseData?.result?.langCode,
          webid: id,
          default_template: responseData?.result?.default_template
            ? true
            : false,
          tempid: responseData?.result?._id,
        };

        if (editorRef.current.getContent() != templateData) {
          const { status, result } = await editEmailTemplate(payload);
          if (status) {
            toastAlert("success", "Submitted");
            handleEmailClose();
            setResponseData(null);
            setTemplateData("");
            getWebinarDetails(id);
          }
        } else {
          toastAlert("success", "Submitted");
          handleEmailClose();
          setResponseData(null);
          // if (status) {
          setTemplateData("");
        }
      }
    } catch {
    } finally {
      setloading(false);
      set_show_notification_edit(show_notification_edit);
    }
  };

  //Validation
  // disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };

  console.log(show_schedule_edit, "show_schedule_edit");

  const handleAccChanges = (acc, type) => {
    if (type == "edit") {
      if (acc == 0) {
        setdefaultAcc(acc);
        setShow_Basicedit(!showBasic_edit);
        set_show_schedule_edit(true);
        set_show_notification_edit(true);
      } else if (acc == 1) {
        setdefaultAcc(acc);
        setShow_Basicedit(true);
        set_show_schedule_edit(!show_schedule_edit);
        set_show_notification_edit(true);
      } else if (acc == 2) {
        setdefaultAcc(acc);
        setShow_Basicedit(true);
        set_show_schedule_edit(true);
        set_show_notification_edit(!show_notification_edit);
      }
    } else if (type == "cancle") {
      if (acc == 0) {
        setdefaultAcc(acc + 1);
        setShow_Basicedit(!showBasic_edit);
        set_show_schedule_edit(!show_schedule_edit);
      } else if (acc == 1) {
        setdefaultAcc(acc + 1);
        set_show_schedule_edit(!show_schedule_edit);
        set_show_notification_edit(!show_notification_edit);
      } else if (acc == 2) {
        setdefaultAcc(4);
        set_show_notification_edit(!show_notification_edit);
        // setShow_Basicedit(!showBasic_edit)
      }
    }
  };

  const NotifySubmit = () => {
    set_show_notification_edit(true);
    setdefaultAcc(4);

    handleCompleteShow();
  };

  const searchHandler = (e) => {
    console.log(e.target.value, "filterSearch");
    languageRef.current.filterSearch(e);
  };

  const timeZoneSearchHandler = async (e) => {
    setsessionCtrl({
      ...sessionCtrl,
      timeZone: e,
    });
  };

  console.log(sessionstartDate, "ssssssssssssssssssssssss");

  return (
    <>
      {loading && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#0d2a35"}
            loading={loading}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      {Object?.keys(EditData)?.length > 0 && (
        <div className="dashboard-page">
          <div className="dashboard">
            <Innerpagenavbar />
            <div className="dashbord-right mt-5">
              <div className="dashbord-right-body-1">
                <Accordion
                  className="accMain"
                  activeKey={[defaultAcc?.toString()]}
                  // defaultActiveKey={[defaultAcc.toString()]}
                  alwaysOpen
                >
                  <Accordion.Item eventKey="0" className="accItem">
                    <Accordion.Header>
                      <p>Basic settings</p>
                      <div className="accordion-header-content">
                        {id && showBasic_edit ? (
                          <>
                            <img
                              src={require("../../../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link
                              className="accordionedit-btn"
                              onClick={() => handleAccChanges(0, "edit")}
                            >
                              Edit
                            </Link>
                          </>
                        ) : (
                          <>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={() => handleAccChanges(0, "cancle")}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Cancel
                              </Link>
                              <Link
                                to="#"
                                className="primary_btn"
                                onClick={submitBasicSetUp}
                              >
                                <img
                                  src={require("../../../assets/images/chevron-right.png")}
                                  height="16"
                                  width="16"
                                  className="img-fluid"
                                />{" "}
                                Confirm
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="white-box webinar-setting-content basic-margin">
                        <Row>
                          <Col lg="4">
                            {" "}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              {" "}
                              <Form.Label>Webinar language</Form.Label>{" "}
                              <LanguagesSelect
                                ref={languageRef}
                                names={"international"}
                                // searchable={true}
                                className="language_select"
                                searchPlaceholder="Search for a language"
                                aria-label="Default select example"
                                id="Webinar_language"
                                onSelect={(val) => Changes_Lang_Handler(val)}
                              />
                              {error && error.Webinar_language && (
                                <span style={{ color: "red" }}>
                                  {error.Webinar_language}
                                </span>
                              )}
                              <p>
                                The interface will be translated for your users
                              </p>
                            </Form.Group>
                          </Col>
                          <Col lg="4">
                            {" "}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Webinar title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Example: How to lose weight in 6 weeks"
                                id="Webinar_title"
                                value={Webinar_title}
                                onChange={changesHandler}
                              />
                              <p>Public title. Max 70 characters.</p>
                              {error && error.Webinar_title && (
                                <span style={{ color: "red" }}>
                                  {error.Webinar_title}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                          <Col lg="4">
                            {" "}
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Webinar description</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Example: All the tips and tricks to efficient diets and workouts"
                                id="Webinar_description"
                                value={Webinar_description}
                                onChange={changesHandler}
                              />
                              <p>Public description. Max 150 characters.</p>
                              {error && error.Webinar_description && (
                                <span style={{ color: "red" }}>
                                  {error.Webinar_description}
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <h2 className="head-field mt-5">Webinar presenter</h2>
                      <Row className=" mb-5">
                        {/* {!edit_presenter && ( */}
                        <Col xl="5" lg="12" className="mb-3">
                          <div className="white-box1 webinar-width2">
                            <Row className="align-items-center present-profile">
                              <Col lg="4" md="3" sm="3">
                                {newPresenter.profile_image != "" &&
                                  newPresenter.profile_image ? (
                                  newPresenter.preview ? (
                                    <img
                                      src={newPresenter.preview}
                                      // onClick={() => fileRef.current.click()}
                                      className="img-fluid"
                                      style={{
                                        height: "120px",
                                        width: "120px",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`${config.API_URL}/presenter/${newPresenter.profile_image}`}
                                      className="img-fluid"
                                      style={{
                                        height: "120px",
                                        width: "120px",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )
                                ) : (
                                  <img
                                    src={require("../../../assets/images/profile-img2.png")}
                                    // onClick={() => fileRef.current.click()}
                                    className="img-fluid"
                                    style={{
                                      height: "120px",
                                      width: "120px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}{" "}
                              </Col>
                              <Col lg="6" md="9" sm="9">
                                <div className="profile-detail-align-start">
                                  <div>
                                    <div className="name-content-1">
                                      <p>{newPresenter.Presenters_name}</p>
                                    </div>
                                    <p className="email-profile">
                                      {newPresenter.Presenters_email}
                                    </p>

                                    <div className="profile-change mt-2">
                                      <a
                                        className="profile-edit"
                                        onClick={() => editPresenters(true)}
                                      >
                                        Change
                                      </a>
                                    </div>
                                  </div>{" "}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        {/* )} */}
                        {/* {
                                              edit_presenter && ( */}

                        <Col xl="7" lg="12" className="mb-3">
                          <div className="webinar-width1">
                            <div
                              className="white-box1 "
                              style={{ minHeight: "200px" }}
                            >
                              <div className=" NewPresenters">
                                <Row className="align-items-center">
                                  <Col
                                    lg="3"
                                    md="3"
                                    sm="3"
                                    className="p-0 m-auto "
                                  >
                                    {" "}
                                    <div className="img_position_basic">
                                      <Form.Control
                                        ref={fileRef}
                                        id="image"
                                        type="file"
                                        className="img_upload"
                                        accept=".png,.jpg,.jpeg,.webp"
                                        // value={ }
                                        onChange={handleListChange}
                                        disabled={edit_presenter}
                                      />
                                      {/* <i class="bi bi-folder-plus upload"  style={{ fontSize: '30px' }}></i> */}
                                      {
                                        // previewUrl != undefined &&
                                        //     previewUrl != "" ? (
                                        //     <img
                                        //         src={previewUrl}
                                        //         onClick={() => fileRef.current.click()}
                                        //         className="img-fluid image-dp"
                                        //         style={{
                                        //             height: "120px",
                                        //             width: "120px",
                                        //         }}
                                        //     />
                                        // ) : (
                                        <>
                                          {values.preview &&
                                            values.preview != "" ? (
                                            <img
                                              src={values.preview}
                                              onClick={() =>
                                                fileRef.current.click()
                                              }
                                              className="img-fluid image-dp"
                                              style={{
                                                height: "120px",
                                                width: "120px",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                              }}
                                            />
                                          ) : (
                                            <>
                                              {values.profile_image &&
                                                values.profile_image != "" ? (
                                                <img
                                                  src={`${config.API_URL}/presenter/${values.profile_image}`}
                                                  onClick={() =>
                                                    fileRef.current.click()
                                                  }
                                                  className="img-fluid image-dp"
                                                  style={{
                                                    height: "120px",
                                                    width: "120px",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={require("../../../assets/images/profile-img2.png")}
                                                  onClick={() =>
                                                    fileRef.current.click()
                                                  }
                                                  className="img-fluid image-dp"
                                                  style={{
                                                    height: "120px",
                                                    width: "120px",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              )}
                                            </>
                                          )}

                                          {/* {error && error.image && <span style={{ color: 'red' }}>{error.image}</span>} */}
                                        </>
                                        // )
                                      }

                                      <img
                                        src={require("../../../assets/images/edit.png")}
                                        className="image_edit"
                                        onClick={() => fileRef.current.click()}
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    lg="9"
                                    md="9"
                                    sm="9"
                                    className="img_position_basic1"
                                  >
                                    {" "}
                                    {/* <div className="profile-detail profile-detail-align-start"> */}
                                    {/* <div> */}
                                    {/* <div className="name-content-1"> */}
                                    <Row className="align-items-center img_position_basic2">
                                      <Row>
                                        <Col lg="6" className="mb-3">
                                          {" "}
                                          <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            id="Presenters_name"
                                            value={
                                              values.Presenters_name
                                                ? values.Presenters_name
                                                : ""
                                            }
                                            onChange={handleListChange}
                                            disabled={edit_presenter}
                                          />
                                          {error && error.Presenters_name && (
                                            <span style={{ color: "red" }}>
                                              {error.Presenters_name}
                                            </span>
                                          )}
                                        </Col>

                                        <Col lg="6" className="mb-3">
                                          {" "}
                                          <Form.Control
                                            type="text"
                                            placeholder="Email Address"
                                            id="Presenters_email"
                                            value={
                                              values.Presenters_email
                                                ? values.Presenters_email
                                                : ""
                                            }
                                            onChange={handleListChange}
                                            disabled={edit_presenter}
                                          />{" "}
                                          {error && error.Presenters_email && (
                                            <span style={{ color: "red" }}>
                                              {error.Presenters_email}
                                            </span>
                                          )}
                                        </Col>
                                        {isOTPSent && (
                                          <Col lg="6" className="mb-3">
                                            {" "}
                                            <Form.Control
                                              type="text"
                                              placeholder="Enter otp"
                                              id="otp"
                                              onChange={handleListChange}
                                            // disabled={edit_presenter}
                                            />{" "}
                                            {error && error.Presenters_otp && (
                                              <span style={{ color: "red" }}>
                                                {error.Presenters_otp}
                                              </span>
                                            )}
                                          </Col>
                                        )}
                                      </Row>

                                      <Col lg="2">
                                        {edit_presenter ? (
                                          <div className="profile-change">
                                            {/* <img
                                                                                  src={require("../../assets/images/setting-img.png")}
                                                                                  className="img-fluid"
                                                                              />{" "}
                                                                              <img
                                                                                  src={require("../../assets/images/voice-img.png")}
                                                                                  className="img-fluid"
                                                                              />{" "} */}
                                            <Link className="profile-edit">
                                              Preview
                                            </Link>
                                          </div>
                                        ) : (
                                          <div className="profile-change">
                                            {/* <img
                                                                              src={require("../../assets/images/setting-img.png")}
                                                                              className="img-fluid"
                                                                          />{" "}
                                                                          <img
                                                                              src={require("../../assets/images/voice-img.png")}
                                                                              className="img-fluid"
                                                                          />{" "} */}
                                            <Link
                                              className="profile-edit"
                                              onClick={addClick}
                                            >
                                              Preview
                                            </Link>
                                          </div>
                                        )}
                                      </Col>

                                      <p
                                        style={{
                                          fontSize: "10px",
                                          color: "#878682",
                                        }}
                                      >
                                        Image is optional, Max 1MB. Valid
                                        formats: png , jpg , jpeg and webp
                                      </p>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>

                  {EditData?.mettingType != 0 && (
                    <>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p>Webinar schedule</p>
                          <div className="accordion-header-content">
                            {show_schedule_edit ? (
                              <>
                                <img
                                  src={require("../../../assets/images/accordion-config.png")}
                                  className="img-fluid"
                                />{" "}
                                <p>Configured</p>
                                {/* <br></br> */}
                                <Link
                                  className="accordionedit-btn"
                                  onClick={() => handleAccChanges(1, "edit")}
                                >
                                  Edit
                                </Link>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Link
                                    to="#"
                                    className="primary_btn primary_btn2 me-4"
                                    onClick={() =>
                                      handleAccChanges(1, "cancle")
                                    }
                                  >
                                    <svg
                                      width="8"
                                      height="12"
                                      viewBox="0 0 8 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.5 1L6.5 6L1.5 11"
                                        stroke="#001F2A"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    Cancel
                                  </Link>
                                  <Link
                                    to="#"
                                    className="primary_btn"
                                    onClick={submitWebinarSchedule}
                                  >
                                    <img
                                      src={require("../../../assets/images/chevron-right.png")}
                                      height="16"
                                      width="16"
                                      className="img-fluid"
                                    />{" "}
                                    Confirm
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="time-mangement">
                            <div
                              className={`time-schedule ${selectedSession === "single" ? "box-shadow" : ""
                                }`}
                            >
                              <div class="form-check check-select">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  value="single"
                                  checked={sessionType === "single"}
                                  onChange={() => handleSessionSelect("single")}
                                  disabled={isSession}
                                />
                                {sessionType === "single"
                                  ? error &&
                                  error.schedule_type && (
                                    <span style={{ color: "red" }}>
                                      {error.schedule_type}
                                    </span>
                                  )
                                  : ""}
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  One single session
                                  <p>
                                    The registration box will display one (or
                                    many) scheduled events, and your users will
                                    register to only one of them at a time
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div
                              className={`time-schedule ${sessionType === "multiple" ? "box-shadow" : ""
                                }`}
                            >
                              <div class="form-check check-select">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault3"
                                  value="second"
                                  checked={sessionType === "multiple"}
                                  onChange={() =>
                                    handleSessionSelect("multiple")
                                  }
                                  disabled={isSession}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault3"
                                >
                                  Multiple sessions
                                  <p>
                                    The registration box will display multiple
                                    scheduled events, and your users will
                                    register to all of them from a single sign
                                    up
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div
                              className={`time-schedule ${sessionType === "Recurring" ? "box-shadow" : ""
                                }`}
                            >
                              <div class="form-check check-select">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault4"
                                  value="Recurring"
                                  checked={sessionType === "Recurring"}
                                  onChange={() =>
                                    handleSessionSelect("Recurring")
                                  }
                                  disabled={isSession}
                                />
                                <label
                                  class="form-check-label"
                                  for="flexRadioDefault4"
                                >
                                  Recurring series of sessions
                                  <p>
                                    The registration box will display series of
                                    recurring daily or weekly events, and your
                                    users will register to the entire series for
                                    as long as it lasts.
                                  </p>
                                </label>
                              </div>
                            </div>
                          </div>

                          {selectedSession != null && (
                            <>
                              {sessionType == "Recurring" ? (
                                <Row className="schedule-sec newSission-bg align-items-center">
                                  <Col
                                    lg="2"
                                    xxl="1"
                                    xl="2"
                                    md="2"
                                    sm="2"
                                    className="mb-2"
                                  >
                                    {" "}
                                    <p>New session</p>
                                  </Col>
                                  <Col
                                    lg="2"
                                    xxl="1"
                                    xl="2"
                                    md="2"
                                    sm="2"
                                    className="mb-2"
                                  >
                                    {" "}
                                    <div className="onbtn">Every</div>
                                  </Col>
                                  <Col
                                    lg="4"
                                    xxl="2"
                                    xl="3"
                                    md="3"
                                    sm="4"
                                    className="mb-2"
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        setsessionCtrl({
                                          ...sessionCtrl,
                                          Day: e.target.value,
                                        })
                                      }
                                      value={Day}
                                    >
                                      <option>Day</option>
                                      <hr></hr>
                                      <option value="All">All</option>
                                      <option value="Monday">Monday</option>
                                      <option value="Tuesday">Tuesday</option>

                                      <option value="Wednesday">
                                        Wednesday
                                      </option>
                                      <option value="Thursday">Thursday</option>

                                      <option value="Friday">Friday</option>
                                      <option value="Saturday">Saturday</option>

                                      <option value="Sunday">Sunday</option>
                                    </Form.Select>

                                    {error && error.Day && (
                                      <span style={{ color: "red" }}>
                                        {error.Day}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    lg="4"
                                    xxl="2"
                                    xl="3"
                                    md="3"
                                    sm="4"
                                    className="mb-2"
                                  >
                                    {" "}
                                    <div className="error_input">
                                      <InputGroup className="">
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="img-position"
                                        >
                                          <img
                                            src={require("../../../assets/images/time.png")}
                                            className="img-fluid cal-img"
                                          />{" "}
                                        </InputGroup.Text>

                                        {sessionstartDate != "" && (
                                          <Datetime
                                            initialValue={sessionstartDate}
                                            inputProps={{ placeholder: "Choose a time" }}
                                            // initialValue={sesseionDate}
                                            // input={true}
                                            dateFormat={false}
                                            timeFormat="hh:mm A"
                                            onChange={session_timeHandler}
                                            isValidDate={disablePastDt}
                                          />
                                        )}

                                        {sessionstartDate == "" && (
                                          <Datetime
                                            inputProps={{ placeholder: "Choose a time" }}
                                            // initialValue={sesseionDate}
                                            // input={true}
                                            dateFormat={false}
                                            timeFormat="hh:mm A"
                                            onChange={session_timeHandler}
                                            isValidDate={disablePastDt}
                                          />
                                        )}
                                      </InputGroup>
                                    </div>
                                    <div className="error_msg">
                                      {error && error.sessionTime && (
                                        <span style={{ color: "red" }}>
                                          {error.sessionTime}
                                        </span>
                                      )}
                                    </div>
                                  </Col>{" "}
                                  <Col
                                    lg="4"
                                    xxl="2"
                                    xl="3"
                                    md="3"
                                    sm="4"
                                    className="mb-2"
                                  >
                                    <div>
                                      {/* <Link className="onbtn">Limit</Link> */}
                                      <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) =>
                                          setsessionCtrl({
                                            ...sessionCtrl,
                                            recurringLimt: e.target.value,
                                          })
                                        }
                                        value={recurringLimt}
                                      >
                                        <option value="0">Limited</option>
                                        <option value="1">Unlimited</option>
                                      </Form.Select>
                                    </div>
                                  </Col>
                                  {recurringLimt == 0 && (
                                    <Col
                                      lg="2"
                                      xxl="1"
                                      xl="2"
                                      md="2"
                                      sm="2"
                                      className="mb-2"
                                    >
                                      <div className="error_input">
                                        <InputGroup className="limit_sec">
                                          <Form.Control
                                            placeholder="Limit"
                                            id="limit"
                                            aria-label="Username"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) =>
                                              setsessionCtrl({
                                                ...sessionCtrl,
                                                NoOfSession: e.target.value,
                                              })
                                            }
                                            value={NoOfSession}
                                          />
                                        </InputGroup>
                                      </div>
                                      <div className="error_msg">
                                        {error && error.NoOfSession && (
                                          <span style={{ color: "red" }}>
                                            {error.NoOfSession}
                                          </span>
                                        )}
                                      </div>
                                    </Col>
                                  )}
                                  <Col
                                    lg="4"
                                    xxl="2"
                                    xl="3"
                                    md="3"
                                    sm="4"
                                    className="mb-2"
                                  >
                                    <div className="error_input">
                                      <SelectSearch
                                        aria-label="Choose your Timezone"
                                        search={true}
                                        onChange={(e) =>
                                          timeZoneSearchHandler(e)
                                        }
                                        options={options}
                                        name="Timezone"
                                        placeholder="Choose your Timezone"
                                        value={timeZone}
                                      />
                                    </div>
                                    <div className="error_msg">
                                      {error && error.timezone && (
                                        <span style={{ color: "red" }}>
                                          {error.timezone}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    lg="2"
                                    xxl="1"
                                    xl="2"
                                    md="2"
                                    sm="2"
                                    className="mb-2"
                                  >
                                    <Link
                                      className="newsission-addbtn"
                                      onClick={addSession}
                                    >
                                      Submit
                                    </Link>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  {sessionType == "single" ? (
                                    <div className="newSission-bg">
                                      <div className="newSission">
                                        <p>New Session</p>
                                        <Link className="onbtn">On</Link>
                                        <div className="error_input">
                                          <InputGroup className="">
                                            <InputGroup.Text
                                              id="basic-addon1"
                                              className="img-position"
                                            >
                                              {" "}
                                              <img
                                                src={require("../../../assets/images/calender.png")}
                                                className="img-fluid cal-img"
                                              />{" "}
                                            </InputGroup.Text>

                                              {/* =========== single senorio ===========  */}

                                            {sessionstartDate == "" && (
                                              <Datetime
                                                timeFormat={false}
                                                onChange={sessionHandler}
                                                isValidDate={disablePastDt}
                                                inputProps={{
                                                  placeholder: "Select the date",
                                                  style:
                                                    sessionType === "single" && AddedSession.length === 1
                                                      ? { cursor: "not-allowed" }
                                                      : {},
                                                  disabled: sessionType === "single" && AddedSession.length === 1,
                                                }}
                                              />

                                            )}
                                          

                                            {sessionstartDate != "" && (
                                              <Datetime
                                                initialValue={
                                                  new Date(sessionstartDate)
                                                }
                                                timeFormat={false}
                                                onChange={sessionHandler}
                                                // dateFormat="DD-MM-YY"
                                                isValidDate={disablePastDt}
                                                inputProps={
                                                  sessionType == "single" &&
                                                  AddedSession.length == 1 && {
                                                    style: {
                                                      cursor: "not-allowed"
                                                    },
                                                    placeholder: "Select the date",
                                                    disabled: true,
                                                  }
                                                }
                                              />
                                            )}

                                          </InputGroup>
                                          <div className="error_msg">
                                            {error && error.session_date && (
                                              <span style={{ color: "red" }}>
                                                {error.session_date}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="error_input">
                                          <InputGroup className="">
                                            <InputGroup.Text
                                              id="basic-addon1"
                                              className="img-position"
                                            >
                                              <img
                                                src={require("../../../assets/images/time.png")}
                                                className="img-fluid cal-img"
                                              />{" "}
                                            </InputGroup.Text>

                                           {/*======== single senorio time ====== */}
                                           
                                            <Datetime
                                              // value={new Date(sessionstartDate)}
                                              dateFormat={false}
                                              timeFormat="hh:mm A"
                                              onChange={session_timeHandler}
                                              inputProps={{
                                                placeholder: "Choose a time", 
                                                style:
                                                  sessionType === "single" && AddedSession.length === 1
                                                    ? { cursor: "not-allowed" }
                                                    : {},
                                                disabled: sessionType === "single" && AddedSession.length === 1, // Conditionally disable the input
                                              }}
                                            />

                                          </InputGroup>
                                          <div className="error_msg">
                                            {error && error.sessionTime && (
                                              <span style={{ color: "red" }}>
                                                {error.sessionTime}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="error_input">
                                          <SelectSearch
                                            aria-label="Choose your Timezone"
                                            search={true}
                                            onChange={(e) =>
                                              timeZoneSearchHandler(e)
                                            }
                                            options={options}
                                            name="Timezone"
                                            placeholder="Choose your Timezone"
                                            value={timeZone}
                                          />
                                          <div className="error_msg">
                                            {error && error.timezone && (
                                              <span style={{ color: "red" }}>
                                                {error.timezone}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        {AddedSession.length > 0 ? (
                                          <Link className="newsission-addbtn">
                                            Submit
                                          </Link>
                                        ) : (
                                          !show_schedule_edit && (
                                            <Link
                                              className="newsission-addbtn"
                                              onClick={addSession}
                                            >
                                              Submit
                                            </Link>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    // multiple senorio 
                                    sessionType != null && (
                                      <div className="newSission-bg">
                                        <div className="newSission">
                                          <p>New Session</p>
                                          <Link className="onbtn">On</Link>
                                          <div className="error_input">
                                            <InputGroup className="">
                                              <InputGroup.Text
                                                id="basic-addon1"
                                                className="img-position"
                                              >
                                                {" "}
                                                <img
                                                  src={require("../../../assets/images/calender.png")}
                                                  className="img-fluid cal-img"
                                                />{" "}
                                              </InputGroup.Text>

                                              {/* ========= multiple senorio ============== */}

                                              {sessionstartDate == "" && (
                                                <Datetime
                                                  // initialValue={new Date()}
                                                  inputProps={{ placeholder: "Select the date" }}
                                                  timeFormat={false}
                                                  onChange={sessionHandler}
                                                // isValidDate={disablePastDt}
                                                />
                                              )}
                                              
                                              
                                              {sessionstartDate != "" && (
                                                <Datetime
                                                  initialValue={
                                                    new Date(sessionstartDate)
                                                  }
                                                  timeFormat={false}
                                                  onChange={sessionHandler}
                                                  // dateFormat="DD-MM-YY"
                                                  isValidDate={disablePastDt}
                                                />
                                              )}
                                            </InputGroup>
                                            <div className="error_msg">
                                              {error && error.session_date && (
                                                <span style={{ color: "red" }}>
                                                  {error.session_date}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="error_input">
                                            <InputGroup className="">
                                              <InputGroup.Text
                                                id="basic-addon1"
                                                className="img-position"
                                              >
                                                <img
                                                  src={require("../../../assets/images/time.png")}
                                                  className="img-fluid cal-img"
                                                />{" "}
                                              </InputGroup.Text>
                                              {/* {sesseionDate == " " &&
                                                (<Datetime
                                                dateFormat={false}
                                                timeFormat="hh:mm A"
                                                onChange={session_timeHandler}
                                                inputProps={{ placeholder: "Choose a time" }}
                                              />)}
                                              {sesseionDate != " " &&
                                              (<Datetime
                                              // value={
                                              //   new Date(sessionstartDate)
                                              // }
                                              initialValue={sesseionDate}
                                              dateFormat={false}
                                              timeFormat="hh:mm A"
                                              onChange={session_timeHandler}
                                            />)
                                            } */}
                                              <Datetime
                                                // value={
                                                //   new Date(sessionstartDate)
                                                // }
                                                // initialValue={sesseionDate}
                                                dateFormat={false}
                                                timeFormat="hh:mm A"
                                                onChange={session_timeHandler}
                                                inputProps={{ placeholder: "Choose a time" }}
                                              />
                                            </InputGroup>
                                            <div className="error_msg">
                                              {error && error.sessionTime && (
                                                <span style={{ color: "red" }}>
                                                  {error.sessionTime}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="error_input">
                                            <SelectSearch
                                              aria-label="Choose your Timezone"
                                              search={true}
                                              onChange={(e) =>
                                                timeZoneSearchHandler(e)
                                              }
                                              options={options}
                                              name="Timezone"
                                              placeholder="Choose your Timezone"
                                              value={timeZone}
                                            />
                                            <div className="error_msg">
                                              {error && error.timezone && (
                                                <span style={{ color: "red" }}>
                                                  {error.timezone}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          {!show_schedule_edit && (
                                            <Link
                                              className="newsission-addbtn"
                                              onClick={addSession}
                                            >
                                              Add
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {/*======= after adding next session ======= */}

                          {AddedSession.length > 0 &&
                            AddedSession.map((list, i) => (
                              <>
                                {selectedSession == "Recurring" ? (
                                  <Row className="schedule-sec newSission-bg align-items-center hover-notallow">
                                    <Col
                                      lg="2"
                                      xxl="1"
                                      xl="2"
                                      md="2"
                                      sm="2"
                                      className="mb-2"
                                    >
                                      {" "}
                                      <p>{i + 1} Session</p>
                                    </Col>
                                    <Col
                                      lg="2"
                                      xxl="1"
                                      xl="2"
                                      md="2"
                                      sm="2"
                                      className="mb-2"
                                    >
                                      <div className="onbtn"> Every</div>
                                    </Col>

                                    {/* <InputGroup className="">
                                                                          <InputGroup.Text id="basic-addon1">
                                                                              {" "}
                                                                              <img
                                                                                  src={require("../../../assets/images/calender.png")}
                                                                                  className="img-fluid"
                                                                              />{" "}
                                                                          </InputGroup.Text>
                                                                          <Form.Control
                                                                              placeholder="Select time"
                                                                              aria-label="Username"
                                                                              aria-describedby="basic-addon1"
                                                                              value={moment(list.session_date).format("LL")}
                                                                          />
                                                                      </InputGroup> */}
                                    <Col
                                      lg="4"
                                      xxl="2"
                                      xl="3"
                                      md="3"
                                      sm="4"
                                      className="mb-2"
                                    >
                                      <Form.Select
                                        aria-label="Default select example"
                                        value={list.Day}
                                      >
                                        <option>{list.Day}</option>
                                        <hr></hr>
                                        {/* <option value="Monday">Monday</option>
                                                                          <option value="Tuesday">Tuesday</option>

                                                                          <option value="Wednesday">
                                                                              Wednesday
                                                                          </option>
                                                                          <option value="Thursday">Thursday</option>

                                                                          <option value="Friday">Friday</option>
                                                                          <option value="Saturday">Saturday</option>

                                                                          <option value="Sunday">Sunday</option> */}
                                      </Form.Select>
                                    </Col>
                                    <Col
                                      lg="4"
                                      xxl="2"
                                      xl="3"
                                      md="3"
                                      sm="4"
                                      className="mb-2"
                                    >
                                      <div>
                                        <InputGroup className="">
                                          <InputGroup.Text
                                            id="basic-addon1"
                                            className="img-position"
                                          >
                                            <img
                                              src={require("../../../assets/images/time.png")}
                                              className="img-fluid cal-img"
                                            />{" "}
                                          </InputGroup.Text>

                                          <Form.Control
                                            placeholder="Select time"
                                            aria-label="Username"
                                            className="date_time"
                                            aria-describedby="basic-addon1"
                                            value={moment(
                                              list.session_date
                                            ).format("hh:mm A")}
                                          />
                                        </InputGroup>
                                      </div>
                                    </Col>
                                    <Col
                                      lg="4"
                                      xxl="2"
                                      xl="3"
                                      md="3"
                                      sm="4"
                                      className="mb-2"
                                    >
                                      <div>
                                        <Form.Select
                                          aria-label="Default select example"
                                          onChange={(e) =>
                                            setsessionCtrl({
                                              ...sessionCtrl,
                                              recurringLimt: e.target.value,
                                            })
                                          }
                                          value={list.recurringLimt}
                                        >
                                          <option value="0">Limited</option>
                                          <option value="1">Unlimited</option>
                                        </Form.Select>
                                      </div>
                                    </Col>
                                    {list.recurringLimt == 0 ? (
                                      <Col
                                        lg="2"
                                        xxl="1"
                                        xl="2"
                                        md="2"
                                        sm="2"
                                        className="mb-2"
                                      >
                                        <div>
                                          {/* <Link className="onbtn">Limit</Link> */}
                                          <InputGroup className="">
                                            <Form.Control
                                              placeholder="Limit"
                                              id="limit"
                                              className="date_time"
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                              value={list.NoOfSession}
                                            // onClick={(e) => setNewSession({ ...newSession, "limit": e.target.value })}
                                            />
                                          </InputGroup>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    <Col
                                      lg="4"
                                      xxl="2"
                                      xl="3"
                                      md="3"
                                      sm="4"
                                      className="mb-2"
                                    >
                                      {" "}
                                      <div>
                                        <Form.Select aria-label="Default select example">
                                          <option>{list.timezone}</option>
                                          {/* <option value="IST">IST</option>
                              <option value="UST">UST</option> */}
                                        </Form.Select>
                                      </div>
                                    </Col>
                                    <Col
                                      lg="2"
                                      xxl="1"
                                      xl="2"
                                      md="2"
                                      sm="2"
                                      className="mb-2"
                                    >
                                      <div className="edit_icon_btn">
                                        <Link
                                          className="newsission-addbtn hover-allow"
                                          onClick={() =>
                                            EditSession(true, list, i)
                                          }
                                        >
                                          <i class="fa-solid fa-pen-to-square"></i>
                                        </Link>

                                        <Link
                                          className="newsission-addbtn hover-allow"
                                          onClick={() =>
                                            DeleteSession(true, list, i)
                                          }
                                        >
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  <div className="newSission-bg hover-notallow">
                                    <div className="newSission">
                                      <p>{i + 1} Session</p>
                                      <Link className="onbtn"> On</Link>
                                      <InputGroup className="">
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="img-position"
                                        >
                                          {" "}
                                          <img
                                            src={require("../../../assets/images/calender.png")}
                                            className="img-fluid cal-img"
                                          />{" "}
                                        </InputGroup.Text>

                                        <Form.Control
                                          placeholder="Select time"
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          value={moment(
                                            list.session_date
                                          ).format("LL")}
                                        />
                                      </InputGroup>
                                      <InputGroup className="">
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="img-position"
                                        >
                                          <img
                                            src={require("../../../assets/images/time.png")}
                                            className="img-fluid cal-img"
                                          />{" "}
                                        </InputGroup.Text>
                                        {/* <Datetime
                                                                  value={list.session_date}
                                                                  // initialValue={sesseionDate}
                                                                  dateFormat={false}
                                                                  timeFormat={"hh:mm:ss"}
                                                                  open={false}
                                                              // onChange={session_timeHandler}
                                                              /> */}

                                        <Form.Control
                                          placeholder="Select time"
                                          aria-label="Username"
                                          aria-describedby="basic-addon1"
                                          value={moment(
                                            list.session_date
                                          ).format("hh:mm A")}
                                        />
                                        {/* <Form.Control
                          placeholder="Select time"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        /> */}
                                      </InputGroup>
                                      <Form.Select aria-label="Default select example">
                                        <option>{list.timezone}</option>
                                        {/* <option >IST</option> */}
                                        {/* <option value="IST">UST</option> */}
                                      </Form.Select>
                                      <Link
                                        className="newsission-addbtn hover-allow"
                                        onClick={() =>
                                          EditSession(false, list, i)
                                        }
                                      >
                                        <i class="fa-solid fa-pen-to-square"></i>
                                      </Link>

                                      <Link
                                        className="newsission-addbtn hover-allow"
                                        onClick={() =>
                                          DeleteSession(true, list, i)
                                        }
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}

                          {/* <div className="timezoneconversion-head">
                                                  <div className="timezoneconversion">
                                                      <h6>Allow time zone conversion</h6>
                                                      <p>
                                                          The Registration form will allow your visitors to
                                                          convert your configured time zone to theirs, so
                                                          it's more convenient for them. However, you may
                                                          turn this option OFF and therefore all times will
                                                          always be displayed in your configured time zone
                                                      </p>
                                                  </div>
                                                  <div>
                                                      <Form.Check
                                                          type="switch"
                                                          id="all_timezone"
                                                          defaultChecked={
                                                              webinarDetails?.Webinar_schedule?.all_timezone
                                                          }
                                                          onClick={scheduleHandler}
                                                      />
                                                  </div>
                                  </div> */}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p>Notification</p>
                          <div className="accordion-header-content">
                            {show_notification_edit ? (
                              <>
                                <img
                                  src={require("../../../assets/images/accordion-config.png")}
                                  className="img-fluid"
                                />{" "}
                                <p>Configured</p>
                                <Link
                                  className="accordionedit-btn"
                                  onClick={() => handleAccChanges(2, "edit")}
                                >
                                  Edit
                                </Link>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Link
                                    to="#"
                                    className="primary_btn primary_btn2 me-4"
                                    onClick={() =>
                                      handleAccChanges(2, "cancle")
                                    }
                                  >
                                    <svg
                                      width="8"
                                      height="12"
                                      viewBox="0 0 8 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.5 1L6.5 6L1.5 11"
                                        stroke="#001F2A"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    Cancel
                                  </Link>
                                  <Link
                                    to="#"
                                    className="primary_btn"
                                    onClick={NotifySubmit}
                                  >
                                    <img
                                      src={require("../../../assets/images/chevron-right.png")}
                                      height="16"
                                      width="16"
                                      className="img-fluid"
                                    />{" "}
                                    Confirm
                                  </Link>
                                </div>
                              </>
                            )}

                            {/* <img
                                                      src={require("../../../assets/images/accordion-config.png")}
                                                      className="img-fluid"
                                                  />{" "}
                                                  <p>Configured</p>
                                                  <Link className="accordionedit-btn">Edit</Link> */}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="dashbord-right-body">
                            <div className="dashbordrightbody-body">
                              <div className="dashbordrightbody-list1 registration_alert">
                                <h6
                                  className="alert alert-info"
                                  style={{ color: "black" }}
                                >
                                  All emails notifications will be sent out from{" "}
                                  <span style={{ color: "black" }}>
                                    "vaffix.com"
                                  </span>
                                </h6>
                                <div className="pre_notification">
                                  <ul className="pre_notification_list">
                                    <li className="list_msg">
                                      <h4 className="mb-2">
                                        Pre-Webinar notifications
                                      </h4>
                                      <p>
                                        Keep them in the loop of the webinar
                                        they've registered for, It's a great
                                        opportunity to hand them their link to
                                        the webinar room, and to remind them of
                                        the topics that will be covered. It
                                        helps greatly to maximize attendance!
                                      </p>
                                      <ul className="pre_notification_1 mt-3">
                                        <li>
                                          <div className="confirm_msg">
                                            <div className="add_info">
                                              <h4>
                                                Register Confirmation Message
                                              </h4>
                                            </div>
                                            <div className="confirm_msg_content mt-4">
                                              <div className="add_info_content">
                                                <img
                                                  src={require("../../../assets/images/registration_imgs/msg.png")}
                                                  className="img-fluid"
                                                />
                                                <div>
                                                  <h4>Immediately</h4>
                                                  <p>
                                                    [Confirmation] Your webinar
                                                    link
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="add_info_img">
                                                <img
                                                  src={require("../../../assets/images/registration_imgs/pencil.png")}
                                                  className="img-fluid"
                                                  onClick={() =>
                                                    registerConfirmMessage(
                                                      EditData?.templateReg,
                                                      "User_Register"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>

                                        <li>
                                          <div className="confirm_msg">
                                            <div className="add_info">
                                              <h4>Reminder message</h4>
                                            </div>
                                            <div className="confirm_msg_content mt-4">
                                              <div className="add_info_content">
                                                <img
                                                  src={require("../../../assets/images/registration_imgs/msg.png")}
                                                  className="img-fluid"
                                                />
                                                <div>
                                                  <h4>15 minutes before</h4>
                                                  <p>
                                                    The session is about to
                                                    begin
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="add_info_img">
                                                <img
                                                  src={require("../../../assets/images/registration_imgs/pencil.png")}
                                                  className="img-fluid"
                                                  onClick={() =>
                                                    registerConfirmMessage(
                                                      EditData?.templateNoti,
                                                      "Reminder_Notify"
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </li>
                                    {/* <li className="list_msg">
                                                                          <h4>Post Webinar notifications</h4>
                                                                          <p>
                                                                              Follow up with them to hand them the
                                                                              link to the webinar replay in case they
                                                                              missed it, or remind them of your
                                                                              product and sevices. this is also great
                                                                              opportunity to invite them to another
                                                                              webinar event!
                                                                          </p>
                                                                          <ul className="pre_notification_1">
                                                                              <li>
                                                                                  <div className="confirm_msg">
                                                                                      <div className="add_info">
                                                                                          <h4>Post Webinar follow ups</h4>
                                                                                          <div className="add_info_span">
                                                                                              <a href="#">Add Phone</a>
                                                                                              <a href="#">Add Email</a>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>
                                                                              </li>
                                                                          </ul>
                                                                      </li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </>
                  )}
                </Accordion>
                {/* <div className="btn-finish">
              <Link
                to="/webinar"
                className="primary_btn"
                onClick={() => toastAlert("Webinar created successfully.")}
              >
                <img
                  src={require("../../../assets/images/chevron-right.png")}
                  height="16"
                  width="16"
                  className="img-fluid"
                />{" "}
                Webinar List
              </Link>
            </div> */}
              </div>
            </div>

            <Modal
              size="lg"
              show={email}
              onHide={handleEmailClose}
              keyboard={false}
              centered
              className="emailnotifications"
            >
              <Modal.Header className="model-head">
                <Modal.Title className="model-title">
                  Email Notifications
                </Modal.Title>
                <div>
                  <Link
                    to="#"
                    className="primary_btn primary_btn2 me-4"
                    onClick={handleEmailClose}
                  >
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1L6.5 6L1.5 11"
                        stroke="#001F2A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Cancel
                  </Link>
                  <button
                    className="primary_btn"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {/* <img
              src={require("../../assets/images/chevron-right.png")}
              height="16"
              width="16"
              className="img-fluid"
            />{" "} */}
                    Confirm
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body className="mailnotify-body">
                <div className="mail-head">
                  <Row>
                    <Col lg="12">
                      {" "}
                      <Form.Label htmlFor="inputPassword5">
                        Webinar name
                      </Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        id="inputPassword5"
                        placeholder="[confirmation] Your webinar link"
                        aria-describedby="passwordHelpBlock"
                        value={EditData.title}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <p>Email body</p>
                      {/* <img
                src={require("../../assets/images/email-body.png")}
                className="img-fluid pt-2"
              />{" "} */}
                      <Editor
                        apiKey="54h6qy3bgdr4e2v1znhrddnngex0hy8165p9bmkupwosdlc7"
                        cloudChannel="5-stable"
                        disabled={false}
                        initialValue={templateData}
                        inline={false}
                        onInit={(_evt, editor) => (editorRef.current = editor)}
                        //onEditorChange={(e)=>console.log(e.target.value)}
                        //onChange={(e)=>console.log(e.target.getContent())}
                        plugins=""
                        tagName="div"
                        textareaName=""
                        toolbar=""
                        value=""
                        outputFormat="html"
                        init={{
                          height: 500,
                          language: "en",
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
      <Modal
        show={completdshow}
        onHide={handleCompleteClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                          <div>

                          </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../../../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage">
                <h2 className="mb-3">Congratulations</h2>
                <p>Your Webinar has been successfully created</p>
              </div>
              <center>
                <button onClick={completeWebinar} className="primary_btn mt-3">
                  Go To Webinar List
                </button>
              </center>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default WebinarpageEdit;
