import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
// import Modal from "react-bootstrap/Modal";
// import nextImg from "../assets/images/side-icon.svg";
// import nextImg1 from "../assets/images/side-icon2.svg";
// import Navinner from "../components/nav-inner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../../components/inner-footer";
import Template_navbar from "../../components/template-navbar";
const Template = (props) => {

  const { handleClose_2 } = props
  
  return (
    <>
      <Template_navbar handleClose_2={handleClose_2}/>
      <section className="template_section">
        <Container>
          <Row>
            <Col lg={12}>

              <div className="template">
                <div className="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                  >Webinar title</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Example: How to lose weight in 6 weeks"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/template_img/pencil.png")}
                        alt="pencil image"
                      /></span>
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                  >Webinar description</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Example: All the tips and tricks to efficient diets and workouts"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <span className="input-group-text" id="basic-addon2">
                      <img
                        className="img-fluid"
                        src={require("../../assets/images/template_img/pencil.png")}
                        alt="pencil image"
                      /></span>
                  </div>
                </div>
                <div class="webinar_forms">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/template_img/profile.png")}
                    alt="profile image"
                  />
                  <div>
                    <h2>Katy Perry</h2>
                    <p>Webinar host</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div class="webinar_time_update">
          <div>
            <p>Wednesday,<span> 13 June 2024, 12:00 pm </span></p>
            <p>Saturday,<span> 15 June 2024, 5:00 pm </span></p>
            <p>Saturday,<span> 15 June 2024, 5:00 pm</span></p>
          </div>
          <div>
            <div class="webinar_countdown">
              <div className="webinar_timer">
                <h3>00</h3>
                <p>Days</p>
              </div>
              <div className="webinar_timer">
                <h3>00</h3>
                <p>Hours</p>
              </div>
              <span>:</span>
              <div className="webinar_timer">
                <h3>00</h3>
                <p>Minutes</p>
              </div>
              <span>:</span>
              <div className="webinar_timer">
                <h3>00</h3>
                <p>Seconds</p>
              </div>
            </div>

          </div>
          <button class="webinar_button">
            <img class="img-fluid" src={require("../../assets/images/template_img/arrow.png")} alt="arrow image" />
            Register
          </button>
        </div>
      </section>

      <Innerfooter />
    </>
  );
};
export default Template;
