import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import nextImg from "../../../assets/images/side-icon.svg";
import Innerfooter from "../../../components/inner-footer";
import Innerpagenavbar from "../../../components/innerpage-navbar";
import BasicFullconfig from "../../../components/fullconfigraton/basicsetting-fullconfig";
import RegistrationFullconfig from "../../../components/fullconfigraton/registration-fullconfig";
import Notificationfullconfig from "../../../components/fullconfigraton/notification-fullconfig";
import Webinarschedulefullconfig from "../../../components/fullconfigraton/webinarschedule-fullconfig";
import Othersettingfullconfig from "../../../components/fullconfigraton/Othersetting-fullconfig";
import {
  Create_Webinar,
  Full_Create_Webinar,
  editWebinarRegister,
  editWebinarSchedule,
  editWebinarSettings,
  editWebinerBasic,
  getWebinarData,
} from "../../../action/user.action";
import { useSelector } from "react-redux";
import {
  new_Basic_validation,
  registervalidation,
} from "../../../lib/validation";
import { decryptString, isEmpty } from "../../../helper/helper";
import { toastAlert } from "../../../utils/toast";
import logo from "../../../assets/images/logo.svg";

const basicForm = {
  language: "en",
  title: "",
  discription: "",
  presentername: "",
  presenterImage: "",
  presenteremail: "",
  vaffixlive: true,
  youtubelive: false,
  facebooklive: false,
};

const sessionForm = {
  webinar_sessionType: "", // 0 - single, 1 - multiple , 2 - recurring
  webinar_sessions: [], // 0- limited , 1-unlimited
};

const regiatrationInitailForm = {
  meetingType: 0,
  templateId: "",
  firstName: 1,
  Lastname: 0,
  email: 1,
  phone: 1,
  registrationFees: 0,
  hasRegistration: 0,
  hasPassword: 0,
  notiMailId: "",
  masterpassword: "",
};

const SettingFrom = {
  message: "",
  urlLink: "",
  document: "",
  chat: false,
  listenOnlyMode: false,
  recording: false,
};

function ConfigSettings() {
  const { id } = useParams();

  const { token } = useParams();

  const location = useLocation();

  let location_result = location.state?.result;

  console.log(location_result, "location_result");

  // const languageRef = useRef();

  // const navigate = useNavigate();

  // const [webinar_Deatils, setWebinatDetails] = useState({});

  // let [completedFormlist, setCompltedFormList] = useState(id ? ["0"] : []);

  // console.log(location_result, 'location_result')

  const [loading, setloading] = useState(false);

  const languageRef = useRef();

  const navigate = useNavigate();

  const [webinar_Deatils, setWebinatDetails] = useState({});

  let [completedFormlist, setCompltedFormList] = useState(id ? ["0"] : []);

  const [activityKey, setAcitvityKey] = useState(id ? "1" : "0");

  const [details, setDetails] = useState();

  const [initialForm, setInitialForm] = useState(basicForm);

  const [schudleform, setScheduleForm] = useState(sessionForm);
  const [selectedSession, setSelectedSessions] = useState(0);

  let [RegisterForm, setRegisterForm] = useState(regiatrationInitailForm);

  const [showNotifivation, setShowNotification] = useState(false);

  const [dissableButton, setDisableButton] = useState(false);

  const [error, setError] = useState();

  const userDetails = useSelector((state) => state.User);
  console.log(userDetails, "userDetails");

  const [settingForm, setSettngform] = useState(SettingFrom);

  // completed

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (id) {
      getWebinarDetails();
    } else {
      instaanceFunction();
    }
  }, [id, token]);

  const instaanceFunction = async () => {
    console.log(token, "location_result");

    if (token) {
      const dep = await decryptString(token, true);
      console.log(dep, "dep");
      let data = JSON.parse(dep);
      console.log(data, "datadatadata");
      data["configure"] = data.configure == "full" ? 1 : 0;
      data["type"] = data.type == "Instant Meeting" ? 0 : 1;

      setDetails(data);
      setShowNotification(data.type == 0 ? false : true);
    }
    setInitialForm({
      ...initialForm,
      presentername: userDetails.userName,
      presenteremail: userDetails.email,
      presenterImage: userDetails.profile_image,
    });
  };

  console.log(details, "detailsssssss");
  const getWebinarDetails = async () => {  
    try {
      let { status, result } = await getWebinarData({ id: id });
      console.log(result, "getdatagetdata");

      if (location_result == "registractionPage") {
        console.log(location_result, "location_result");
        if (result.result.configType == 0) {
          completedFormlist.push("0");
        } else {
          completedFormlist.push("0");
          completedFormlist.push("1");
        }
        setAcitvityKey("2");
      }

      if (result.result.mettingType == 0) {
        setAcitvityKey("2");
      }

      console.log("registractionPage-----registractionPage");
      setWebinatDetails(result.result);

      languageRef.current.updateSelected(result.result.langugae);
      setDetails({
        configure: result.result.configType,
        type: result.result.mettingType,
      });
      setInitialForm({
        title: result.result.title,
        discription: result.result.description,
        language: result.result.langugae,
        presentername: result.result.presenterTempName,
        presenteremail: result.result.presenterTempEmail,
        presenterImage: result.result.presenterTempImage,
        vaffixlive: result.result.VaffixLiveStream,
        youtubelive: result.result.youtubeLiveStream,
        facebooklive: result.result.FacebookLiveStream,
      });

      setShowNotification(
        result.result.mettingType == 0
          ? result.result.masterPasword == ""
            ? false
            : true
          : true
      );

      if (result.result.sessions.length > 0) {
        const sessions = result.result.sessions.map((data) => ({
          sessionid: data._id,
          session_date: data.startDate,
          timezone: data.timeZone,
          Day: data.Day,
          recurringLimt: data.recurringLimt,
          NoOfSession: data.NoOfSession,
          completed_Status: data.status,
        }));
        setScheduleForm({
          webinar_sessionType: result.result.sessionType,
          webinar_sessions: sessions,
        });
        setSelectedSessions(result.result.sessionType);
      }

      console.log(result.result.mettingType, "result.result.mettingType");

      setRegisterForm({
        meetingType: result.result.mettingType,
        templateId: result.result.templateId ? result.result.templateId : 0,
        firstName: result.result.firstName,
        Lastname: result.result.Lastname,
        email: result.result.email,
        phone: result.result.phone,
        registrationFees: result.result.registrationFees,
        hasRegistration: result.result.registrationFees != 0 ? true : false,
        hasPassword: result.result.masterPasword ? true : false,
        // notiMailId: result.result.notiMailId != '' ? result.result.notiMailId : result.result.presenterTempEmail,
        notiMailId: result.result.notiMailId,
        notifylist: [userDetails.email, result.result.presenterTempEmail],
        masterpassword: result.result.pwd,
        hasNotified: result.result.notiMailId ? true : false,
      });

      setSettngform({
        message: result.result.welcomeMsg,
        urlLink: result.result.externalUrlLink,
        document: result.result.document,
        chat: result.result.attendiesChat,
        listenOnlyMode: result.result.ListenMode,
        recording: result.result.recording,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitBasicSetUp = async () => {
    try {
      console.log("rrrrrrrrrrrrrr-----------------11111111111111");
      const dat = await new_Basic_validation(initialForm);
      console.log(dat, "dat--------------111111111111111");

      if (isEmpty(dat)) {
        if (dissableButton) {
          return false;
        }

        setDisableButton(true);
        if (id) {
          setloading(true);
          const payload = {
            id,
            profile_image: initialForm.presenterImage,
            Presenters_name: initialForm.presentername,
            Presenters_email: initialForm.presenteremail,
            Webinar_title: initialForm.title,
            Webinar_language: initialForm.language,
            Webinar_description: initialForm.discription,
            vaffixlive: initialForm.vaffixlive,
            youtubelive: initialForm.youtubelive,
            facebooklive: initialForm.facebooklive,
          };
          console.log(payload, "payload11111");
          const { status, result } = await editWebinerBasic(payload);
          console.log(status, result, "status, result");
          //Expand collapse
          // setShow_Basicedit(!showBasic_edit);
          // set_show_schedule_edit(false);
          // setdefaultAcc(1);
          if (status) {
            if (details?.type != 0) {
              completedFormlist.push("0");
              setAcitvityKey("1");
            } else {
              completedFormlist.push("0");
              setAcitvityKey("2");
            }
            toastAlert("success", result?.message);
            setDisableButton(false);
          }
          console.log(status, result, "edit webinar");
        } else {
          if (isEmpty(dat)) {
            setloading(true);
            // const dep = await decryptString(details, true);
            // console.log(dep, "dep");
            // const deatils = JSON.parse(dep)

            console.log(details, "details-----1");

            const payload = {
              configure: details.configure,
              type: details.type,
              profile_image: initialForm.presenterImage,
              Presenters_name: initialForm.presentername,
              Presenters_email: initialForm.presenteremail,
              Webinar_title: initialForm.title,
              Webinar_language: initialForm.language,
              Webinar_description: initialForm.discription,
              vaffixlive: initialForm.vaffixlive,
              youtubelive: initialForm.youtubelive,
              facebooklive: initialForm.facebooklive,
            };

            console.log("rrrrrrrrrr---------payload");

            console.log(payload, "payload");

            const { status, result } = await Full_Create_Webinar(payload);
            console.log(status, result, "status, result");
            if (status) {
              if (details?.type != 0) {
                completedFormlist.push("0");
                setAcitvityKey("1");
              } else {
                completedFormlist.push("0");
                setAcitvityKey("2");
              }
              console.log("result", result.result._id);
              toastAlert("success", "Configuration settings completed");

              setDisableButton(false);

              window.location.href = `/configSettings/${result.result._id}`;
              // navigate();
            }
          } else {
            console.log(dat, "eeeeeeeeeeeeeeeeeee");
            setError(dat);
          }
        }
      } else {
        console.log(dat, "eeeeeeeeeeeeeeeeeee");
        setError(dat);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setloading(false);
    }
  };

  console.log(error, "schudleform");

  const submitWebinarSchedule = async () => {
    try {
      // console.log(AddedSession, sessionCtrl, "kkkk");
      // const sesionValidation = await NewValidation(AddedSession)
      setloading(true);

      // sessionstartDate,
      // timeZone,
      if (schudleform.webinar_sessions.length != 0) {
        if (dissableButton) {
          return false;
        }
        setDisableButton(true);
        const payloadData = {
          id: id,
          SessionData: schudleform.webinar_sessions,
          selectedSession:
            schudleform.webinar_sessionType == ""
              ? 0
              : schudleform.webinar_sessionType,
        };
        console.log(payloadData, "payloadData");

        // return

        var { status, result } = await editWebinarSchedule(payloadData);
        console.log(status, result, "status, result");

        if (status) {
          // set_show_schedule_edit(true);
          // set_show_notification_edit(false);
          completedFormlist.push("1");
          setAcitvityKey("2");
          toastAlert("success", "Schedule updated");
          setDisableButton(false);
        }
      } else {
        
        toastAlert("error", "Sessions is required");
        
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading(false);
    }
  };

  console.log(completedFormlist, "completedFormlist");

  const submitregisterForm = async () => {
    try {
      // console.log(AddedSession, sessionCtrl, "kkkk");
      // const sesionValidation = await NewValidation(AddedSession)
      setloading(true);

      const dat = await registervalidation(RegisterForm);

      console.log(dat, "datdatdatdatdatdatdat");

      if (!isEmpty(dat)) {
        setError(dat);

        return false;
      }

      if (dissableButton) {
        return false;
      }

      console.log(RegisterForm, "payloadData1111111111---------------1111");

      if (!RegisterForm.hasRegistration) {
        RegisterForm.registrationFees = 0;
      }
      setDisableButton(true);
      RegisterForm.id = id;

      var { status, result } = await editWebinarRegister(RegisterForm);
      console.log(status, result, "status, result");

      if (status) {
        // set_show_schedule_edit(true);
        // set_show_notification_edit(false);

        console.log(webinar_Deatils, "webinar_Deatils");

        if (webinar_Deatils.mettingType == 0) {
          if (RegisterForm.hasPassword) {
            setShowNotification(true);
          } else {
            setShowNotification(false);
          }
        }

        setError({});
        completedFormlist.push("2");
        setAcitvityKey("3");
        toastAlert("success", "Register updated");
        setDisableButton(false);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading(false);
    }
  };

  console.log(error, "errrrrrrrrrrrrrrrrrrrr");

  const submitSetting = async () => {
    try {
      // console.log(AddedSession, sessionCtrl, "kkkk");
      // const sesionValidation = await NewValidation(AddedSession)
      setloading(true);

      // sessionstartDate,
      // timeZone,

      console.log(settingForm, "payloadData");

      settingForm.id = id;

      // return
      if (dissableButton) {
        return false;
      }

      setDisableButton(true);
      var { status, result } = await editWebinarSettings(settingForm);
      console.log(status, result, "status, result");

      if (status) {
        // set_show_schedule_edit(true);
        // set_show_notification_edit(false);
        completedFormlist.push("3");
        if (showNotifivation) {
          setAcitvityKey("4");
        } else {
          setAcitvityKey("5");

          completedFormlist.push("5");

          handleShow();
        }
        toastAlert("success", "Settings updated");
        setDisableButton(false);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
      setloading(false);
    }
  };

  const confirmNotification = async () => {
    setAcitvityKey("5");

    completedFormlist.push("5");

    handleShow();
  };

  const editHandler = async (val) => {
    console.log(val, "valvalvalvalvalvalvalvalval");

    console.log(val, "valvalvalvalvalvalvalvalval", completedFormlist);

    if (val != "0" && completedFormlist.includes("0")) {
      if (
        details?.type == 0 ||
        (val == "1" && completedFormlist.includes("1")) ||
        (val == "2" && completedFormlist.includes("1")) ||
        (val == "3" && completedFormlist.includes("1")) ||
        (val == "4" && completedFormlist.includes("1"))
      ) {
        setAcitvityKey(val);

        const tempval = [...completedFormlist];

        const index = tempval.findIndex((list) => list === val);
        console.log(index, "completedFormlist");

        if (index !== -1) {
          tempval.splice(index, 1);
          setCompltedFormList(tempval);
        }
      } else {
        toastAlert("error", "Schedule not completed");
      }
    } else {
      if (val == "0") {
        const tempval = [...completedFormlist];

        const index = tempval.findIndex((list) => list === val);
        console.log(index, "completedFormlist");

        if (index !== -1) {
          tempval.splice(index, 1);
          setCompltedFormList(tempval);
        }
        setAcitvityKey(val);
      } else {
        toastAlert("error", "Basic settings not completed");
      }
    }
  };

  console.log(showNotifivation, "completedFormlist");

  const completeWebinar = () => {
    window.location.href = `/webinar/`;
  };

  return (
    <div className="dashboard-page">
      <div className="dashboard">
        <Innerpagenavbar />
        <div className="dashbord-right mt-2">
          <div className="dashbord-right-body-1">
            <div className="registration_nav_sec">
              <Scrollbars
                className="custom-scrollbar"
                style={{ width: "100%", height: 100 }}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    className="scrollbar-track vertical"
                    style={{ ...style }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    className="scrollbar-thumb vertical"
                    style={{ ...style }}
                  />
                )}
              >
                <ul className="registration_list">
                  <li>
                    <div
                      className={
                        completedFormlist.some((val) => val == "0") &&
                        id &&
                        activityKey != "0"
                          ? "registration_nav_content active"
                          : "registration_nav_content"
                      }
                    >
                      <div className="top-head-img">
                        <img
                          src={require("../../../assets/images/registration_imgs/notepad.png")}
                          className="img-fluid top-head"
                        />
                        <img
                          src={require("../../../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked"
                        />
                      </div>
                      <p>Configuration</p>
                      {completedFormlist.some((val) => val == "0") &&
                      id &&
                      activityKey != "0" ? (
                        <img
                          src={require("../../../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked1"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </li>

                  {details?.type != 0 ? (
                    <li>
                      <div
                        className={
                          completedFormlist.some((val) => val == "1") &&
                          id &&
                          activityKey != "1"
                            ? "registration_nav_content active"
                            : "registration_nav_content"
                        }
                      >
                        <div className="top-head-img">
                          <img
                            src={require("../../../assets/images/registration_imgs/calendar-tick.png")}
                            className="img-fluid top-head"
                          />
                          <img
                            src={require("../../../assets/images/registration_imgs/checked.png")}
                            className="img-fluid top-checked"
                          />
                        </div>
                        <p>Schedules</p>
                        {completedFormlist.some((val) => val == "1") &&
                        activityKey != "1" ? (
                          <img
                            src={require("../../../assets/images/registration_imgs/checked.png")}
                            className="img-fluid top-checked1"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  ) : (
                    ""
                  )}

                  <li>
                    <div
                      className={
                        completedFormlist.some((val) => val == "2") &&
                        id &&
                        activityKey != "2"
                          ? "registration_nav_content active"
                          : "registration_nav_content"
                      }
                    >
                      <div className="top-head-img">
                        <img
                          src={require("../../../assets/images/registration_imgs/driver.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Registration</p>
                      {completedFormlist.some((val) => val == "2") &&
                      activityKey != "2" ? (
                        <img
                          src={require("../../../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked1"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </li>

                  <li>
                    <div
                      className={
                        completedFormlist.some((val) => val == "3") &&
                        id &&
                        activityKey != "3"
                          ? "registration_nav_content active"
                          : "registration_nav_content"
                      }
                    >
                      <div className="top-head-img">
                        <img
                          src={require("../../../assets/images/registration_imgs/notification_2.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Othersetting</p>
                      {completedFormlist.some((val) => val == "3") &&
                      activityKey != "3" ? (
                        <img
                          src={require("../../../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked1"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </li>

                  {showNotifivation && (
                    <li>
                      <div
                        className={
                          completedFormlist.some((val) => val == "4") &&
                          id &&
                          activityKey != "4"
                            ? "registration_nav_content active"
                            : "registration_nav_content"
                        }
                      >
                        <div className="top-head-img">
                          <img
                            src={require("../../../assets/images/registration_imgs/notification_2.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Notification</p>
                        {completedFormlist.some((val) => val == "4") &&
                        activityKey != "4" ? (
                          <img
                            src={require("../../../assets/images/registration_imgs/checked.png")}
                            className="img-fluid top-checked1"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  )}

                  <li>
                    <div className="registration_nav_content">
                      <div className="top-head-img">
                        <img
                          src={require("../../../assets/images/video.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Finish</p>
                      {/* <img
                    src={require("../../../assets/images/registration_imgs/checked.png")}
                    className="img-fluid"
                  /> */}
                    </div>
                  </li>
                </ul>
              </Scrollbars>
            </div>
            <Accordion
              className="accMain registration "
              activeKey={[activityKey]}
              // defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accItem">
                <Accordion.Header>
                  <p>Configuration</p>

                  {activityKey != "0" ? (
                    <div className="accordion-header-content">
                      {completedFormlist.includes("0") && (
                        <>
                          <img
                            src={require("../../../assets/images/accordion-config.png")}
                            className="img-fluid"
                          />
                          <p>Configured</p>
                        </>
                      )}
                      <Link
                        className="accordionedit-btn"
                        onClick={() => editHandler("0")}
                      >
                        Edit
                      </Link>
                    </div>
                  ) : (
                    <div className="accordion-hearder-content">
                      <div>
                        <Link
                          to="/webinar"
                          className="primary_btn primary_btn2 me-4"
                        >
                          <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Cancel
                        </Link>
                        <Link
                          to="#"
                          className="primary_btn"
                          onClick={submitBasicSetUp}
                        >
                          <img
                            src={require("../../../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />{" "}
                          {/* <svg
                            width="8"
                            height="12"
                             fill="none"
                            viewBox="0 0 8 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                          Confirm
                        </Link>
                      </div>
                    </div>
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  <BasicFullconfig
                    webinarId={webinar_Deatils._id}
                    initialForm={initialForm}
                    setInitialForm={setInitialForm}
                    languageRef={languageRef}
                    error={error}
                  />
                </Accordion.Body>
              </Accordion.Item>

              {details?.type != 0 && (
                <Accordion.Item eventKey="1" className="mt-4">
                  <Accordion.Header>
                    <p>Webinar schedule </p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {activityKey != "1" ? (
                          <div className="accordion-header-content">
                            {completedFormlist.includes("1") && (
                              <>
                                <img
                                  src={require("../../../assets/images/accordion-config.png")}
                                  className="img-fluid"
                                />
                                <p>Configured</p>
                              </>
                            )}
                            <Link
                              className="accordionedit-btn"
                              onClick={() => editHandler("1")}
                            >
                              Edit
                            </Link>
                          </div>
                        ) : (
                          <div className="accordion-hearder-content">
                            <div>
                              <Link
                                to="/webinar"
                                className="primary_btn primary_btn2 me-4"
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Cancel
                              </Link>
                              <Link
                                to="#"
                                className="primary_btn"
                                onClick={submitWebinarSchedule}
                              >
                                <img
                                  src={require("../../../assets/images/chevron-right.png")}
                                  height="16"
                                  width="16"
                                  className="img-fluid"
                                />{" "}
                                {/* <svg
                            width="8"
                            height="12"
                             fill="none"
                            viewBox="0 0 8 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                                Confirm
                              </Link>
                            </div>
                          </div>
                        )}

                        {/* <img
                        src={require("../../../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn" >Edit</Link> */}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Webinarschedulefullconfig
                      schudleform={schudleform}
                      setScheduleForm={setScheduleForm}
                      selectedSession={selectedSession}
                      setSelectedSessions={setSelectedSessions}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              )}

              <Accordion.Item eventKey="2" className="mt-4">
                <Accordion.Header>
                  <p>Registration </p>
                  <div className="accordion-header-content">
                    {activityKey != "2" ? (
                      <div className="accordion-header-content">
                        {completedFormlist.includes("2") && (
                          <>
                            <img
                              src={require("../../../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />
                            <p>Configured</p>
                          </>
                        )}

                        <Link
                          className="accordionedit-btn"
                          onClick={() => editHandler("2")}
                        >
                          Edit
                        </Link>
                      </div>
                    ) : (
                      <div className="accordion-hearder-content">
                        <div>
                          <Link
                            to="/webinar"
                            className="primary_btn primary_btn2 me-4"
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.5 1L6.5 6L1.5 11"
                                stroke="#001F2A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Cancel
                          </Link>
                          <Link
                            to="#"
                            className="primary_btn"
                            onClick={submitregisterForm}
                          >
                            <img
                              src={require("../../../assets/images/chevron-right.png")}
                              height="16"
                              width="16"
                              className="img-fluid"
                            />{" "}
                            {/* <svg
                            width="8"
                            height="12"
                             fill="none"
                            viewBox="0 0 8 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                            Confirm
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </Accordion.Header>
                <Accordion.Body className="inner-accMain">
                  <RegistrationFullconfig
                    RegisterForm={RegisterForm}
                    setRegisterForm={setRegisterForm}
                    error={error}
                    webinarId={id}
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3" className="mt-4">
                <Accordion.Header>
                  <p>Other Settings</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {activityKey != "3" ? (
                        <div className="accordion-header-content">
                          {completedFormlist.includes("3") && (
                            <>
                              <img
                                src={require("../../../assets/images/accordion-config.png")}
                                className="img-fluid"
                              />
                              <p>Configured</p>
                            </>
                          )}
                          <Link
                            className="accordionedit-btn"
                            onClick={() => editHandler("3")}
                          >
                            Edit
                          </Link>
                        </div>
                      ) : (
                        <div className="accordion-hearder-content">
                          <div>
                            <Link
                              to="/webinar"
                              className="primary_btn primary_btn2 me-4"
                            >
                              <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 1L6.5 6L1.5 11"
                                  stroke="#001F2A"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              Cancel
                            </Link>
                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={submitSetting}
                            >
                              <img
                                src={require("../../../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid"
                              />{" "}
                              {/* <svg
                            width="8"
                            height="12"
                             fill="none"
                            viewBox="0 0 8 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                              Confirm
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Othersettingfullconfig
                    settingForm={settingForm}
                    setSettngform={setSettngform}
                  />
                </Accordion.Body>
              </Accordion.Item>

              {showNotifivation && (
                <Accordion.Item eventKey="4" className="mt-4">
                  <Accordion.Header>
                    <p>Notification</p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {activityKey != "4" ? (
                          <div className="accordion-header-content">
                            {completedFormlist.includes("4") && (
                              <>
                                <img
                                  src={require("../../../assets/images/accordion-config.png")}
                                  className="img-fluid"
                                />
                                <p>Configured</p>
                              </>
                            )}

                            <Link
                              className="accordionedit-btn"
                              onClick={() => editHandler("4")}
                            >
                              Edit
                            </Link>
                          </div>
                        ) : (
                          <div className="accordion-hearder-content">
                            <div>
                              <Link
                                to="/webinar"
                                className="primary_btn primary_btn2 me-4"
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Cancel
                              </Link>
                              <Link
                                to="#"
                                className="primary_btn"
                                onClick={confirmNotification}
                              >
                                <img
                                  src={require("../../../assets/images/chevron-right.png")}
                                  height="16"
                                  width="16"
                                  className="img-fluid"
                                />{" "}
                                {/* <svg
                            width="8"
                            height="12"
                             fill="none"
                            viewBox="0 0 8 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.5 1L6.5 6L1.5 11"
                              stroke="#001F2A"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg> */}
                                Confirm
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Notificationfullconfig EditData={webinar_Deatils} />
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
            {/* <div className="footer_img mt-5">
              <a href="#">
                {" "}
                <img src={nextImg} alt="img" className="img-fluid" />
              </a>
              <a href="#">
                {" "}
                <img
                  src={require("../../../assets/images/registration_imgs/blue_arrow.png")}
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </div> */}
          </div>
          <Innerfooter />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                            <div>

                            </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../../../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage">
                <h2 className="mb-3">Congratulations</h2>
                <p>Your Webinar has been successfully created</p>
              </div>
              <center>
                <button onClick={completeWebinar} className="primary_btn mt-3">
                  Go To Webinar List
                </button>
              </center>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default ConfigSettings;
