import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWebinarDetails, searchMeetings } from "../../action/user.action";

const initialState = {};

export const Webinar_Details = createAsyncThunk("Webinar", async (data) => {
  data = data ? data : 0;
  const { status, result } = await getWebinarDetails(data);
  if (status) {
    console.log("result ===", result.result);
    return result.result;
  }
});

export const Search_Details = createAsyncThunk("Webinar", async (data) => {
  const { status, result } = await searchMeetings(data);
  if (status) {
    console.log("search-meetings:=", result.result);
    return result.result;
  }
});

const WebinarSlice = createSlice({
  name: "Webinar",
  initialState,
  reducers: {
    setWebinar: (state, action) => {
      state = action.payload;
      return state;
    },
  },
  extraReducers: (build) => {
    build.addCase(Webinar_Details.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  },
  extraReducers: (build) => {
    build.addCase(Search_Details.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  },
});

export const { setWebinar } = WebinarSlice.actions;

export default WebinarSlice.reducer;
