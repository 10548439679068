import React, { useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import ReactHtmlParser from 'react-html-parser';
import { unlayerSampleJSON } from "../regtemplate/Canvafiles/unlayerSampleJSON";

import { toastAlert } from "../../utils/toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
//API
import { saveRegistartionTemplate } from "../../action/user.action";

const emailJSON = unlayerSampleJSON;

const CanvaTemplate = () => {
    const [editHtml, setEditHtml] = useState('');
    const emailEditorRef = useRef({ editor: {} });

    const navigate = useNavigate();
    const { webinarId } = useParams()

    console.log("webinarId",webinarId)

   
    const handleCanvaClose = () => setCanva(false);
    const handleCanvaShow = () => setCanva(true);
    const [canva, setCanva] = useState(false);

    const saveTemplate = async () => {
        // Use editHtml state for content
        let content = editHtml;

        console.log("webinarId",webinarId)
    
        // PAYLOAD
        let payload = {
            content: content,
            identifier: "Canva",
            default_template: "false",
            status: "active",
            webinarId: webinarId,
            template_type: 1,
            templateId: "none",
        };
    
        try {
            let { status, result } = await saveRegistartionTemplate(payload);
            console.log(status, result, "status, result");
    
            if (status) {
                setCanva(false)
                toastAlert("success", result?.message);
                redirectToWebinarConfig();
            }
        } catch (err) {
            console.log(err, "err");
        } finally {
        }
    };

    const redirectToWebinarConfig = () => {
        navigate(`/configSettings/${webinarId}`, {
          state: { result: "registractionPage" },
        });
      };
    

    const onDesignLoad = (data) => {
        // console.log('onDesignLoad', data);
        // console.log(emailEditorRef.current);
    };

    const onLoad = () => {
        console.log("ref: ", emailEditorRef.current);
        emailEditorRef.current.editor.loadDesign(emailJSON);

        // This autosaves design after every change
    };

    const saveDesign = () => {
        const unlayer = emailEditorRef.current.editor;

        unlayer.saveDesign((design) => {
            console.log('saveDesign', design);
            alert('Design JSON has been logged in your developer console.');
        });
    };

    const exportHtml = () => {
        const unlayer = emailEditorRef.current.editor;

        unlayer.exportHtml((data) => {
            const { design, html } = data;
            let styleTags = html.match(/<style[^>]*>[\s\S]*<\/style>/gi);
            let bodyTags = html.match(/<body[^>]*>[\s\S]*<\/body>/gi);
            console.log('exportHtml', bodyTags);
            setEditHtml(bodyTags);
            // alert('Output HTML has been logged in your developer console.');
            setCanva(true)
        });
    };

    const products = [
        {
            id: 1,
            image: "https://img.thriftbooks.com/api/images/i/m/B4380A756292C0D50D92213A920E5A9E6C1DD713.jpg",
            title: "The Great Believers",
            description: "A page turner... An absorbing and emotionally riveting story about what it's like to live during times of crisis. The New York Times Book Review A dazzling new novel of friendship and redemption in the face of tragedy and loss set in 1980s Chicago and contemporary Paris In 1985, Yale Tishman, the development director for an art gallery in Chicago, is about to pull off an amazing coup, bringing in an extraordinary collection of 1920s paintings as a gift to the gallery.",
        },
        {
            id: 2,
            image: "https://img.thriftbooks.com/api/images/i/m/F0836F0E91E904515A5A47078C555303CCC67351.jpg",
            title: "The Hundred-Year House",
            description: "From the acclaimed author of The Great Believers, an original, mordantly witty novel about the secrets of an old-money family and their turn-of-the-century estate, Laurelfield. Meet the Devohrs: Zee, a Marxist literary scholar who detests her parents' wealth but nevertheless finds herself living in their carriage house; Gracie, her mother, who claims she can tell your lot in life by looking at your teeth; and Bruce, her step-father, stockpiling supplies for the Y2K apocalypse and perpetually late for his tee time.",
        },
    ];

    return (
        <div>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                projectId={144031}
                tools={{
                    "custom#product_tool": {
                        data: {
                            products,
                        },
                        properties: {
                            productLibrary: {
                                editor: {
                                    data: {
                                        products,
                                    },
                                },
                            },
                        },
                    }
                }}
                options={{
                    designTags: {
                        business_name: "My business",
                        current_user_name: "Ron",
                    },
                    customCSS: [
                        "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                    ],
                    customJS: [
                        window.location.protocol + "//" + window.location.host + "/custom.js",
                    ]
                }}
            />
            <div>
                {/* <button type="button" onClick={saveDesign}>Save</button> */}
                <button type="button" class="login_button mt-4" onClick={exportHtml}>Save</button>
            </div>
            <div>
                {/* {ReactHtmlParser(editHtml)} */}
                <Modal
                    size="lg"
                    show={canva}
                    onHide={handleCanvaClose}
                    keyboard={false}
                    centered
                    className="emailnotifications"
                >
                    <Modal.Header className="model-head">
                        <Modal.Title className="model-title">
                            Canva Template
                        </Modal.Title>
                        <div>
                            <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleCanvaClose}
                            >
                                <svg
                                    width="8"
                                    height="12"
                                    viewBox="0 0 8 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.5 1L6.5 6L1.5 11"
                                        stroke="#001F2A"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                Cancel
                            </Link>
                            <button
                                className="primary_btn"
                                type="button"
                                onClick={saveTemplate}
                            >
                                Confirm
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="mailnotify-body">
                        {ReactHtmlParser(editHtml)}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CanvaTemplate;
