import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  getDashboard_data,
  searchMeetings,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";


const InnerHome = () => {

  const [dashboardData,setDashboardData]=useState({})
  const GetDashboard_details = async()=>{
    try {      
      const {result, status}=await getDashboard_data()
      console.log(result, status,'dashboard:=')
      setDashboardData(result.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    GetDashboard_details()
  },[])


  return (
    <>
 <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />

          <div className="dashbord-right mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding ">
                <div className="inner_home_shadow">
              <div className="dashbord-right-header inner_home_dashboard">
                <p>Dashboard</p>
               </div>
               <div className="inner_home_body">
                <Row>
                    <Col xl={4} lg={6}>
                    <div className="inner_home_content">
                    <img
                            src={require("../assets/images/inner_dashboard_img.png")}
                            height="80"
                            width="80"
                            className="img-fluid"
                          />
                          <div>
                        <h5>{dashboardData.totalNoOfSession}</h5>
                        <p>Total no of sessions created</p>
                        </div>
                    </div>
                    </Col>
                    <Col xl={4} lg={6}>
                    <div className="inner_home_content">
                    <img
                            src={require("../assets/images/inner_dashboard_img1.png")}
                            height="80"
                            width="80"
                            className="img-fluid"
                          />
                          <div>
                        <h5>{dashboardData.notComplted}</h5>
                        <p>Upcoming session </p>
                        </div>
                    </div>
                    </Col>
                    <Col xl={4}lg={6}>
                    <div className="inner_home_content">
                    <img
                            src={require("../assets/images/inner_dashboard_img2.png")}
                            height="80"
                            width="80"
                            className="img-fluid"
                          />
                          <div>
                        <h5>{dashboardData.completedSession}</h5>
                        <p>Completed sessions</p>
                        </div>
                    </div>
                    </Col>
                    <Col xl={6}lg={6}>
                    <div className="inner_home_content mt-4">
                    <img
                            src={require("../assets/images/inner_dashboard_img3.png")}
                            height="80"
                            width="80"
                            className="img-fluid"
                          />
                          <div>
                        <h5>{dashboardData.webinarCount}</h5>
                        <p>No of Webinars</p>
                        </div>
                    </div>
                    </Col>
                    <Col xl={6}>
                    <div className="inner_home_content mt-4">
                    <img
                            src={require("../assets/images/inner_dashboard_img4.png")}
                            height="80"
                            width="80"
                            className="img-fluid"
                          />
                          <div>
                        <h5>{dashboardData.registerUser}</h5>
                        <p>Total User Registered </p>
                        </div>
                    </div>
                    </Col>
                </Row>

               </div>
               </div>
           



            




            </div>
            <Innerfooter />
            <div></div>
          </div>
        </div>
      </div>

    </>
  );
};
export default InnerHome;
