import React, { useEffect, useState } from "react";
import { Completed_meeting_Details } from "../../action/user.action";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const FinishMeeting = () => {
    const { id } = useParams();

    console.log(id, "webinar  analytics");

    // const navigate = useNavigate()
    // const [infoData, setInfoData] = useState({})

    // const [initialState, setInitialState] = useState({});
    // const [chartData, setChartData] = useState([]);
    // const [xAxisData, setXaxisData] = useState([]);

    // const getCompleted_Meetings = async () => {
    //     try {
    //         const payload = {
    //             id: id,
    //         };
    //         const { status, result } = await Completed_meeting_Details(payload);

    //         console.log(status, result, "sakthi ----- dashboard");
    //         if (status) {

    //             setInfoData({ ...result.dashboardLink })
    //             // for (let i of result.dashboardDate.result) {
    //             //     console.log(i, 'iiiiiiiii')
    //             //     chartData.push(i.users)
    //             //     xAxisData.push(i.intervalStart)
    //             // }
    //             // result.result.session_Length = result.dashboardDate.session_length
    //             // setInitialState(result.result)
    //         } else {
    //             setInfoData({ ...result.dashboardLink })
    //         }
    //     } catch (error) {
    //         // setInfoData({ ...result.dashboardLink })


    //         console.log(error);
    //     }
    // };

    // console.log(chartData, xAxisData, "xAxisData");
    // useEffect(() => {
    //     if (id) {
    //         getCompleted_Meetings();
    //     }
    // }, [id]);



    // const InfoHandler = () => {
    //     console.log("WebinarAnalytics", infoData);

    //     navigate("/WebinarAnalytics", { state: infoData });
    // }


    return (
        <div>

            {/* <Button variant="primary" onClick={handleShow}> */}
                Launch static backdrop modal
            {/* </Button> */}

          
        </div>
    );
};
export default FinishMeeting;
