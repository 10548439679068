

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get_UserDetails } from '../../action/user.action'

const initialState = {
    first_name: '',
    last_name: '',
    userId: '',
    email: '',
    is_Email_Verifed: '',
    company: '',
    phoneNo: ''
}

export const getUserDetails = createAsyncThunk('User', async () => {
    const { status, result } = await get_UserDetails()
    console.log(status, result, 'UserSlice ------ redux')
    if (status) {
        return result.result
    }
})


const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            state = action.payload
            return state
        }
    },
    extraReducers(build) {
        build.addCase(getUserDetails.fulfilled, (state, action) => {
            state = action.payload
            return state
        })
    }
})

export const { setUserDetails } = UserSlice.actions
export default UserSlice.reducer