import React from "react";
import Navinner from "../components/nav-inner";
const Innerpagenavbar = () => {
  return (
    <>
      <div className="nav-sidebar">
        <Navinner />
      </div>

      <div className="nav-sidebar2">
        <div className="sidebar-nav">
          <nav class="navbar nav-bar  fixed-top">
            <a class="navbar-brand" href="#">
              <img
                src={require("../assets/images/logo-light.png")}
                className="img-fluid"
              />{" "}
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="offcanvas offcanvas-start text-bg-dark"
              tabindex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <Navinner />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Innerpagenavbar;
