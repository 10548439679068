import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../../components/inner-footer";
import Template_navbar from "../../components/template-navbar";
import ReactHtmlParser from 'react-html-parser';

//EDITOR
import { CKEditor, useCKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  InlineEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
//API
import { getRegistartionTemplate } from "../../action/user.action";
const Template_5 = () => {

  const { id, tid, meetingType } = useParams();

  //EDITOR
  let plugins = [
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
  ];
  let toolbar = [
    'undo', 'redo', '|',
    'heading', '|', 'bold', 'italic', '|',
    'link', 'insertTable', 'mediaEmbed', '|',
    'bulletedList', 'numberedList', 'indent', 'outdent'
  ];

  //REF
  let editorRef = useRef(null);
  //STATE
  const [editorObj, setEditorObj] = useState(null);
  const [templateValue, setTemplateValue] = useState(null);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);

  useEffect(() => {
    if (tid != "none") {
      getTemplate();
    }
  }, [tid])

  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: tid
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      if (status) {
        setTemplateValue(result?.result?.content);
      }
    } catch (err) {
      console.log(err, "err");
    } finally { }
  }

  //EXTRACT - CKEDITOR DATA
  useEffect(() => {
    if (templateValue) {
      for (let i = 0; i < document.getElementsByClassName('ck').length; i++) {
        console.log(document.getElementsByClassName('ck')[i]?.innerHTML?.toString())
        setTempData(tempData => [...tempData, document.getElementsByClassName('ck')[i]?.innerHTML?.toString()]);
      }
    }
  }, [templateValue]);
  return (
    <>
      <Template_navbar identifier={"template5"} webinarId={id} templateId={tid} />


      {(templateValue) && (
        <div style={{ display: "none" }}> {ReactHtmlParser(templateValue)} </div>
      )}

      {(tid != "none" && tempData?.length !== 0) ? (
        <div className="templateData">
          <section className="temp_5 template5-font">
            <Container>
              <div class="temp_5_head">

                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `${tempData?.[0]?.toString()}`,
                  }}
                />

                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `${tempData?.[1]?.toString()}`,
                  }}
                />

              </div>
              <div class="row_content">
                <div class="video_content">
                  <video class="sample_vdo" controls>
                    <source src={require("../../assets/images/template_img/sample_vdo.mp4")} type="video/mp4" />
                  </video>
                </div>

                <div class="orange_box">

                  <CKEditor
                    editor={InlineEditor}
                    config={{
                      toolbar: toolbar,
                      plugins: plugins,
                      initialData: `${tempData?.[2]?.toString()}`,
                    }}
                  />

                  <CKEditor
                    editor={InlineEditor}
                    config={{
                      toolbar: toolbar,
                      plugins: plugins,
                      initialData: `${tempData?.[3]?.toString()}`,
                    }}
                  />


                  <div class="btn-orange">
                  <a href="#" id="joinlink"><button>Enroll Now</button></a>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </div>
      ) : ((tid == "none") && (
        <div className="templateData">
          <section className="temp_5 template5-font">
            <Container>
              <div class="temp_5_head">

                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `<p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum
                  </p>`,
                  }}
                />

                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `<h2>
                    Lorem Ipsum is simply dummy text simply dummy text Lorem Ipsum is
                    simply dummy text
                  </h2>`,
                  }}
                />


              </div>
              <div class="row_content">
                <div class="video_content">
                  <video class="sample_vdo" controls>
                    <source src={require("../../assets/images/template_img/sample_vdo.mp4")} type="video/mp4" />
                  </video>
                </div>

                <div class="orange_box">
                  <CKEditor
                    editor={InlineEditor}
                    config={{
                      toolbar: toolbar,
                      plugins: plugins,
                      initialData: `<h5>Lorem Ipsum is simply dummy text</h5>`,
                    }}
                  />

                  <CKEditor
                    editor={InlineEditor}
                    config={{
                      toolbar: toolbar,
                      plugins: plugins,
                      initialData: `<ul>
                      <li>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                        quae ab illo inventore veritatis et quasi architecto beatae
                        vitae dicta sunt explicabo.
                      </li>
                      <li>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                        quae ab illo inventore veritatis et quasi architecto beatae
                        vitae dicta sunt explicabo.
                      </li>
                      <li>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                        quae ab illo inventore veritatis et quasi architecto beatae
                        vitae dicta sunt explicabo.
                      </li>
                    </ul>`,
                    }}
                  />



                  <div class="btn-orange">
                  <a href="#" id="joinlink"><button>Enroll Now</button></a>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </div>
      ))}


      <Innerfooter />
    </>
  );
};
export default Template_5;
