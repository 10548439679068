import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import {
  Register_User,
  Resend_Email,
  Verify_Email,
} from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
function EmailVerify() {
  const navigate = useNavigate();

  let data = sessionStorage.getItem("USER_AUTH_VERIFY_TOKEN_DATA");
  if (data == null) {
    navigate("/register");
  }

  const resendlink = async () => {
    try {
      const { status, result } = await Resend_Email(data);
      if (status) {
        sessionStorage.removeItem("USER_AUTH_VERIFY_TOKEN_DATA");
        navigate("/ConfirmMail");
      }
    } catch (error) {
      toastAlert("error", "Something went wrong,please try again later");
    }
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>

      <section className="login_page">
        <Container>
          <div className=" login_content verify_content ">
            <h4 className="mb-4">Verify Your Account </h4>
            <Alert key={"primary"} className="p-2 alert_email">
              <i className="me-2 fa fa-info-circle"></i>
              Your account has been successfully registered and pending for
              email verification. If you're not receive email,&nbsp;&nbsp;
              <a href="javascript:void(0)" onClick={resendlink}>
                click here
              </a>
            </Alert>
            <p className="mb-2">
              We have sent an email with confirmation link to your email
              address. In order to complete the signup process, please click on
              the confirmation link.
            </p>
            <p className="mb-4">
              If you dont receive any email, please check your spam folder or
              wait for few minutes to receive email. Also, please verify that
              you entered a valid email address during registration
            </p>{" "}
            <div className="text-center mt-4">
              <Link to="/login" className="primary_btn">
                Sign In
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default EmailVerify;
