import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Form from "react-bootstrap/Form";
import Footer from "../components/Footer";
import {
  faEye,
  faEyeSlash,
  faSkating,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import { Login_user, verify_recaptcha } from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { setCookies } from "../utils/cookies";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { ValidateEmail } from "../helper/validator";
import config from "../lib/config";
import { getUserDetails } from "../redux/features/userdetails";
import { useDispatch } from "react-redux";

const InitialFormValue = {
  email: "",
  password: "",
  rememberMe: false,
};

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formValue, setFormValue] = useState(InitialFormValue);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(null);
  const [showPwd, setShowPwd] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState();
  const { email, password, rememberMe } = formValue;

  const [mailactivation, setmailactivation] = useState(
    location.state == undefined ? "done" : location.state.result
  );

  

  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      if (id == "rememberMe") {
        setFormValue({ ...formValue, [id]: !formValue.rememberMe });
      } else {
        setFormValue({ ...formValue, [id]: value });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (email.trim() == "") {
        validateError.email = "Email is required";
      } else if (!ValidateEmail(email)) {
        validateError.email = "Invalid email address";
      }
      if (password.trim() == "") {
        validateError.password = "Password is required";
      }
      setError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const submit_Handler = async (e) => {
    e.preventDefault();
    try {
      setloading(true);
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        setloading(true);
        // if (!executeRecaptcha) {
        //   return;
        // }
        // const token = await executeRecaptcha("login");
        //const { status, result } = await verify_recaptcha(token);
        //if (status) {
        const { status, result } = await Login_user(formValue);
        if (status) {
          toastAlert("success", result.message);
          console.log(result.token, "resultresultresult");
          setCookies(result.token, formValue.rememberMe);
          window.location.href = "/webinar";
        } else {
          setError(result.message);
        }
        //}
        // return false
      }
    } catch (error) {
      toastAlert("error", "Something went wrong,please try again later");
    } finally {
      setloading(false);
    }
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>

      <section className="login_page">
        <Container>
          {/* <a href="/">
            <img
              src={logo}
              className="img-fluid brand_logo mx-auto d-block"
              alt="logo"
            />
          </a> */}

          <form>
            <div className="login_content mt-5">
              <h4 className="text-center">Login</h4>

              {mailactivation === true && (
                <Alert key={"success"} variant={"success"} className="p-2">
                  <i className="me-2 fa-solid fa-circle-check"></i>
                  Your Email has been activated. Please login your account!
                </Alert>
              )}
              {mailactivation === false && (
                <Alert key={"danger"} variant={"danger"} className="p-2">
                  <i className="me-2 fa-solid fa-circle-xmark"></i>
                  Your account has been activated already. Please login your
                  account!
                </Alert>
              )}
              <div class="mb-4 mt-4">
                <label for="exampleFormControlInput1" class="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={changesHadler}
                />
                {error && error.email ? (
                  <span style={{ color: "red" }}>{error.email}</span>
                ) : (
                  ""
                )}
              </div>
              <label for="exampleFormControlInput1" class="form-label">
                Password
              </label>
              <div class="input-group mb-2">
                <input
                  type={showPwd ? "text" : "password"}
                  class="form-control"
                  id="password"
                  placeholder="Enter your password"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value={password}
                  onChange={changesHadler}
                />
                <span class="input-group-text" id="basic-addon2">
                  {showPwd ? (
                    <i
                      className="fa-regular fa-eye-slash"
                      onClick={() => setShowPwd(!showPwd)}
                    ></i>
                  ) : (
                    <i
                      className="fa-regular fa-eye"
                      onClick={() => setShowPwd(!showPwd)}
                    ></i>
                  )}
                </span>
              </div>
              {error && error.password ? (
                <span style={{ color: "red" }}>{error.password}</span>
              ) : (
                ""
              )}

              <div className="forgetpassword1">
                <Form.Check
                  type="checkbox"
                  id="rememberMe"
                  onChange={changesHadler}
                  label="Remember Me"
                />{" "}
                <a href="/forgetpassword">Forgot your password?</a>
              </div>
              <button
                //type="button"
                type="submit"
                class="login_button mt-4"
                disabled={loading}
                onClick={submit_Handler}
              >
                {loading && (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    id="circle"
                  ></i>
                )}
                Login
              </button>
              <p className="text-center mt-4">
                No account yet? <a href="/register"> Sign up now!</a>
              </p>
              {/* <p className="text-center mt-4">Or sign in with</p> */}
              {/* <div className="facebook_button mt-4">
              <a href="#" target="_blank" className="icon_button">
                <span>
                  <img
                    src={require("../assets/images/facebook-icon.png")}
                    className="img-fluid"
                    alt="face"
                  />
                </span>{" "}
                Facebook
              </a>
            </div> */}
            </div>
          </form>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Login;
