import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import nextImg from "../../assets/images/side-icon.svg";

import "react-datepicker/dist/react-datepicker.css";

import Navinner from "../../components/nav-inner";
import { Add_registractionForm } from "../../action/user.action";
import Innerfooter from "../../components/inner-footer";

const RegistrationPage = () => {
  const [selectedSession, setSelectedSession] = useState(null);

  const handleSessionSelect = (session) => {
    setSelectedSession(session);
  };

  const [activeBody, setActiveBody] = useState(0);

  const [addedFileds, setAddedFileds] = useState({});

  const [filedValues, setfiledValues] = useState([]);

  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };

  useEffect(() => {}, [filedValues]);
  const FieldHandler = async (e) => {
    console.log(e.target);
    let { id, value } = e.target;

    addedFileds.id = value;
    addedFileds.placeholder = value;
  };

  const AddiledHandler = async () => {
    setfiledValues([...filedValues, addedFileds]);
    setAddedFileds({});
  };

  console.log(filedValues, "filedValues");

  const removeHandler = async (i) => {
    // try {
    console.log(i, "removeHandler");
    filedValues.splice(i, 1);
    console.log(filedValues, "removeHandler");
  };

  const SubmitHandler = async (e) => {
    try {
      const payload = {
        templateName: activeBody,
        form_data: filedValues,
      };
      const { status, result } = await Add_registractionForm(payload);

      console.log(status, result, "status, result");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <div className="nav-sidebar">
            <Navinner />
          </div>

          <div className="nav-sidebar2">
            <div className="sidebar-nav">
              <nav class="navbar nav-bar  fixed-top">
                <a class="navbar-brand" href="#">
                  <img
                    src={require("../../assets/images/logo-light.png")}
                    className="img-fluid"
                  />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasDarkNavbar"
                  aria-controls="offcanvasDarkNavbar"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div
                  class="offcanvas offcanvas-start text-bg-dark"
                  tabindex="-1"
                  id="offcanvasDarkNavbar"
                  aria-labelledby="offcanvasDarkNavbarLabel"
                >
                  <div class="offcanvas-header">
                    <button
                      type="button"
                      class="btn-close btn-close-white"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body">
                    <Navinner />
                  </div>
                </div>
              </nav>
            </div>
          </div>
          <div className="dashbord-right mt-2">
            <div className="registration_nav_sec">
              <ul className="registration_list">
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/notepad.png")}
                      className="img-fluid"
                    />
                    <p>Configuration</p>
                    <img
                      src={require("../../assets/images/registration_imgs/checked.png")}
                      className="img-fluid"
                    />
                  </div>
                </li>
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/calendar-tick.png")}
                      className="img-fluid"
                    />
                    <p>Schedules</p>
                    <img
                      src={require("../../assets/images/registration_imgs/checked.png")}
                      className="img-fluid"
                    />
                  </div>
                </li>
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/driver.png")}
                      className="img-fluid"
                    />
                    <p>Registration</p>
                    <img
                      src={require("../../assets/images/registration_imgs/checked.png")}
                      className="img-fluid"
                    />
                  </div>
                </li>
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/notification_2.png")}
                      className="img-fluid"
                    />
                    <p>Notification</p>
                  </div>
                </li>
              </ul>
            </div>
            <Accordion
              className="accMain registration mt-4"
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accItem">
                <Accordion.Header>
                  <p>Registration Page Design</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                    </div>
                    <img
                      src={require("../../assets/images/accordion-config.png")}
                      className="img-fluid"
                    />{" "}
                    <p>Configured</p>
                    <Link className="accordionedit-btn">Edit</Link>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="dashbordrightbody-body">
                    <div className="dashbordrightbody-list1 registration_radio">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          Enable Split Testing
                        </label>
                        <img
                          src={require("../../assets/images/registration_imgs/question_mark.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div className="registration_sec mt-5">
                        <a
                          href="#"
                          className={`registration_body  ${
                            activeBody === 1 ? "active" : ""
                          }`}
                          onClick={() => handleBodyClick(1)}
                        >
                          <img
                            src={require("../../assets/images/registration_imgs/user_img.png")}
                            className="img-fluid"
                          />
                          <div className="registration_info mt-3">
                            <p>
                              Template name : <span>Vaf 001</span>{" "}
                              <img
                                src={require("../../assets/images/registration_imgs/eye_icon.png")}
                                className="img-fluid"
                              />{" "}
                            </p>

                            <div className="registration_info_1">
                              <img
                                src={require("../../assets/images/registration_imgs/pencil_square.png")}
                                className="img-fluid"
                              />
                              <p>Customize</p>
                            </div>
                          </div>
                        </a>

                        <a
                          href="#"
                          className={`registration_body  ${
                            activeBody === 2 ? "active" : ""
                          }`}
                          onClick={() => handleBodyClick(2)}
                        >
                          <img
                            src={require("../../assets/images/registration_imgs/user_img_1.png")}
                            className="img-fluid"
                          />
                          <div className="registration_info mt-3">
                            <p>
                              Template name : <span>Vaf 001</span>{" "}
                              <img
                                src={require("../../assets/images/registration_imgs/eye_icon.png")}
                                className="img-fluid"
                              />{" "}
                            </p>

                            <div className="registration_info_1">
                              <img
                                src={require("../../assets/images/registration_imgs/pencil_square.png")}
                                className="img-fluid"
                              />
                              <p>Customize</p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          className={`registration_body  ${
                            activeBody === 3 ? "active" : ""
                          }`}
                          onClick={() => handleBodyClick(3)}
                        >
                          <img
                            src={require("../../assets/images/registration_imgs/user_img.png")}
                            className="img-fluid"
                          />
                          <div className="registration_info mt-3">
                            <p>
                              Template name : <span>Vaf 001</span>{" "}
                              <img
                                src={require("../../assets/images/registration_imgs/eye_icon.png")}
                                className="img-fluid"
                              />{" "}
                            </p>

                            <div className="registration_info_1">
                              <img
                                src={require("../../assets/images/registration_imgs/pencil_square.png")}
                                className="img-fluid"
                              />
                              <p>Customize</p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          className={`registration_body  ${
                            activeBody === 4 ? "active" : ""
                          }`}
                          onClick={() => handleBodyClick(4)}
                        >
                          <img
                            src={require("../../assets/images/registration_imgs/user_img_1.png")}
                            className="img-fluid"
                          />
                          <div className="registration_info mt-3">
                            <p>
                              Template name : <span>Vaf 001</span>{" "}
                              <img
                                src={require("../../assets/images/registration_imgs/eye_icon.png")}
                                className="img-fluid"
                              />{" "}
                            </p>

                            <div className="registration_info_1">
                              <img
                                src={require("../../assets/images/registration_imgs/pencil_square.png")}
                                className="img-fluid"
                              />
                              <p>Customize</p>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          className={`registration_body  ${
                            activeBody === 5 ? "active" : ""
                          }`}
                          onClick={() => handleBodyClick(5)}
                        >
                          <img
                            src={require("../../assets/images/registration_imgs/user_img.png")}
                            className="img-fluid"
                          />
                          <div className="registration_info mt-3">
                            <p>
                              Template name : <span>Vaf 001</span>{" "}
                              <img
                                src={require("../../assets/images/registration_imgs/eye_icon.png")}
                                className="img-fluid"
                              />{" "}
                            </p>

                            <div className="registration_info_1">
                              <img
                                src={require("../../assets/images/registration_imgs/pencil_square.png")}
                                className="img-fluid"
                              />
                              <p>Customize</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="registration_form mt-4">
                    <p>Registration form design</p>
                    <div className="template_btn">
                      <p>Template 6</p>

                      <Link to="#" className="primary_btn">
                        Edit
                      </Link>
                    </div>
                  </div>

                  <div className="dashbord-right-body mt-4">
                    <div className="dashbordrightbody-header dashboard_future">
                      <p>Webinars</p>
                      <div className="dashborad_button">
                        <Link to="#" className="primary_btn">
                          <img
                            src={require("../../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />
                          Cancel
                        </Link>
                        <Link
                          to="#"
                          className="primary_btn"
                          onClick={SubmitHandler}
                        >
                          <img
                            src={require("../../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />
                          Confirm
                        </Link>
                      </div>
                    </div>
                    <div className="dashbordrightbody-body">
                      <div className="dashbordrightbody-list1 registration_forms ">
                        <h4>Add fields</h4>
                        <div className="add_fields">
                          <div class="input-group  mb-3">
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              placeholder="First Name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={FieldHandler}
                            />
                            <span
                              class="input-group-text"
                              id="basic-addon2"
                              onClick={AddiledHandler}
                            >
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/green.png")}
                                className="img-fluid"
                              />
                            </span>
                          </div>

                          {/* <div class="input-group  mb-3">
                                            <input type="text" class="form-control" placeholder="Last Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <span class="input-group-text" id="basic-addon2"><img
                                                src={require("../../assets/images/registration_imgs/green.png")}
                                                className="img-fluid"
                                            /></span>
                                        </div>

                                        <div class="input-group  mb-3">
                                            <input type="text" class="form-control" placeholder="Email address" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <span class="input-group-text" id="basic-addon2"><img
                                                src={require("../../assets/images/registration_imgs/green.png")}
                                                className="img-fluid"
                                            /></span>
                                        </div>

                                        <div class="input-group  mb-3">
                                            <input type="text" class="form-control" placeholder="Phone Number" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <span class="input-group-text" id="basic-addon2"><img
                                                src={require("../../assets/images/registration_imgs/green.png")}
                                                className="img-fluid"
                                            /></span>
                                        </div> */}
                        </div>
                        <div className="form_fields">
                          <h4>Form fields</h4>
                          <div className="add_fields mt-4">
                            {filedValues.length > 0
                              ? filedValues.map((list, i) => (
                                  <div class="input-group  mb-3">
                                    {console.log(list, "listfiledValues")}
                                    {/* <span class="input-group-text" id="basic-addon2"> <img
                                                        src={require("../../assets/images/registration_imgs/profile.png")}
                                                        className="img-fluid"
                                                    /></span> */}

                                    <input
                                      type="text"
                                      class="form-control"
                                      disabled
                                      placeholder={list.placeholder}
                                      id={list.id}
                                      aria-label="Recipient's username"
                                      aria-describedby="basic-addon2"
                                    />
                                    <span
                                      class="input-group-text"
                                      id="basic-addon2"
                                      onClick={() => removeHandler(i)}
                                    >
                                      {" "}
                                      <img
                                        src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                        className="img-fluid"
                                      />
                                    </span>
                                  </div>
                                ))
                              : ""}

                            {/* <div class="input-group  mb-3">
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/profile.png")}
                                                    className="img-fluid"
                                                /></span>
                                                <input type="text" class="form-control" placeholder="First Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                                    className="img-fluid"
                                                /></span>
                                            </div>
                                            <div class="input-group  mb-3">
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/mail_1.png")}
                                                    className="img-fluid"
                                                /></span>
                                                <input type="text" class="form-control" placeholder="First Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                                    className="img-fluid"
                                                /></span>
                                            </div>
                                            <div class="input-group  mb-3">
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/mobile.png")}
                                                    className="img-fluid"
                                                /></span>
                                                <input type="text" class="form-control" placeholder="First Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span class="input-group-text" id="basic-addon2"> <img
                                                    src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                                    className="img-fluid"
                                                /></span>
                                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-4">
                <Accordion.Header>
                  <p>Registration form fields</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      <img
                        src={require("../../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="dashbordrightbody-body">
                    <div className="dashbordrightbody-list1 registration_forms ">
                      <h4>Add fields</h4>
                      <div className="add_fields">
                        <div class="input-group  mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="First Name"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span class="input-group-text" id="basic-addon2">
                            {" "}
                            <img
                              src={require("../../assets/images/registration_imgs/green.png")}
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        <div class="input-group  mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Last Name"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span class="input-group-text" id="basic-addon2">
                            <img
                              src={require("../../assets/images/registration_imgs/green.png")}
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        <div class="input-group  mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Email address"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span class="input-group-text" id="basic-addon2">
                            <img
                              src={require("../../assets/images/registration_imgs/green.png")}
                              className="img-fluid"
                            />
                          </span>
                        </div>
                        <div class="input-group  mb-3">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Phone Number"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span class="input-group-text" id="basic-addon2">
                            <img
                              src={require("../../assets/images/registration_imgs/green.png")}
                              className="img-fluid"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form_fields">
                        <h4>Form fields</h4>
                        <div className="add_fields mt-4">
                          <div class="input-group  mb-3">
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/profile.png")}
                                className="img-fluid"
                              />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                className="img-fluid"
                              />
                            </span>
                          </div>
                          <div class="input-group  mb-3">
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/profile.png")}
                                className="img-fluid"
                              />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                className="img-fluid"
                              />
                            </span>
                          </div>
                          <div class="input-group  mb-3">
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/mail_1.png")}
                                className="img-fluid"
                              />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                className="img-fluid"
                              />
                            </span>
                          </div>
                          <div class="input-group  mb-3">
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/mobile.png")}
                                className="img-fluid"
                              />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="First Name"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                            />
                            <span class="input-group-text" id="basic-addon2">
                              {" "}
                              <img
                                src={require("../../assets/images/registration_imgs/wrong_1.png")}
                                className="img-fluid"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="mt-4">
                <Accordion.Header>
                  <p>Auto-subscribtion to future webinars</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      <img
                        src={require("../../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={3}>
                      <div
                        className={`registration_checked mt-4  ${
                          activeBody === 0 ? "active" : ""
                        }`}
                        onClick={() => handleBodyClick(0)}
                      >
                        <img
                          src={require("../../assets/images/registration_imgs/checked.png")}
                          height="16"
                          width="16"
                          className="img-fluid"
                        />
                        <div className="checked_field">
                          <h4>Disable auto-subscription</h4>
                          <p>
                            No option to auto-subscribe to future webinars will
                            br displayed
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div
                        className={`registration_checked_1 mt-4  ${
                          activeBody === 1 ? "active" : ""
                        }`}
                        onClick={() => handleBodyClick(1)}
                      >
                        <img
                          src={require("../../assets/images/registration_imgs/circle.png")}
                          height="16"
                          width="16"
                          className="img-fluid"
                        />
                        <div className="checked_field">
                          <h4>Enable auto-subscription</h4>
                          <p>
                            During th e registration process, my users will be
                            able to for the auto-subscription service. This will
                            automatically register them to any new webinar you
                            might conduct in the future.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="registration_rewrite mt-4">
                        <img
                          src={require("../../assets/images/registration_imgs/pencil.png")}
                          className="img-fluid"
                        />
                        <div className="checked_field_1">
                          <p>
                            <a href="#">Name</a>
                          </p>
                          <p>
                            <a href="#">Email</a>
                          </p>

                          <p>
                            <a href="#">Register</a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* 
                    <div className="dashbord-right-body mt-4">
                        <div className="dashbordrightbody-header dashboard_future">
                            <p>Auto-subscribtion to future webinars</p>
                            <div className="dashborad_button">
                                <Link to="#" className="primary_btn" >
                                    <img
                                        src={require("../../assets/images/chevron-right.png")}
                                        height="16"
                                        width="16"
                                        className="img-fluid"
                                    />
                                    Cancel
                                </Link>
                                <Link to="#" className="primary_btn" >
                                    <img
                                        src={require("../../assets/images/chevron-right.png")}
                                        height="16"
                                        width="16"
                                        className="img-fluid"
                                    />
                                    Confirm
                                </Link>
                            </div>
                        </div>

                    </div> */}

            <div className="registration_checked mt-4">
              <img
                src={require("../../assets/images/registration_imgs/checked.png")}
                height="16"
                width="16"
                className="img-fluid"
              />
              <div className="checked_field">
                <h4>Disable auto-subscription</h4>
                <p>
                  No option to auto-subscribe to future webinars will br
                  displayed
                </p>
              </div>
              <Accordion
                className="accMain registration mt-4"
                defaultActiveKey={["0"]}
              ></Accordion>

              {/* </Accordion>
                        <div className="footer_img mt-5">
                            <a href="#"> <img src={nextImg} alt="img" className="img-fluid" /></a>
                            <a href="#"> <img
                                src={require("../../assets/images/registration_imgs/blue_arrow.png")}
                                alt="img"
                                className="img-fluid"

                            />
                            </a>
                        </div> */}
            </div>

            <div className="footer_img mt-5">
              <a href="#">
                {" "}
                <img src={nextImg} alt="img" className="img-fluid" />
              </a>
              <a href="#">
                {" "}
                <img
                  src={require("../../assets/images/registration_imgs/blue_arrow.png")}
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default RegistrationPage;
