import React from "react";
import Modal from "react-bootstrap/Modal";

const ConfirmationModal = ({ showModal, hideModal, confirmModal, config, id, loader }) => {
  return (
    <div className="popup1">
      <Modal
        size="lg"
        show={showModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title">{config?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{config?.message}</Modal.Body>
        <Modal.Footer className="footer-model">
          {loader ?
            <i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              id="circle"
            ></i>
            :
            <>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => confirmModal(id)}
              >

                Yes
              </button>
              <button type="button" className="btn btn-danger" onClick={hideModal}>
                No
              </button></>

          }
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ConfirmationModal;
