import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import moment from "moment-timezone";

//API
import { getEnrollTemplate } from "../../action/user.action";
import { decryptString } from "../../helper/helper";
import config from '../../lib/config'

const DefaultTemplate = (props) => {
    const { id } = useParams();
    const [templateValue, setTemplateValue] = useState(null);
    const [response, setResponse] = useState(null);
    const [meetingStartTime, setMeetingStartTime] = useState(null);
    let JOINLINK = `${config.front_URL}/attendeesRegister/${id}`;

    console.log(JOINLINK,'JOINLINK')

    useEffect(() => {
        if (id != "none") {
            getTemplate();
        }
    }, [id])

    const getTemplate = async () => {
        const dep = await decryptString(id, true);
        const RealData = JSON.parse(dep)
        try {
            let { status, result } = await getEnrollTemplate(RealData);

            console.log("result:=", result)
            if (status) {
                setTemplateValue(result?.result?.[0]?.regTemplate?.[0]?.content);
                setResponse(result?.result?.[0]);
            }
        } catch (err) {
            console.log(err, "err");
        } finally { }
    }

    //IMPORT - TEMPLATE CONTENT
    useEffect(() => {
        if (templateValue) {
            //REMOVE CKEDITOR IN THIS CONTENT
            Array.from(document.querySelectorAll(".ck"))
                .forEach((elem) => {
                    elem.removeAttribute("lang")
                    elem.removeAttribute("dir")
                    elem.removeAttribute("role")
                    elem.removeAttribute("aria-label")
                    elem.removeAttribute("contenteditable")
                });
            //INSERT LINK INTO ENROLL BUTTON
            document.getElementById('joinlink').href = JOINLINK;

        }
    }, [templateValue]);

    useEffect(() => {
        if (response) {
            const timezoneFormatTime = moment.tz(new Date(response?.sessions?.[0]?.startDate), 'MM/DD/YYYY hh:mm A', response?.sessions?.[0]?.timeZone).format();
            setMeetingStartTime(timezoneFormatTime);

            //REMOVE TIMER - FOR INSTANT MEETING
            if (response?.mettingType == 0) {
                let element = document.getElementById("normal-countdown");
                element?.parentNode?.removeChild(element);
            }
        }
    }, [response]);

    useEffect(() => {
        if (meetingStartTime) {
            let elem = document.querySelectorAll(".webinar_timer");
            if (elem?.length == 4) {
                //JUDGE MEETING STARTED
                let countDownDate = new Date(meetingStartTime?.toString()).getTime();
                let now = new Date().getTime();
                let distance = countDownDate - now;
                if (distance > 0) {
                    countdownTimeStart();
                }
            }
        }
    }, [meetingStartTime]);


    function countdownTimeStart() {
        var countDownDate = new Date(meetingStartTime?.toString()).getTime();

        // Update the count down every 1 second
        var x = setInterval(function () {
            // Get todays date and time
            var now = new Date().getTime();

            // Find the distance between now an the count down date
            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            // Time calculations for days, hours, minutes and seconds
            var hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            days = (days < 10 ? "0" + days : days);
            hours = (hours < 10 ? "0" + hours : hours);
            minutes = (minutes < 10 ? "0" + minutes : minutes);
            seconds = (seconds < 10 ? "0" + seconds : seconds);

            setTimeDisplay(days, hours, minutes, seconds);

            // If the count down is over, write some text
            if (distance < 0) {
                clearInterval(x);
            }
        }, 1000);
    }

    const setTimeDisplay = (days, hours, minutes, seconds) => {
        let elem = document.querySelectorAll(".webinar_timer");
        if (elem?.length == 4) {
            elem[0].childNodes[0].innerHTML = days;
            elem[1].childNodes[0].innerHTML = hours;
            elem[2].childNodes[0].innerHTML = minutes;
            elem[3].childNodes[0].innerHTML = seconds;
        }
    }

    return (
        <>
            <section className="temp_1 template1-font">
                {(templateValue) && (
                    <div> {ReactHtmlParser(templateValue)} </div>
                )}
            </section>
        </>
    );
};
export default DefaultTemplate;
