import React, { useState, useEffect } from "react";
import navicon1 from "../assets/images/nav-icon1.svg";
import navicon2 from "../assets/images/nav-icon2.svg";
import navicon3 from "../assets/images/nav-icon3.svg";
import navicon4 from "../assets/images/nav-icon4.svg";
import navicon5 from "../assets/images/nav-icon5.svg";
import { Link } from "react-router-dom";
import { clearCookies } from "../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import io from "socket.io-client";
import config from "../lib/config";
import Cookies from "js-cookie";
import { setUserDetails } from "../redux/features/userdetails";

const socket = io(config.API_URL);

const Navinner = () => {
  let UserDetails = useSelector((state) => state.User);
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    navigate(page);
  };

  const clearUserCookies = () => {
    Cookies.remove("user_token");
    dispatch(setUserDetails(null))
    window.location.reload();
  };

  useEffect(() => {
    if (UserDetails) {
      socket.on("connect", () => {
        console.log("Connected to server");
      });
      socket.emit("CREATEROOM", UserDetails._id);
      socket.emit("message", "hii");
      socket.on("auth", (data) => {
        console.log("socket_______emit__auth");
        clearUserCookies();
      });
      // socket.on("StremingCompleted", (reason) => {
      //   console.log(`StremingCompletedstopStreaming : ${reason}`);
        
      // });
      // return () => {
      //   if (socket) {
      //     socket.disconnect();
      //   }
      // };
    }
  }, [UserDetails,socket]);
  return (
    <>
      <div className="sidebar-left">
        <div className="sidebar-left-header">
          <Link className="navbar-brand" to="#">
            <img
              src={require("../assets/images/logo-light.png")}
              className="img-fluid logo-light"
            />
          </Link>

          <div class="nav-icons">
            <ul>
              <li onClick={() => handlePageChange("/inner-home")}>
                <img src={navicon1} alt="Icon 1" class="img-fluid" />
                <a href="#">Home</a>
              </li>
              <li onClick={() => handlePageChange("/webinar")}>
                <img src={navicon2} alt="Icon 2" class="img-fluid" />
                <a href="#">My webinars</a>
              </li>
              <li onClick={() => handlePageChange("/WebinarAnalytics")}>
                <img src={navicon3} alt="Icon 3" class="img-fluid" />
                <a href="#">My analytics</a>
              </li>
              <li onClick={() => handlePageChange("#")}>
                <img src={navicon4} alt="Icon 4" class="img-fluid" />
                <a href="/inner-active">My registrants</a>
              </li>
            </ul>
          </div>
          
        </div>
        <div className="sidebar-left-footer">
          <ul>
            <li>
              <img
                src={
                  UserDetails?.profile_image === ""
                    ? require("../assets/images/navicon6.png")
                    : UserDetails?.profile_image
                }
                className="img-fluid "
              />
              <a href="/inner-settings">{UserDetails?.userName}</a>
            </li>
            <li>
              <img
                src={require("../assets/images/navicon7.png")}
                className="img-fluid "
              />
              <a href="/inner-support-ticket">Support</a>
            </li>
            <li>
              <img
                src={require("../assets/images/logout.png")}
                className="img-fluid "
              />
              <a href="" onClick={clearCookies}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Navinner;
