import React, { useRef, useEffect } from "react";
import Confetti from "react-canvas-confetti";

const ConfettiComponent = () => {
  // Create a ref to access the Confetti instance
  const confettiRef = useRef(null);

  // Initialize and fire confetti when the component mounts
  useEffect(() => {
    const fireConfetti = () => {
      if (confettiRef.current) {
        confettiRef.current({
          particleCount: 100,
          spread: 160,
          origin: { y: 0.6 },
        });
      }
    };

    // Fire the confetti
    fireConfetti();
  }, []);

  return (
    <div className="confetti-container">
      {/* Render the Confetti component and pass the ref */}
      <Confetti ref={confettiRef} />
    </div>
  );
};

export default ConfettiComponent;
