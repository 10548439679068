export const isEmpty = (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);
  
  export const isBoolean = (value) =>
    (typeof value === "boolean" && (value === true || value === false)) ||
    (typeof value === "string" && (value === "true" || value === "false"));
  
  export function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
  
  export function ValidateNumber(mail) {
    if (/^[0-9]*$/.test(mail)) {
      return true;
    }
    return false;
  }
  
  export function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
  export function ValidatePassword(ps) {
    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(ps)) {
      return true;
    }
    return false;
  }
  
  export function ValidateUserName(val) {
    if (/^[a-zA-Z\s]+$/.test(val)) {
      return true;
    }
    return false;
  }
  
  export const validOnlyString = (e) => {
    if (/[*|\":<>[\]{}`\\()';@&$!#%^0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  
  export const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };
  
  export const validSpecialPositive = (e) => {
    if (/[*|\":<>[\]{}`\\()';@&$!#%^]/.test(e.key)) {
      e.preventDefault();
    }
  };
  
  export const preventCopyPaste = (e) => {
    e.preventDefault();
  };
  
  export const ValidPhoneNumber = (e) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneno.test(e.target.value)) {
      return true;
    }
    return false;
  };
  