import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../../components/inner-footer";
import Template_navbar from "../../components/template-navbar";
import ReactHtmlParser from 'react-html-parser';

//EDITOR
import { CKEditor, useCKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  InlineEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
//API
import { getRegistartionTemplate } from "../../action/user.action";
const Template_4 = () => {
  const { id, tid, meetingType } = useParams();

  //EDITOR
  let plugins = [
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
  ];
  let toolbar = [
    'undo', 'redo', '|',
    'heading', '|', 'bold', 'italic', '|',
    'link', 'insertTable', 'mediaEmbed', '|',
    'bulletedList', 'numberedList', 'indent', 'outdent'
  ];

  //REF
  let editorRef = useRef(null);
  //STATE
  const [editorObj, setEditorObj] = useState(null);
  const [templateValue, setTemplateValue] = useState(null);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);

  useEffect(() => {
    if (tid != "none") {
      getTemplate();
    }
  }, [tid])

  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: tid
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      if (status) {
        setTemplateValue(result?.result?.content);
      }
    } catch (err) {
      console.log(err, "err");
    } finally { }
  }

  //EXTRACT - CKEDITOR DATA
  useEffect(() => {
    if (templateValue) {
      for (let i = 0; i < document.getElementsByClassName('ck').length; i++) {
        console.log(document.getElementsByClassName('ck')[i]?.innerHTML?.toString())
        setTempData(tempData => [...tempData, document.getElementsByClassName('ck')[i]?.innerHTML?.toString()]);
      }
    }
  }, [templateValue]);
  return (
    <>
      <Template_navbar identifier={"template4"} webinarId={id} templateId={tid} />


      {(templateValue) && (
        <div style={{ display: "none" }}> {ReactHtmlParser(templateValue)} </div>
      )}

      {(tid != "none" && tempData?.length !== 0) ? (
        <div className="templateData">
          <section className="temp_4">
            <Container>
              <div className="template_4">
                <Row>
                  <Col lg={6}>
                    <div class="template_4_content">
                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: `${tempData?.[0]?.toString()}`,
                        }}
                      />

                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: `${tempData?.[1]?.toString()}`,
                        }}
                      />

                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: `${tempData?.[2]?.toString()}`,
                        }}
                      />
                    </div>
                    {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                    {(meetingType != 0 && meetingType != 2) && (
                      <div id="normal-countdown" class="webinar_countdown_2" >
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Days</p>
                        </div>
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Hours</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3> 00 </h3>
                          <p>Minutes</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Seconds</p>
                        </div>
                      </div>
                    )}

                    <div class="template_4_btn">
                      <a href="#" id="joinlink">
                        <button class="learn-more">
                          <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                          </span>
                          <span class="button-text">Enroll Now !</span>
                        </button>
                      </a>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="template_4_img">
                      <img class="img-fluid " src={require("../../assets/images/template_img/temp_4.png")} alt="group image" />

                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      ) : ((tid == "none") && (
        <div className="templateData">
          <section className="temp_4">
            <Container>
              <div className="template_4">
                <Row>
                  <Col lg={6}>
                    <div class="template_4_content">
                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: "<span>12/A, Miami city, UK.</span>",
                        }}
                      />
                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: "<h2>Business Conference</h2>",
                        }}
                      />

                      <CKEditor
                        editor={InlineEditor}
                        config={{
                          toolbar: toolbar,
                          plugins: plugins,
                          initialData: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>`,
                        }}
                      />
                    </div>

                    {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                    {(meetingType != 0 && meetingType != 2) && (
                      <div id="normal-countdown" class="webinar_countdown_2" >
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Days</p>
                        </div>
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Hours</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3> 00 </h3>
                          <p>Minutes</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Seconds</p>
                        </div>
                      </div>
                    )}

                    <div class="template_4_btn">
                      <a href="#" id="joinlink">
                        <button class="learn-more">
                          <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                          </span>
                          <span class="button-text">Enroll Now !</span>
                        </button>
                      </a>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="template_4_img">
                      <img class="img-fluid " src={require("../../assets/images/template_img/temp_4.png")} alt="group image" />

                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        </div>
      ))}


      <Innerfooter />
    </>
  );
};
export default Template_4;
