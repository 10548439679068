import Innerpagenavbar from "../../components/innerpage-navbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Accordion, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Basic_validation,
  persenter_validation,
  schedule_Valid,
  session_recurringValid,
  session_singleValid,
} from "../../lib/validation";
import { decryptString, encryptString, isEmpty } from "../../helper/helper";
import {
  Create_Webinar,
  add_webinar_schedule,
  getSingleWebinarDetails,
  getWebinarDetails,
  update_Webinar,
} from "../../action/user.action";
import { setWebinar } from "../../redux/features/webinar_details";
import { toastAlert } from "../../utils/toast";
import ReactLanguageSelect from "react-languages-select";
import config from "../../lib/config";
import { useEffect, useState } from "react";
import moment from "moment";
import Datetime from "react-datetime";

import "react-datetime/css/react-datetime.css";
import Innerfooter from "../../components/inner-footer";
import timezones from 'timezones-list';

function WebinarSchedule() {

  const { id } = useParams()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const webinarDetails = useSelector((state) => state.Webinar);

  const [webinarDetails, setwebinarDetails] = useState({})
  const [initialDate, setInitialDate] = useState();
  console.log(webinarDetails, "webinarDetails");


  const getWebinatDetails = async () => {

    try {

      if (id) {
        // const decodeid = await decryptString(id, true)
        // console.log(decodeid, 'data')

        // if (!decodeid) {
          const { status, result } = await getSingleWebinarDetails({ id: id })
          console.log(status, result, 'status, result')
          if (status) {
            setwebinarDetails(result.result)
          }
        // }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWebinatDetails()
  }, [id])



  const [selectedSession, setSelectedSession] = useState(null);
  const [AddedSession, setAddedSession] = useState([]);
  const [error, setError] = useState();
  const [schedule, setSchedule] = useState({});
  const [show_schedule_edit, set_show_schedule_edit] = useState(false);
  const [sesseionDate, setSessionDate] = useState();

  const [newSession, setNewSession] = useState({});

  const handleSessionSelect = (session) => {
    setSelectedSession(session);
    // setInitialState({ ...initialState, 'schedule_type': session })
    setAddedSession([]);
    setSchedule({ schedule_type: session });
  };

  const scheduleHandler = async (e) => {
    const { id, value } = e.target;

    console.log(id, value, "id, value ");
    if (id == "all_timezone") {
      setSchedule({ ...schedule, [id]: !schedule.all_timezone });
    } else {
      setSchedule({ ...schedule, [id]: value });
    }
  };

  const sessionHandler = async (e) => {
    console.log(new Date(), "sessionHandler");
    console.log(new Date(e._d), "sessionHandler");
    const data = moment(e._d).format("LLL");
    console.log(data, "sessionHandler");
    setSessionDate(new Date(e._d));

  };

  const session_timeHandler = async (e) => {
    console.log(e, "sessionHandler");
    setSessionDate(new Date(e._d));
  };

  useEffect(() => { }, [sesseionDate, AddedSession, initialDate]);

  const addSession = async () => {
    console.log(sesseionDate, newSession, AddedSession, "setAddedSession");
    setInitialDate()
    let dat;
    if (selectedSession != "Recurring") {
      dat = await session_singleValid({
        session_date: sesseionDate,
        ...newSession,
      });
    } else {
      dat = await session_recurringValid({
        session_date: sesseionDate,
        ...newSession,
      });
    }
    console.log(dat, "addSession");

    if (!AddedSession.find((val) => val.session_date == sesseionDate)) {
      if (isEmpty(dat)) {
        setError({});
        setAddedSession([
          ...AddedSession,
          { session_date: sesseionDate, ...newSession },
        ]);
      } else {
        setError(dat);
      }
    } else {
      toastAlert('error', 'Session date already exists ')
      return false
    }



  };

  const submitFormTwo = async (e) => {
    try {
      e.preventDefault();
      // console.log(schedule, AddedSession, 'submitFormTwo')

      if (show_schedule_edit) {
        if (selectedSession == webinarDetails.Webinar_schedule.type) {
          schedule.schedule_type = selectedSession;
          schedule.id = webinarDetails._id;
          schedule.session = [
            ...AddedSession,
            ...webinarDetails.Webinar_schedule?.sessions,
          ];
        } else {
          schedule.schedule_type = selectedSession;
          schedule.id = webinarDetails._id;
          schedule.session = [...AddedSession];
        }
        console.log(schedule, "schedule");
        const { status, result } = await add_webinar_schedule(schedule);
        console.log(status, result);
        dispatch(setWebinar(result.result));
        toastAlert("success", "Webinar updated");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        // const det = await schedule_Valid(schedule);
        if (AddedSession.length != 0) {
          setError({});
          schedule.id = webinarDetails._id;
          schedule.session = AddedSession;
          const { status, result } = await add_webinar_schedule(schedule);
          console.log(status, result);
          // dispatch(setWebinar(result.result));
          toastAlert("success", "Webinar updated");
          setTimeout(() => {
            navigate(`/Notification/${id}`)
          }, 1500);
        } else {
          if (AddedSession.length == 0) {
            toastAlert("error", "Sessions is required");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextHandler = async () => {
    const id = await encryptString(webinarDetails._id, true);
    navigate(`/RegistrationPage/${id}`);
  };

  const EditSession = (type, val, i) => {
    console.log(i, "val, ival, i", type, val);
    setInitialDate()
    console.log(AddedSession, 'AddedSession')

    // if (type) {
    //   webinarDetails?.Webinar_schedule?.sessions.splice(i, 1);
    //   setInitialDate(new Date(val.session_date));
    // } else {

    const newSessions = [...AddedSession];

    setInitialDate(new Date(newSessions[i].session_date));
    newSessions.splice(i, 1);

    console.log(newSessions, 'AddedSession')

    setAddedSession(newSessions);
    // }
  };

  console.log(initialDate, "hh:mm:ss");

  const navigateHandler = async () => {
    const id = await encryptString(webinarDetails._id, true);
    navigate(`/BasicSettings/${id}`);
  };


  console.log(timezones, 'timezones')
  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5">
            <div className="registration_nav_sec">
              <ul className="registration_list">
                <li>
                  <div
                    className="registration_nav_content"
                    onClick={navigateHandler}
                  >
                    <img
                      src={require("../../assets/images/registration_imgs/notepad.png")}
                      className="img-fluid"
                    />
                    <p>Configuration</p>
                    {id ? (
                      <img
                        src={require("../../assets/images/registration_imgs/checked.png")}
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/calendar-tick.png")}
                      className="img-fluid"
                    />

                    <p>Schedules</p>
                    {webinarDetails?.Webinar_schedule?.type ? (
                      <img
                        src={require("../../assets/images/registration_imgs/checked.png")}
                        className="img-fluid"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                {/* <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/driver.png")}
                      className="img-fluid"
                    />
                    <p>Registration</p>
                  </div>
                </li> */}
                <li>
                  <div className="registration_nav_content">
                    <img
                      src={require("../../assets/images/registration_imgs/notification_2.png")}
                      className="img-fluid"
                    />
                    <p>Notification</p>
                  </div>
                </li>
              </ul>
            </div>
            <Accordion className="accMain" defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p>Webinar schedule</p>
                  <div className="accordion-hearder-content">
                    {show_schedule_edit == false &&
                      webinarDetails?.Webinar_schedule?.type ? (
                      <>
                        <Link
                          className="accordionedit-btn"
                          onClick={() => set_show_schedule_edit(true)}
                        >
                          Edit
                        </Link>
                        <img
                          src={require("../../assets/images/accordion-config.png")}
                          className="img-fluid"
                        />{" "}
                        <p>Configured</p>
                        <Link className="primary_btn" onClick={nextHandler}>
                          <img
                            src={require("../../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                          />{" "}
                          Next
                        </Link>
                      </>
                    ) : (
                      <>
                        <div>
                          <Link
                            to="#"
                            className="primary_btn primary_btn2 me-4"
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.5 1L6.5 6L1.5 11"
                                stroke="#001F2A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Cancel
                          </Link>
                          <Link
                            to="#"
                            className="primary_btn"
                            onClick={submitFormTwo}
                          >
                            <img
                              src={require("../../assets/images/chevron-right.png")}
                              height="16"
                              width="16"
                              className="img-fluid"
                            />{" "}
                            Confirm
                          </Link>
                        </div>
                      </>
                    )}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="time-mangement">
                    <div
                      className={`time-schedule ${selectedSession === "single" ? "box-shadow" : ""
                        }`}
                    >
                      <div class="form-check check-head">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="single"
                          checked={selectedSession === "single"}
                          onChange={() => handleSessionSelect("single")}
                        />
                        {selectedSession === "single"
                          ? error &&
                          error.schedule_type && (
                            <span style={{ color: "red" }}>
                              {error.schedule_type}
                            </span>
                          )
                          : ""}
                        <label class="form-check-label" for="flexRadioDefault2">
                          One single session
                        </label>
                      </div>
                      <p>
                        The registration box will display one (or many)
                        scheduled events, and your users will register to only
                        one of them at a time
                      </p>
                     
                    </div>
                    <div
                      className={`time-schedule ${selectedSession === "multiple" ? "box-shadow" : ""
                        }`}
                    >
                      <div class="form-check check-head">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="second"
                          checked={selectedSession === "multiple"}
                          onChange={() => handleSessionSelect("multiple")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Multiple Session
                        </label>
                      </div>
                      <p>
                        The registration box will display one (or many)
                        scheduled events, and your users will register to only
                        one of them at a time
                      </p>
                     
                    </div>
                    <div
                      className={`time-schedule ${selectedSession === "Recurring" ? "box-shadow" : ""
                        }`}
                    >
                      <div class="form-check check-head">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="Recurring"
                          checked={selectedSession === "Recurring"}
                          onChange={() => handleSessionSelect("Recurring")}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          Recurring Series Of Session
                        </label>
                      </div>
                      <p>
                        The registration box will display one (or many)
                        scheduled events, and your users will register to only
                        one of them at a time
                      </p>
                     
                    </div>
                  </div>

                  {selectedSession == "Recurring" ? (
                    <div className="newSission-bg">
                      <div className="newSission">
                        <p>New Series</p>
                        <Link className="onbtn">EveryDay</Link>
                        <InputGroup className="">
                          <InputGroup.Text id="basic-addon1">
                            {" "}
                            <img
                              src={require("../../assets/images/calender.png")}
                              className="img-fluid"
                            />{" "}
                          </InputGroup.Text>
                          {initialDate == undefined && (
                            <Datetime
                              initialValue={new Date()}
                              timeFormat={false}
                              onChange={sessionHandler}
                            // dateFormat="DD-MM-YY"
                            />
                          )}

                          {initialDate != undefined && (
                            <Datetime
                              initialValue={initialDate}
                              timeFormat={false}
                              onChange={sessionHandler}
                            // dateFormat="DD-MM-YY"
                            />
                          )}
                          {/* <Datetime
                                                        initialValue={new Date()}
                                                        timeFormat={false}
                                                        onChange={sessionHandler}
                                                    // dateFormat="DD-MM-YY"
                                                    /> */}
                        </InputGroup>
                        {error && error.session_date && (
                          <span style={{ color: "red" }}>
                            {error.session_date}
                          </span>
                        )}
                        <InputGroup className="">
                          <InputGroup.Text id="basic-addon1">
                            <img
                              src={require("../../assets/images/time.png")}
                              className="img-fluid"
                            />{" "}
                          </InputGroup.Text>
                          <Datetime
                            value={sesseionDate ? sesseionDate : ""}
                            // initialValue={sesseionDate}
                            dateFormat={false}
                            timeFormat={"hh:mm:ss"}
                            onChange={session_timeHandler}
                          />
                        </InputGroup>
                        <Link className="onbtn">Limit</Link>
                        <InputGroup className="">
                          <Form.Control
                            placeholder="Limit"
                            id="limit"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={(e) =>
                              setNewSession({
                                ...newSession,
                                limit: e.target.value,
                              })
                            }
                          />
                        </InputGroup>
                        {error && error.limit && (
                          <span style={{ color: "red" }}>{error.limit}</span>
                        )}

                        <Form.Select
                          aria-label="Default select example"
                          onClick={(e) =>
                            setNewSession({
                              ...newSession,
                              timezone: e.target.value,
                            })
                          }
                        >
                          <option>Select time zone</option>
                          <option value="IST">IST</option>
                          <option value="UST">UST</option>
                        </Form.Select>
                        {error && error.timezone && (
                          <span style={{ color: "red" }}>{error.timezone}</span>
                        )}
                        <Link
                          className="newsission-addbtn"
                          onClick={addSession}
                        >
                          Add
                        </Link>
                      </div>
                    </div>
                  ) : (

                    <>
                      {
                        selectedSession === "single" ?

                          <div className="newSission-bg">
                            <div className="newSission">
                              <p>New Session</p>
                              <Link className="onbtn">On</Link>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  {" "}
                                  <img
                                    src={require("../../assets/images/calender.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>

                                {initialDate == undefined && (
                                  <Datetime
                                    initialValue={new Date()}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                  // dateFormat="DD-MM-YY"
                                  />
                                )}

                                {initialDate != undefined && (
                                  <Datetime
                                    initialValue={initialDate}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                  // dateFormat="DD-MM-YY"
                                  />
                                )}
                              </InputGroup>
                              {error && error.session_date && (
                                <span style={{ color: "red" }}>
                                  {error.session_date}
                                </span>
                              )}
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../../assets/images/time.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Datetime
                                  value={sesseionDate ? sesseionDate : ""}
                                  // initialValue={sesseionDate}
                                  dateFormat={false}
                                  timeFormat={"hh:mm:ss"}
                                  onChange={session_timeHandler}
                                />
                                {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                              </InputGroup>
                              <Form.Select
                                aria-label="Default select example"
                                onClick={(e) =>
                                  setNewSession({ timezone: e.target.value })
                                }
                              >
                                <option>Select time zone</option>
                                <option value="IST">IST</option>
                                <option value="UST">UST</option>
                              </Form.Select>
                              {error && error.timezone && (
                                <span style={{ color: "red" }}>{error.timezone}</span>
                              )}
                              {
                                AddedSession.length > 0 ?
                                  <Link
                                    className="newsission-addbtn"
                                  >
                                    Submit
                                  </Link>
                                  :
                                  <Link
                                    className="newsission-addbtn"
                                    onClick={addSession}
                                  >
                                    Submit
                                  </Link>
                              }

                            </div>
                          </div>
                          :
                          selectedSession != null && <div className="newSission-bg">
                            <div className="newSission">
                              <p>New Session</p>
                              <Link className="onbtn">On</Link>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  {" "}
                                  <img
                                    src={require("../../assets/images/calender.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>

                                {initialDate == undefined && (
                                  <Datetime
                                    initialValue={new Date()}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                  // dateFormat="DD-MM-YY"
                                  />
                                )}

                                {initialDate != undefined && (
                                  <Datetime
                                    initialValue={initialDate}
                                    timeFormat={false}
                                    onChange={sessionHandler}
                                  // dateFormat="DD-MM-YY"
                                  />
                                )}
                              </InputGroup>
                              {error && error.session_date && (
                                <span style={{ color: "red" }}>
                                  {error.session_date}
                                </span>
                              )}
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../../assets/images/time.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Datetime
                                  value={sesseionDate ? sesseionDate : ""}
                                  // initialValue={sesseionDate}
                                  dateFormat={false}
                                  timeFormat={"hh:mm:ss"}
                                  onChange={session_timeHandler}
                                />
                                {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                              </InputGroup>
                              <Form.Select
                                aria-label="Default select example"
                                onClick={(e) =>
                                  setNewSession({ timezone: e.target.value })
                                }
                              >
                                <option>Select time zone</option>
                                <option value="IST">IST</option>
                                <option value="UST">UST</option>
                              </Form.Select>
                              {error && error.timezone && (
                                <span style={{ color: "red" }}>{error.timezone}</span>
                              )}

                              <Link
                                className="newsission-addbtn"
                                onClick={addSession}
                              >
                                Add
                              </Link>
                            </div>
                          </div>

                      }
                    </>
                  )
                  }

                  {webinarDetails?.Webinar_schedule?.sessions.length > 0 &&
                    selectedSession == webinarDetails.Webinar_schedule?.type &&
                    webinarDetails?.Webinar_schedule?.sessions.map(
                      (list, i) => (
                        <>
                          {selectedSession == "Recurring" ? (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Series</p> */}
                                <Link className="onbtn">EveryDay</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  {/* <Datetime
                                                                    value={new Date(list.session_date)}
                                                                    timeFormat={false}
                                                                    onChange={sessionHandler}
                                                                    dateFormat="DD-MM-YY"
                                                                    open={false}
                                                                /> */}

                                  <Form.Control
                                    placeholder="Select time"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={moment(list.session_date).format(
                                      "LL"
                                    )}
                                  />
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  {/* <Datetime
                                                                    value={new Date(list.session_date)}
                                                                    // initialValue={sesseionDate}
                                                                    dateFormat={false}
                                                                    timeFormat={"hh:mm:ss"}
                                                                    onChange={session_timeHandler}
                                                                    open={false}

                                                                /> */}
                                  <Form.Control
                                    placeholder="Select time"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={moment(list.session_date).format(
                                      "hh:mm:ss"
                                    )}
                                  />
                                </InputGroup>
                                <Link className="onbtn">Limit</Link>
                                <InputGroup className="">
                                  <Form.Control
                                    placeholder="Limit"
                                    id="limit"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={list.limit}
                                  // onClick={(e) => setNewSession({ ...newSession, "limit": e.target.value })}
                                  />
                                </InputGroup>

                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option value="IST">IST</option>
                                <option value="UST">UST</option> */}
                                </Form.Select>
                                <Link
                                  className="newsission-addbtn"
                                  onClick={() => EditSession(true, list, i)}
                                >
                                  Edit
                                </Link>
                              </div>
                            </div>
                          ) : (
                            <div className="newSission-bg">
                              <div className="newSission">
                                {/* <p>New Session</p> */}
                                <Link className="onbtn">On</Link>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    {" "}
                                    <img
                                      src={require("../../assets/images/calender.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>

                                  <Form.Control
                                    placeholder="Select time"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={moment(list.session_date).format(
                                      "LL"
                                    )}
                                  />

                                  {/* <Datetime
                                                                    initialValue={list.session_date}
                                                                    timeFormat={false}

                                                                    // dateFormat="DD-MM-YY"
                                                                    open={false}
                                                                /> */}
                                  {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          /> */}
                                </InputGroup>
                                <InputGroup className="">
                                  <InputGroup.Text id="basic-addon1">
                                    <img
                                      src={require("../../assets/images/time.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </InputGroup.Text>
                                  <Form.Control
                                    placeholder="Select time"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={moment(list.session_date).format(
                                      "hh:mm:ss"
                                    )}
                                  />
                                  {/* <Datetime
                                                                    value={list.session_date}
                                                                    // initialValue={sesseionDate}
                                                                    dateFormat={false}
                                                                    timeFormat={"hh:mm:ss"}
                                                                    onChange={session_timeHandler}
                                                                    open={false}
                                                                /> */}
                                  {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                                </InputGroup>
                                <Form.Select aria-label="Default select example">
                                  <option>{list.timezone}</option>
                                  {/* <option >IST</option> */}
                                  {/* <option value="IST">UST</option> */}
                                </Form.Select>
                                <Link
                                  className="newsission-addbtn"
                                  onClick={() => EditSession(true, list, i)}
                                >
                                  Edit
                                </Link>
                              </div>
                            </div>
                          )}
                        </>
                      )
                    )}

                  {AddedSession.length > 0 &&
                    AddedSession.map((list, i) => (
                      <>
                        {selectedSession == "Recurring" ? (
                          <div className="newSission-bg">
                            <div className="newSission">
                              {/* <p>New Series</p> */}
                              <Link className="onbtn">EveryDay</Link>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  {" "}
                                  <img
                                    src={require("../../assets/images/calender.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Select time"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={moment(list.session_date).format("LL")}
                                />
                              </InputGroup>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../../assets/images/time.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                {/* <Datetime
                                                                    value={list.session_date}
                                                                    // initialValue={sesseionDate}
                                                                    dateFormat={false}
                                                                    timeFormat={"hh:mm:ss"}
                                                                    // onChange={session_timeHandler}
                                                                    open={false}
                                                                /> */}
                                <Form.Control
                                  placeholder="Select time"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={moment(list.session_date).format(
                                    "hh:mm:ss"
                                  )}
                                />
                              </InputGroup>
                              <Link className="onbtn">Limit</Link>
                              <InputGroup className="">
                                <Form.Control
                                  placeholder="Limit"
                                  id="limit"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={list.limit}
                                // onClick={(e) => setNewSession({ ...newSession, "limit": e.target.value })}
                                />
                              </InputGroup>

                              <Form.Select aria-label="Default select example">
                                <option>{list.timezone}</option>
                                {/* <option value="IST">IST</option>
                                <option value="UST">UST</option> */}
                              </Form.Select>
                              <Link
                                className="newsission-addbtn"
                                onClick={() => EditSession(true, list, i)}
                              >
                                Edit
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="newSission-bg">
                            <div className="newSission">
                              {/* <p>New Session</p> */}
                              <Link className="onbtn">On</Link>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  {" "}
                                  <img
                                    src={require("../../assets/images/calender.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>

                                <Form.Control
                                  placeholder="Select time"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={moment(list.session_date).format("LL")}
                                />
                              </InputGroup>
                              <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../../assets/images/time.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                {/* <Datetime
                                                                    value={list.session_date}
                                                                    // initialValue={sesseionDate}
                                                                    dateFormat={false}
                                                                    timeFormat={"hh:mm:ss"}
                                                                    open={false}
                                                                // onChange={session_timeHandler}
                                                                /> */}

                                <Form.Control
                                  placeholder="Select time"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={moment(list.session_date).format(
                                    "hh:mm:ss"
                                  )}
                                />
                                {/* <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          /> */}
                              </InputGroup>
                              <Form.Select aria-label="Default select example">
                                <option>{list.timezone}</option>
                                {/* <option >IST</option> */}
                                {/* <option value="IST">UST</option> */}
                              </Form.Select>
                              <Link
                                className="newsission-addbtn"
                                onClick={() => EditSession(false, list, i)}
                              >
                                Edit
                              </Link>
                            </div>
                          </div>
                        )}
                      </>
                    ))}


                  <div className="timezoneconversion-head">
                    <div className="timezoneconversion">
                      <h6>Allow time zone conversion</h6>
                      <p>
                        The Registration form will allow your visitors to
                        convert your configured time zone to theirs, so it's
                        more convenient for them. However, you may turn this
                        option OFF and therefore all times will always be
                        displayed in your configured time zone
                      </p>
                    </div>
                    <div>
                      <Form.Check
                        type="switch"
                        id="all_timezone"
                        defaultChecked={
                          webinarDetails?.Webinar_schedule?.all_timezone
                        }
                        onClick={scheduleHandler}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
}

export default WebinarSchedule;
