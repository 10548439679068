
import { Navigate, Outlet } from "react-router-dom";
// import Login from "./Login";
import Cookies from "js-cookie";

const useAuth = () => {

    const userToken = Cookies.get("user_token");
    if (userToken) {
        return true
    } else {
        return false
    }

}

const ProtectedRouter = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/login" />
};

export default ProtectedRouter