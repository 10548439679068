import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";

// import Nav from 'react-bootstrap/Nav';

import InputGroup from "react-bootstrap/InputGroup";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Navinner from "../components/nav-inner";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
const ConfigrationPage = () => {
  const [showTab1, setShowTab1] = useState(true);
  const [showTab2, setShowTab2] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");

  const toggleTab1 = () => {
    setShowTab1(true);
    setShowTab2(false);
    setActiveTab("tab1");
  };

  const toggleTab2 = () => {
    setShowTab1(false);
    setShowTab2(true);
    setActiveTab("tab2");
  };

  const [showPasswordprotectionTab1, setShowPasswordprotectionTab1] =
    useState(true);
  const [showPasswordprotectionTab2, setShowPasswordprotectionTab2] =
    useState(false);
  const [activeTab1, setActiveTab1] = useState("PasswordprotectionTab1");

  const togglePasswordprotectionTab1 = () => {
    setShowPasswordprotectionTab1(true);
    setShowPasswordprotectionTab2(false);
    setActiveTab1("PasswordprotectionTab1");
  };

  const togglePasswordprotectionTab2 = () => {
    setShowPasswordprotectionTab1(false);
    setShowPasswordprotectionTab2(true);
    setActiveTab1("PasswordprotectionTab2");
  };
  const [showMasterPassword1, setShowMasterPassword1] = useState(false);
  const [showMasterPassword2, setShowMasterPassword2] = useState(false);
  const toggleMasterPassword1 = () => {
    setShowMasterPassword1(true);
    setShowMasterPassword2(false);
  };
  const toggleMasterPassword2 = () => {
    setShowMasterPassword1(false);
  };

  const [showContent1, setShowContent1] = useState(false);
  const [showContent2, setShowContent2] = useState(false);

  const toggleContent1 = () => {
    setShowContent1(true);
    setShowContent2(false);
  };

  const toggleContent2 = () => {
    setShowContent1(false);
    setShowContent2(true);
  };
  const [selectedSession, setSelectedSession] = useState(null);

  const handleSessionSelect = (session) => {
    setSelectedSession(session);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState(false);

  const handleEmailClose = () => setEmail(false);
  const handleEmailShow = () => setEmail(true);
  const [activeBody, setActiveBody] = useState(null);

  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };

  const [ShowAutoplay1, setShowAutoplay1] = useState(true);
  const [ShowAutoplay2, setShowAutoplay2] = useState(false);
  const [ActiveAutoplayTab, setActiveAutoplayTab] = useState("tab1");

  const toggleAutoplayTab1 = () => {
    setShowAutoplay1(true);
    setShowAutoplay2(false);
    setActiveAutoplayTab("tab1");
  };

  const toggleAutoplayTab2 = () => {
    setShowAutoplay1(false);
    setShowAutoplay2(true);
    setActiveAutoplayTab("tab2");
  };
  const [Polls, setShowPolls] = useState(false);

  const handlePollsClose = () => setShowPolls(false);
  const handleShowPoll = () => setShowPolls(true);
  const [startDate, setStartDate] = useState(new Date());

  const [Productoffer, setShowProductoffer] = useState(false);

  const handleProductofferClose = () => setShowProductoffer(false);
  const handleProductofferShow = () => setShowProductoffer(true);

  const [VideoInjection, setShowVideoInjection] = useState(false);

  const handleVideoInjectionClose = () => setShowVideoInjection(false);
  const handleVideoInjectionShow = () => setShowVideoInjection(true);
  const [Upload_file, setShowUpload_file] = useState(false);

  const handleUpload_fileClose = () => setShowUpload_file(false);
  const handleUpload_fileShow = () => setShowUpload_file(true);
  const [Upload_slide, setShowUpload_slide] = useState(false);

  const handleUpload_slideClose = () => setShowUpload_slide(false);
  const handleUpload_slideShow = () => setShowUpload_slide(true);

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-2">
            <div className="dashbord-right-body-1">
              <div className="registration_nav_sec">
                <Scrollbars
                  className="custom-scrollbar"
                  style={{ width: "100%", height: 100 }}
                  renderTrackVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      className="scrollbar-track vertical"
                      style={{ ...style }}
                    />
                  )}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      className="scrollbar-thumb vertical"
                      style={{ ...style }}
                    />
                  )}
                >
                  <ul className="registration_list">
                    <li>
                      <div className="registration_nav_content active">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/registration_imgs/notepad.png")}
                            className="img-fluid top-head"
                          />
                          <img
                            src={require("../assets/images/registration_imgs/checked.png")}
                            className="img-fluid top-checked"
                          />
                        </div>
                        <p>Configuration</p>
                        <img
                          src={require("../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked1"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content active">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/registration_imgs/calendar-tick.png")}
                            className="img-fluid top-head"
                          />
                          <img
                            src={require("../assets/images/registration_imgs/checked.png")}
                            className="img-fluid top-checked"
                          />
                        </div>
                        <p>Schedules</p>
                        <img
                          src={require("../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked1"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content ">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/registration_imgs/driver.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Registration</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/registration_imgs/notification_2.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Notification</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/like_img.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Thank You</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/video.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Live</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/video.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Replay</p>
                      </div>
                    </li>
                    <li>
                      <div className="registration_nav_content">
                        <div className="top-head-img">
                          <img
                            src={require("../assets/images/video.png")}
                            className="img-fluid top-head"
                          />
                        </div>
                        <p>Finish</p>
                        {/* <img
                        src={require("../assets/images/registration_imgs/checked.png")}
                        className="img-fluid"
                      /> */}
                      </div>
                    </li>
                  </ul>
                </Scrollbars>
              </div>
              <Accordion
                className="accMain registration mt-4"
                defaultActiveKey={["0"]}
              >
                <Accordion.Item eventKey="0" className="accItem">
                  <Accordion.Header>
                    <p>Basic Settings</p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      </div>
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <div className="dashbordrightbody-body">
                      <div className="configration_sec webinar-setting-content">
                        <div className="config_form">
                          <Row>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Webinar language</Form.Label>
                                <Form.Select aria-label="Default select example">
                                  <option>English</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </Form.Select>
                                <p>
                                  The interface will be translated for your
                                  users
                                </p>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Webinar name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: Webinar for my free leads"
                                />
                                <p>
                                  Private title. for your reference only. Max 70
                                  characters.
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Webinar title</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: How to lose weight in 6 weeks"
                                />
                                <p>Public title. Max 70 characters.</p>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Webinar description</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: All the tips and tricks to efficient diets and workouts"
                                />
                                <p>Public description. Max 150 characters.</p>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>

                        <div className="tab_content ">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <div className="configuration_tab">
                              <Nav
                                variant="pills"
                                className=" configuration_flex "
                              >
                                <Nav.Item>
                                  <Nav.Link eventKey="first">
                                    <div className="configuration_tab">
                                      <div className="configuration_tab_content">
                                        <img
                                          src={require("../assets/images/registration_imgs/checked.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <div>
                                          <h6>
                                            Don't list your webinar, keep it
                                            private
                                          </h6>
                                          <p>
                                            I do not want to list my webinar in
                                            the webinars On-Demand directory. I
                                            understand that i give up on the
                                            extra SEO benefits and I might get
                                            fewer organic registrants.
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">
                                    <div className="configuration_tab_1">
                                      <div className="configuration_tab_content">
                                        <img
                                          src={require("../assets/images/registration_imgs/checked.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <div>
                                          <h6>
                                            Yes, list it in the On-Demand
                                            section
                                          </h6>
                                          <p>
                                            The webinars On-Demand portal is a
                                            public directory where all webinar
                                            jam events get uploaded for others
                                            users (and anonymous visitors0 to
                                            browse for content and register to
                                            webinars in their areas of interest.
                                            It is a great way to bring exposure
                                            and registrants to your events. The
                                            best part? It is a free service we
                                            offer to our webinar jam customers,
                                            so it is highly recommended to go
                                            for it!
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>

                              <Tab.Content className="tab_content_1">
                                <Tab.Pane eventKey="first">
                                  <div className="tab_home_content mt-4">
                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img_1.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />

                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img_1.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />

                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img_2.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  {" "}
                                  <div className="tab_home_content mt-4">
                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />

                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />

                                    <img
                                      src={require("../assets/images/registration_imgs/tab_img_2.png")}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </div>
                      </div>
                    </div> */}

                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p>Basic settings</p>

                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="dashbordrightbody-body">
                            <div className="configration_sec webinar-setting-content">
                              <div className="config_form">
                                <Row>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Webinar language</Form.Label>
                                      <Form.Select aria-label="Default select example">
                                        <option>English</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </Form.Select>
                                      <p>
                                        The interface will be translated for
                                        your users
                                      </p>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Webinar name</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Example: Webinar for my free leads"
                                      />
                                      <p>
                                        Private title. for your reference only.
                                        Max 70 characters.
                                      </p>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>Webinar title</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Example: How to lose weight in 6 weeks"
                                      />
                                      <p>Public title. Max 70 characters.</p>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlInput1"
                                    >
                                      <Form.Label>
                                        Webinar description
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Example: All the tips and tricks to efficient diets and workouts"
                                      />
                                      <p>
                                        Public description. Max 150 characters.
                                      </p>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>

                              <div className="tab_content ">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="first"
                                >
                                  <div className="configuration_tab">
                                    <Nav
                                      variant="pills"
                                      className=" configuration_flex "
                                    >
                                      <Nav.Item>
                                        <Nav.Link eventKey="first">
                                          <div className="configuration_tab">
                                            <div className="configuration_tab_content">
                                              <img
                                                src={require("../assets/images/registration_imgs/checked.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <div>
                                                <h6>
                                                  Don't list your webinar, keep
                                                  it private
                                                </h6>
                                                <p>
                                                  I do not want to list my
                                                  webinar in the webinars
                                                  On-Demand directory. I
                                                  understand that i give up on
                                                  the extra SEO benefits and I
                                                  might get fewer organic
                                                  registrants.
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                        <Nav.Link eventKey="second">
                                          <div className="configuration_tab_1">
                                            <div className="configuration_tab_content">
                                              <img
                                                src={require("../assets/images/registration_imgs/checked.png")}
                                                alt="img"
                                                className="img-fluid"
                                              />
                                              <div>
                                                <h6>
                                                  Yes, list it in the On-Demand
                                                  section
                                                </h6>
                                                <p>
                                                  The webinars On-Demand portal
                                                  is a public directory where
                                                  all webinar jam events get
                                                  uploaded for others users (and
                                                  anonymous visitors0 to browse
                                                  for content and register to
                                                  webinars in their areas of
                                                  interest. It is a great way to
                                                  bring exposure and registrants
                                                  to your events. The best part?
                                                  It is a free service we offer
                                                  to our webinar jam customers,
                                                  so it is highly recommended to
                                                  go for it!
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </Nav.Link>
                                      </Nav.Item>
                                    </Nav>

                                    <Tab.Content className="tab_content_1">
                                      <Tab.Pane eventKey="first">
                                        <div className="tab_home_content mt-4">
                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img_1.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />

                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img_1.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />

                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img_2.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="second">
                                        {" "}
                                        <div className="tab_home_content mt-4">
                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />

                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />

                                          <img
                                            src={require("../assets/images/registration_imgs/tab_img_2.png")}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </div>
                                      </Tab.Pane>
                                    </Tab.Content>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p>Webinar presenters</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="webinar-setting-sub-head configuration_webinar">
                            <div className=" webinar-width">
                              <div>
                                <p className="webinar-setting-content1-head">
                                  Presenters
                                </p>
                                <div className="white-box1 webinar-setting-sub">
                                  <div className="profile-present">
                                    {" "}
                                    <img
                                      src={require("../assets/images/profile-img.png")}
                                      className="img-fluid"
                                    />{" "}
                                    <div className="profile-detail">
                                      <div>
                                        <div className="name-content">
                                          <p>Katy Perry</p>
                                          <div className="verify">
                                            {" "}
                                            <img
                                              src={require("../assets/images/verify-img.png")}
                                              className="img-fluid"
                                            />{" "}
                                            <p>Verified</p>
                                          </div>
                                        </div>
                                        <p className="email-profile">
                                          kevin@peterson.com
                                        </p>
                                      </div>{" "}
                                      <div className="profile-change">
                                        <img
                                          src={require("../assets/images/setting-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <img
                                          src={require("../assets/images/voice-img.png")}
                                          className="img-fluid"
                                        />{" "}
                                        <Link className="profile-edit">
                                          Edit
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" webinar-width1">
                              <p className="webinar-setting-content1-head">
                                New Presenters
                              </p>
                              <div className="white-box1 webinar-setting-sub">
                                <img
                                  src={require("../assets/images/profile-img2.png")}
                                  className="img-fluid"
                                />{" "}
                                <Row>
                                  <Col lg="5">
                                    <div className="mail-input">
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter the first name and last name"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="7">
                                    <div className="mail-input">
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter the email..."
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <Row>
                            <Col lg={6}>
                              <div className="user_role mt-4">
                                <p className="webinar-setting-content1-head">
                                  User Role
                                </p>
                                <h6>
                                  Presenters will proactively speak at the
                                  event, and therefore will be given a link to
                                  the participate in the live room.
                                </h6>
                                <h6 className="mt-3">
                                  Moderators, on the other hand, will onlu
                                  assist managing and moderating the event. As a
                                  result, the system will give them access ti
                                  the control panel, not the live room itself.
                                </h6>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="user_content mt-5">
                                <div className="webinar_user_content">
                                  <img
                                    src={require("../assets/images/registration_imgs/micro.png")}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <p>Presenter</p>
                                  <img
                                    src={require("../assets/images/registration_imgs/checked.png")}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="webinar_user_content">
                                  <img
                                    src={require("../assets/images/registration_imgs/men_profile.png")}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <p>Moderator</p>
                                </div>
                                <Link to="#" className="primary_btn">
                                  <img
                                    src={require("../assets/images/chevron-right.png")}
                                    height="16"
                                    width="16"
                                    className="img-fluid"
                                  />
                                  Save
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p>Streaming service</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body></Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="mt-4">
                  <Accordion.Header>
                    <p>Webinar schedule </p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
<img
src={require("../assets/images/chevron-right.png")}
height="16"
width="16"
className="img-fluid"
/>
Cancel
</Link>
<Link to="#" className="primary_btn" >
<img
src={require("../assets/images/chevron-right.png")}
height="16"
width="16"
className="img-fluid"
/>
Confirm
</Link> */}
                        <img
                          src={require("../assets/images/accordion-config.png")}
                          className="img-fluid"
                        />{" "}
                        <p>Configured</p>
                        <Link className="accordionedit-btn">Edit</Link>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="time-mangement">
                      <div
                        className={`time-schedule ${
                          selectedSession === "single" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-select">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="Webinar-radio1"
                            value="single"
                            checked={selectedSession === "single"}
                            onChange={() => handleSessionSelect("single")}
                          />
                          <label class="form-check-label" for="Webinar-radio1">
                            One single session
                            <p>
                              The registration box will display one (or many)
                              scheduled events, and your users will register to
                              only one of them at a time
                            </p>
                            {/* <div
                              className="schedule-details"
                              style={{
                                backgroundColor:
                                  selectedSession === "single"
                                    ? "#effeff"
                                    : "#fff",
                              }}
                            >
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img1.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Full Name"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img2.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Email address"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img3.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Phone number"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <h6>Select an event</h6>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  Thursday 7th May, 5:00 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios2"
                                >
                                  Friday 8th May, 7:30 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios3"
                                >
                                  Saturday 9th May, 3:00 pm
                                </label>
                              </div>
                            </div> */}
                          </label>
                        </div>
                      </div>
                      <div
                        className={`time-schedule ${
                          selectedSession === "second" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-select">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="Webinar-radio2"
                            value="second"
                            checked={selectedSession === "second"}
                            onChange={() => handleSessionSelect("second")}
                          />
                          <label class="form-check-label" for="Webinar-radio2">
                            Multiple sessions
                            <p>
                              The registration box will display multiple
                              scheduled events, and your users will register to
                              all of them from a single sign up
                            </p>
                            {/* <div
                              className="schedule-details"
                              style={{
                                backgroundColor:
                                  selectedSession === "second"
                                    ? "#effeff"
                                    : "#fff",
                              }}
                            >
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img1.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Full name"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img2.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Email address"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img3.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Phone number"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <h6>Select an event</h6>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  Thursday 7th May, 5:00 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios2"
                                >
                                  Friday 8th May, 7:30 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios3"
                                >
                                  Saturday 9th May, 3:00 pm
                                </label>
                              </div>
                            </div> */}
                          </label>
                        </div>
                      </div>
                      <div
                        className={`time-schedule ${
                          selectedSession === "third" ? "box-shadow" : ""
                        }`}
                      >
                        <div class="form-check check-select">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="Webinar-radio3"
                            value="third"
                            checked={selectedSession === "third"}
                            onChange={() => handleSessionSelect("third")}
                          />
                          <label class="form-check-label" for="Webinar-radio3">
                            Recurring series of sessions
                            <p>
                              The registration box will display series of
                              recurring daily or weekly events, and your users
                              will register to the entire series for as long as
                              it lasts.
                            </p>
                            {/* <div
                              className="schedule-details"
                              style={{
                                backgroundColor:
                                  selectedSession === "third"
                                    ? "#effeff"
                                    : "#fff",
                              }}
                            >
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img1.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Full name"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img2.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Email address"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                  <img
                                    src={require("../assets/images/schedule-img3.png")}
                                    className="img-fluid"
                                  />{" "}
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Phone number"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </InputGroup>
                              <h6>Select an event</h6>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios1"
                                >
                                  Thursday 7th May, 5:00 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios2"
                                >
                                  Friday 8th May, 7:30 pm
                                </label>
                              </div>
                              <div class="form-check time-event mb-2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="option1"
                                />
                                <label
                                  class="form-check-label"
                                  for="exampleRadios3"
                                >
                                  Saturday 9th May, 3:00 pm
                                </label>
                              </div>
                            </div> */}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="newSission-bg">
                      <div className="newSission">
                        <p>New Session</p>
                        <Link className="onbtn">On</Link>
                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            {" "}
                            <img
                              src={require("../assets/images/calender.png")}
                              className="img-fluid cal-img"
                            />{" "}
                          </InputGroup.Text>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="date_time"
                          />
                        </InputGroup>

                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            <img
                              src={require("../assets/images/time.png")}
                              className="img-fluid"
                            />{" "}
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="date_time"
                          />
                        </InputGroup>
                        <Form.Select aria-label="Default select example">
                          <option>Select time zone</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <Link className="newsission-addbtn">Add</Link>
                      </div>
                    </div>

                    <div className="newSission-bg">
                      <div className="newSission">
                        <p>New Session</p>
                        <Link className="onbtn">Day</Link>
                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            {" "}
                            <img
                              src={require("../assets/images/calender.png")}
                              className="img-fluid cal-img"
                            />{" "}
                          </InputGroup.Text>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="date_time"
                          />
                        </InputGroup>

                        <InputGroup className="">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="img-position"
                          >
                            <img
                              src={require("../assets/images/time.png")}
                              className="img-fluid"
                            />{" "}
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Select time"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="date_time"
                          />
                        </InputGroup>

                        <Form.Select aria-label="Default select example">
                          <option>Limited</option>
                          <option value="1">Unlimited</option>
                        </Form.Select>
                        <InputGroup className="">
                          <Form.Control
                            placeholder="Limit"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            className="date_time"
                          />
                        </InputGroup>
                        <Form.Select aria-label="Default select example">
                          <option>Select time zone</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <Link className="newsission-addbtn">Submit</Link>
                      </div>
                    </div>

                    <div className="timezoneconversion-head">
                      <div className="timezoneconversion">
                        <h6>Allow time zone conversion</h6>
                        <p>
                          The Registration form will allow your visitors to
                          convert your configured time zone to theirs, so it's
                          more convenient for them. However, you may turn this
                          option OFF and therefore all times will always be
                          displayed in your configured time zone
                        </p>
                      </div>
                      <div>
                        <Form.Check type="switch" id="custom-switch" />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="mt-4">
                  <Accordion.Header>
                    <p>Registration </p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                        <img
                            src={require("../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                        />
                        Cancel
                    </Link>
                    <Link to="#" className="primary_btn" >
                        <img
                            src={require("../assets/images/chevron-right.png")}
                            height="16"
                            width="16"
                            className="img-fluid"
                        />
                        Confirm
                    </Link> */}
                        <img
                          src={require("../assets/images/accordion-config.png")}
                          className="img-fluid"
                        />{" "}
                        <p>Configured</p>
                        <Link className="accordionedit-btn">Edit</Link>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p> Registration form fields</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>Add fields</p>
                          <div className="Add_fields">
                            <InputGroup className="mb-3 input-box-1 active">
                              <Form.Control
                                value="First name"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <Button id="button-addon2">
                                {" "}
                                <img
                                  src={require("../assets/images/acc-btn.png")}
                                  className="img-fluid"
                                />
                              </Button>
                            </InputGroup>
                            <InputGroup className="mb-3 input-box-1">
                              <Form.Control
                                value="Last name"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <Button id="button-addon2">
                                {" "}
                                <img
                                  src={require("../assets/images/acc-btn.png")}
                                  className="img-fluid"
                                />
                              </Button>
                            </InputGroup>
                            <InputGroup className="mb-3 input-box-1">
                              <Form.Control
                                value="Email address"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <Button id="button-addon2">
                                {" "}
                                <img
                                  src={require("../assets/images/acc-btn.png")}
                                  className="img-fluid"
                                />
                              </Button>
                            </InputGroup>
                            <InputGroup className="mb-3 input-box-1">
                              <Form.Control
                                value="Phone number"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <Button id="button-addon2">
                                {" "}
                                <img
                                  src={require("../assets/images/acc-btn.png")}
                                  className="img-fluid"
                                />
                              </Button>
                            </InputGroup>
                          </div>

                          <div className="White_box_1">
                            <div className="Mandatory_flelds">
                              <p>Form fields</p>
                              <div className="Add_fields_2">
                                <p>Mandatory</p>
                                <div class="Add_fields_2_content">
                                  <img
                                    src={require("../assets/images/Mandatory_icon.png")}
                                    className="img-fluid"
                                  />
                                  <p>First Name</p>
                                </div>
                                <div class="Add_fields_2_content active">
                                  <img
                                    src={require("../assets/images/Mandatory_icon.png")}
                                    className="img-fluid"
                                  />
                                  <p>Last Name</p>
                                </div>
                                <div class="Add_fields_2_content">
                                  <img
                                    src={require("../assets/images/Mandatory_icon.png")}
                                    className="img-fluid"
                                  />
                                  <p>Mail</p>
                                </div>
                                <div class="Add_fields_2_content">
                                  <img
                                    src={require("../assets/images/Mandatory_icon.png")}
                                    className="img-fluid"
                                  />
                                  <p>Phone</p>
                                </div>
                              </div>
                            </div>
                            <div className="Add_fields">
                              <InputGroup className=" input-box-1 ">
                                <InputGroup.Text id="basic-addon3">
                                  <img
                                    src={require("../assets/images/person_img.png")}
                                    className="img-fluid"
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="First name"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <Button id="button-addon2">
                                  {" "}
                                  <img
                                    src={require("../assets/images/close_img.png")}
                                    className="img-fluid"
                                  />
                                </Button>
                              </InputGroup>
                              <InputGroup className=" input-box-1 ">
                                <InputGroup.Text id="basic-addon3">
                                  <img
                                    src={require("../assets/images/person_img.png")}
                                    className="img-fluid"
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Last name"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <Button id="button-addon2">
                                  {" "}
                                  <img
                                    src={require("../assets/images/close_img.png")}
                                    className="img-fluid"
                                  />
                                </Button>
                              </InputGroup>
                              <InputGroup className=" input-box-1 ">
                                <InputGroup.Text id="basic-addon3">
                                  <img
                                    src={require("../assets/images/mail_img.png")}
                                    className="img-fluid"
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Email address"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <Button id="button-addon2">
                                  {" "}
                                  <img
                                    src={require("../assets/images/close_img.png")}
                                    className="img-fluid"
                                  />
                                </Button>
                              </InputGroup>
                              <InputGroup className=" input-box-1 ">
                                <InputGroup.Text id="basic-addon3">
                                  <img
                                    src={require("../assets/images/mobile_img.png")}
                                    className="img-fluid"
                                  />
                                </InputGroup.Text>
                                <Form.Control
                                  placeholder="Phone number"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                />
                                <Button id="button-addon2">
                                  {" "}
                                  <img
                                    src={require("../assets/images/close_img.png")}
                                    className="img-fluid"
                                  />
                                </Button>
                              </InputGroup>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p> Auto subscription to future webinars</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="d-flex align-items-start Auto_subscription">
                            <div
                              class="nav nav-pills nav_pills1 me-3"
                              id="future_registration"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="future_registration-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-future_registration"
                                // type="button"
                                role="tab"
                                aria-controls="v-future_registration"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="future_registration_radio"
                                    id="future_registration_check"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="future_registration_check"
                                  >
                                    <h6> Disable auto-subscription</h6>
                                    <p>
                                      No option to auto-subscribe to future
                                      webinars will be displayed during
                                      registration.
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1"
                                id="future_registration-tab_2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-future_registration_2"
                                type="button"
                                role="tab"
                                aria-controls="v-future_registration_2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="future_registration_radio"
                                    id="future_registration_check_1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="future_registration_check_1"
                                  >
                                    <h6> Enable auto-subscription</h6>
                                    <p>
                                      During the registration process, my users
                                      will be able to opt for the
                                      auto-subscription service. This will
                                      automatically register them to any new
                                      webinar you might conduct in the future.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="tab-content" id="future_registration">
                              <div
                                class="tab-pane fade show active"
                                id="p-future_registration"
                                role="tabpanel"
                                aria-labelledby="future_registration-tab"
                              >
                                <div className="nav_pills_content_head">
                                  <div className="nav_pills_content">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="First name"
                                    />
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                  <Button>Register</Button>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-future_registration_2"
                                role="tabpanel"
                                aria-labelledby="future_registration-tab"
                              >
                                <div className="nav_pills_content_head">
                                  <div className="nav_pills_content">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="First name"
                                    />
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                  <div class="form-check">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="future_registration_radio"
                                      id="future_registration_radio3"
                                    />
                                    <label
                                      class="form-check-label"
                                      for="future_registration_radio3"
                                    >
                                      Also subscribe to future webinars
                                    </label>
                                  </div>
                                  <Button>Register</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p> Free registration vs Paid registration</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="d-flex align-items-start Auto_subscription">
                            <div
                              class="nav nav-pills nav_pills1 me-3"
                              id="paid_registration"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="paid_registration-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-paid_registration"
                                // type="button"
                                role="tab"
                                aria-controls="v-paid_registration"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="paid_registration_radio"
                                    id="paid_registration_check"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="paid_registration_check"
                                  >
                                    <h6> It's free to register</h6>
                                    <p>
                                      No option to auto-subscribe to future
                                      webinars will be displayed during
                                      registration.
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1"
                                id="paid_registration-tab_2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-paid_registration_2"
                                type="button"
                                role="tab"
                                aria-controls="v-paid_registration_2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="paid_registration_radio"
                                    id="paid_registration_check_1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="paid_registration_check_1"
                                  >
                                    <h6> Charge a registration fee</h6>
                                    <p>
                                      Your users will be sent to a payment
                                      checkout before they can complete their
                                      webinar registration.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="tab-content" id="paid_registration">
                              <div
                                class="tab-pane fade show active"
                                id="p-paid_registration"
                                role="tabpanel"
                                aria-labelledby="paid_registration-tab"
                              >
                                <div className="nav_pills_content_head">
                                  <div className="nav_pills_content">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="First name"
                                    />
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Email Address"
                                    />
                                  </div>
                                  <Button>Free Registration</Button>
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-paid_registration_2"
                                role="tabpanel"
                                aria-labelledby="paid_registration-tab"
                              >
                                <div className="nav_pills_content_head">
                                  <div className="nav_pills_content">
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="First name"
                                    />
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleFormControlInput1"
                                      placeholder="Email Address"
                                    />
                                  </div>

                                  <Button>Pay fee $10.00</Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <p> New registration notification</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="thankyou_accbody_3">
                          <div class="tab_content_11">
                            <div className="tab_flex">
                              <div
                                className={`tab_content_1_sub ${
                                  activeTab === "tab1" ? "active" : ""
                                }`}
                                onClick={toggleTab1}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="notificationOptions"
                                    id="noNotification"
                                    data-toggle="collapse"
                                    data-target="#notifyImg1"
                                    aria-expanded="false"
                                    aria-controls="notifyImg1"
                                    checked={activeTab === "tab1"} // Check if activeTab is "tab1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="noNotification"
                                  >
                                    <h6> No notification</h6>
                                    <p>
                                      You will not receive an email notification
                                      when an attendee registers to a webinar.
                                    </p>
                                  </label>
                                </div>
                              </div>

                              <div
                                className={`tab_content_1_sub2 ${
                                  activeTab === "tab2" ? "active" : ""
                                }`}
                                onClick={toggleTab2}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="notificationOptions"
                                    id="sendNotification"
                                    data-toggle="collapse"
                                    data-target="#notifyImg2"
                                    aria-expanded="false"
                                    aria-controls="notifyImg2"
                                    checked={activeTab === "tab2"} // Check if activeTab is "tab2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sendNotification"
                                  >
                                    <h6> Send notification</h6>
                                    <p>
                                      Your users will be sent to a payment
                                      checkout before they can complete their
                                      webinar registration.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="tab-img">
                              {showTab1 && (
                                <div className="collapse show img-width">
                                  <div>
                                    <div className="img-bg">
                                      <img
                                        src={require("../assets/images/notify_img.png")}
                                        className="img-fluid"
                                        alt="Notification Image 1"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {showTab2 && (
                                <div className="collapse show img-width">
                                  <div>
                                    <div className="img-bg">
                                      <img
                                        src={require("../assets/images/notify_img_1.png")}
                                        className="img-fluid"
                                        alt="Notification Image 2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {showTab2 && (
                            <div
                              className="collapse multi-collapse select_box_1"
                              id="notifyImg2"
                            >
                              <Form.Label>Recipient's email</Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select item</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </Form.Select>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <p>Password protection</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="thankyou_accbody_2">
                          {" "}
                          <div class="tab_content_11">
                            <div className="flex-tab-11">
                              <div
                                className={`tab_content_1_sub flex-grow-1 ${
                                  activeTab1 === "PasswordprotectionTab1"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={togglePasswordprotectionTab1}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="notificationOptions"
                                    id="noNotification"
                                    data-toggle="collapse"
                                    data-target="#PasswordprotectionImg1"
                                    aria-expanded="false"
                                    aria-controls="PasswordprotectionImg1"
                                    checked={
                                      activeTab1 === "PasswordprotectionTab1"
                                    } // Check if activeTab is "PasswordprotectionTab1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="noNotification"
                                  >
                                    <h6>No password needed</h6>
                                    <p>
                                      Anyone with a valid link will be able to
                                      access the webinar room.
                                    </p>
                                  </label>
                                </div>
                              </div>

                              <div
                                className={`tab_content_1_sub2 ${
                                  activeTab1 === "PasswordprotectionTab2"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={togglePasswordprotectionTab2}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="notificationOptions"
                                    id="sendNotification"
                                    data-toggle="collapse"
                                    data-target="#PasswordprotectionImg2"
                                    aria-expanded="false"
                                    aria-controls="PasswordprotectionImg2"
                                    checked={
                                      activeTab1 === "PasswordprotectionTab2"
                                    } // Check if activeTab is "PasswordprotectionTab2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sendNotification"
                                  >
                                    <h6> Password is required</h6>
                                    <p>
                                      Both a valid link and a secret password
                                      will be required to enter the webinar
                                      room.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="m-auto  ">
                              {/* <div className="tab_content_1_sub3">
                                <img
                                  src={require("../assets/images/password_img.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                                <div>
                                  <input
                                    type="email"
                                    class="form-control mb-2"
                                    id="exampleFormControlInput1"
                                    placeholder="name@example.com"
                                  />
                                  <input
                                    type="password"
                                    class="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="name@example.com"
                                  />
                                </div>
                              </div>
                              <Button>Pay fee $10.00</Button> */}
                              {showPasswordprotectionTab1 && (
                                <div className="collapse show">
                                  <div>
                                    <img
                                      src={require("../assets/images/Passwordprotection_img1.png")}
                                      className="img-fluid"
                                      alt="Notification Image 1"
                                    />
                                  </div>
                                </div>
                              )}

                              {showPasswordprotectionTab2 && (
                                <div className="collapse show">
                                  <div>
                                    <img
                                      src={require("../assets/images/Passwordprotection_img2.png")}
                                      className="img-fluid"
                                      alt="Notification Image 2"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {showPasswordprotectionTab2 && (
                            <div className="tab-content-12-head flex-grow-1">
                              <div className="tab-content-12">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="notificationOptions1"
                                    id="MasterPassword1"
                                    onClick={toggleMasterPassword1}
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="MasterPassword1"
                                  >
                                    <h6>
                                      {" "}
                                      Define one master password Everyone will
                                      log-in with the same password.
                                    </h6>
                                  </label>
                                </div>

                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="notificationOptions1"
                                    id="MasterPassword1_2"
                                    onClick={toggleMasterPassword2}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="MasterPassword1_2"
                                  >
                                    <h6>
                                      Unique passwords for every attendee The
                                      system will generate a unique password for
                                      each user.
                                    </h6>
                                  </label>
                                </div>
                              </div>
                              {showMasterPassword1 && (
                                <div className="collapse show">
                                  <div className="whitebox-content">
                                    <div class="mb-3">
                                      <label
                                        for="exampleFormControlInput1"
                                        class="form-label"
                                      >
                                        Master password
                                      </label>
                                      <input
                                        type="password"
                                        class="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter your password"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {/* <div className="tab-content-12-head">
                            <div className="tab-content-12">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="notificationOptions"
                                  id="noNotification3"
                                />
                                <label
                                  class="form-check-label"
                                  for="noNotification3"
                                >
                                  <h6>
                                    {" "}
                                    Define one master password Everyone will
                                    log-in with the same password.
                                  </h6>
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="notificationOptions"
                                  id="noNotification4"
                                />
                                <label
                                  class="form-check-label"
                                  for="noNotification4"
                                >
                                  <h6>
                                    Unique passwords for every attendee The
                                    system will generate a unique password for
                                    each user.
                                  </h6>
                                </label>
                              </div>
                            </div>
                            <div className="whitebox-content">
                              <div class="mb-3">
                                <label
                                  for="exampleFormControlInput1"
                                  class="form-label"
                                >
                                  Master password
                                </label>
                                <input
                                  type="password"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter your password"
                                />
                              </div>
                            </div>
                          </div> */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" className="mt-4">
                  <Accordion.Header>
                    <p>Notification</p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      </div>
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p>Registration form fields</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="d-flex align-items-start Auto_subscription">
                            <div
                              class="nav nav-pills nav_pills1 nav-fill me-3"
                              id="Notify_tab_content"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="notify-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-notify-tab"
                                // type="button"
                                role="tab"
                                aria-controls="v-notify-tab"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="notify-cheak"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="notify-cheak"
                                  >
                                    <h6>WebinarJam mail</h6>
                                    <p>
                                      We have a very robust built-in mailing
                                      system to send all your webinar reminders
                                      in seconds. And, best of all, it's free!
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1 "
                                id="notify-tab-2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-notify-tab-2"
                                type="button"
                                role="tab"
                                aria-controls="v-notify-tab-2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="notify-cheak-1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="notify-cheak-1"
                                  >
                                    <h6>Your own SMTP gateway</h6>
                                    <p>
                                      Plug a SMTP premium service if you'd
                                      rather send your webinar reminders through
                                      it instead.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-content notify-img_width"
                              id="Notify_tab_content"
                            >
                              <div
                                class="tab-pane fade show active"
                                id="p-notify-tab"
                                role="tabpanel"
                                aria-labelledby="notify-tab"
                              >
                                <div className=" img-bg">
                                  <img
                                    src={require("../assets/images/notify_img.png")}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-notify-tab-2"
                                role="tabpanel"
                                aria-labelledby="notify-tab-2"
                              >
                                <div className=" img-bg">
                                  <img
                                    src={require("../assets/images/notify_img_1.png")}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p> Auto subscription to future webinars</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="dashbordrightbody-list1 registration_alert">
                            <h6>
                              All emails notifications will be sent out from{" "}
                              <span>"Kevin Peterson - kevin@peterson.com"</span>
                              . If you'd like to change it, read this article.
                            </h6>
                            <div className="pre_notification">
                              <ul className="pre_notification_list">
                                <li className="list_msg">
                                  <h4 className="mb-2">
                                    Pre-Webinar notifications
                                  </h4>
                                  <p>
                                    Keep them in the loop of the webinar they've
                                    registered for, It's a great opportunity to
                                    hand them their link to the webinar room,
                                    and to remind them of the topics that will
                                    be covered. It helps greatly to maximize
                                    attendance!
                                  </p>
                                  <ul className="pre_notification_1 mt-3">
                                    <li>
                                      <div className="confirm_msg">
                                        <div className="add_info">
                                          <h4>Register Confirmation Message</h4>
                                          <div className="add_info_span">
                                            <a href="#">Add Phone</a>
                                            <a href="#">Add Email</a>
                                          </div>
                                        </div>
                                        <div className="confirm_msg_content mt-4">
                                          <div className="add_info_content">
                                            <img
                                              src={require("../assets/images/registration_imgs/msg.png")}
                                              className="img-fluid"
                                            />
                                            <div>
                                              <h4>Immediately</h4>
                                              <p>
                                                [Confirmation] Your webinar link
                                              </p>
                                            </div>
                                          </div>
                                          <div className="add_info_img">
                                            <a href="#">
                                              {" "}
                                              <img
                                                src={require("../assets/images/registration_imgs/mail.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                            <a href="#">
                                              <img
                                                src={require("../assets/images/registration_imgs/pencil.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                            <a href="#">
                                              {" "}
                                              <img
                                                src={require("../assets/images/registration_imgs/wrong.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="confirm_msg">
                                        <div className="add_info">
                                          <h4>Register Confirmation Message</h4>
                                          <div className="add_info_span">
                                            <a href="#">Add Phone</a>
                                            <a href="#">Add Email</a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="confirm_msg">
                                        <div className="add_info">
                                          <h4>Register Confirmation Message</h4>
                                          <div className="add_info_span">
                                            <a href="#">Add Phone</a>
                                            <a href="#">Add Email</a>
                                          </div>
                                        </div>
                                        <div className="confirm_msg_content mt-4">
                                          <div className="add_info_content">
                                            <img
                                              src={require("../assets/images/registration_imgs/msg.png")}
                                              className="img-fluid"
                                            />
                                            <div>
                                              <h4>Immediately</h4>
                                              <p>
                                                [Confirmation] Your webinar link
                                              </p>
                                            </div>
                                          </div>
                                          <div className="add_info_img">
                                            <a href="#">
                                              {" "}
                                              <img
                                                src={require("../assets/images/registration_imgs/mail.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                            <a href="#">
                                              <img
                                                src={require("../assets/images/registration_imgs/pencil.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                            <a href="#">
                                              {" "}
                                              <img
                                                src={require("../assets/images/registration_imgs/wrong.png")}
                                                className="img-fluid"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li className="list_msg">
                                  <h4>Post Webinar notifications</h4>
                                  <p>
                                    Follow up with them to hand them the link to
                                    the webinar replay in case they missed it,
                                    or remind them of your product and sevices.
                                    this is also great opportunity to invite
                                    them to another webinar event!
                                  </p>
                                  <ul className="pre_notification_1">
                                    <li>
                                      <div className="confirm_msg">
                                        <div className="add_info">
                                          <h4>Post Webinar follow ups</h4>
                                          <div className="add_info_span">
                                            <a href="#">Add Phone</a>
                                            <a href="#">Add Email</a>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="mt-4">
                  <Accordion.Header>
                    <p>Thank You </p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      </div>
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p>Default vs Custom confirmation page</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="thankyou_accbody">
                          <div class="d-flex align-items-start Auto_subscription">
                            <div
                              class="nav nav-pills nav_pills1 nav-fill me-3"
                              id="Default-vs-Custom"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="Custom-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-Custom-tab"
                                // type="button"
                                role="tab"
                                aria-controls="v-Custom-tab"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Custom-cheak"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Custom-cheak"
                                  >
                                    <h6>Vaffix’s default confirmation page</h6>
                                    <p>
                                      Upon registration, your subscribers will
                                      be redirected to our default confirmation
                                      page. Here, the user will receive the link
                                      to the webinar room, plus a summary of all
                                      the details: date, time, host details,
                                      etc.
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1 "
                                id="Custom-tab-2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-Custom-tab-2"
                                type="button"
                                role="tab"
                                aria-controls="v-Custom-tab-2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Custom-cheak-1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Custom-cheak-1"
                                  >
                                    <h6> Your own custom page</h6>
                                    <p>
                                      Upon registration, your subscribers will
                                      be sent to your external page of choice.
                                      If you go for this option, make sure to
                                      also configure an email notification with
                                      the webinar's details so your users get
                                      all the necessary instructions.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="tab-content" id="Default-vs-Custom">
                              <div
                                class="tab-pane fade show active"
                                id="p-Custom-tab"
                                role="tabpanel"
                                aria-labelledby="Custom-tab"
                              >
                                <img
                                  src={require("../assets/images/thankyou_img_1.png")}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-Custom-tab-2"
                                role="tabpanel"
                                aria-labelledby="Custom-tab-2"
                              >
                                <img
                                  src={require("../assets/images/thankyou_img_2.png")}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p>Survey your registrants</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div class="d-flex align-items-start Auto_subscription thankyou_accbody_1">
                            <div
                              class="nav nav-pills nav_pills1 nav-fill me-3"
                              id="Survey_tab_head"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="Survey-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-Survey-tab"
                                // type="button"
                                role="tab"
                                aria-controls="v-Survey-tab"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Survey-cheak"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Survey-cheak"
                                  >
                                    <h6>No, don't survey my registrants</h6>
                                    <p>
                                      Don't pop any survey in the Thank You
                                      page.
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1 "
                                id="Survey-tab-2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-Survey-tab-2"
                                type="button"
                                role="tab"
                                aria-controls="v-Survey-tab-2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="Survey-cheak-1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Survey-cheak-1"
                                  >
                                    <h6> Yes, survey my registrants</h6>
                                    <p>
                                      Pop a survey in the Thank You page, ask
                                      your registrants about the topics they're
                                      most interested in, and script your
                                      webinar content around those topics!
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="tab-content" id="Survey_tab_head">
                              <div
                                class="tab-pane fade show active"
                                id="p-Survey-tab"
                                role="tabpanel"
                                aria-labelledby="Survey-tab"
                              >
                                <img
                                  src={require("../assets/images/thankyou_img_4.png")}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-Survey-tab-2"
                                role="tabpanel"
                                aria-labelledby="Survey-tab-2"
                              >
                                <img
                                  src={require("../assets/images/thankyou_img_3.png")}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="gray-body">
                            <p>Add modules to your survey</p>
                            <div className="poll-head">
                              <div className="polls-Add ">
                                <button className="polls-add-btn active">
                                  <span>Poll allowing only one answer</span>
                                  <img
                                    src={require("../assets/images/acc-btn.png")}
                                    className="img-fluid"
                                  />
                                </button>
                                <button className="polls-add-btn">
                                  <span>Poll allowing multiple answer</span>
                                  <img
                                    src={require("../assets/images/acc-btn.png")}
                                    className="img-fluid"
                                  />
                                </button>
                                <button className="polls-add-btn">
                                  <span>Question for a short answer</span>
                                  <img
                                    src={require("../assets/images/acc-btn.png")}
                                    className="img-fluid"
                                  />
                                </button>
                                <button className="polls-add-btn">
                                  <span>Question for a long answer</span>
                                  <img
                                    src={require("../assets/images/acc-btn.png")}
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                              <div className="polls-qa">
                                <div className="poll-question">
                                  <p>Question 1</p>
                                  <div>
                                    <InputGroup className="">
                                      <Form.Control
                                        placeholder="Enter the poll option"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                      />
                                      <InputGroup.Text id="basic-addon2">
                                        <img
                                          src={require("../assets/images/close-circle.png")}
                                          className="img-fluid"
                                        />
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </div>
                                </div>

                                <div className="poll-answer">
                                  <div>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        placeholder="Enter the poll option"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                      />
                                      <InputGroup.Text id="basic-addon2">
                                        <img
                                          src={require("../assets/images/add-circle.png")}
                                          className="img-fluid"
                                        />
                                      </InputGroup.Text>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        placeholder="Enter the poll option"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                      />
                                      <InputGroup.Text id="basic-addon2">
                                        <img
                                          src={require("../assets/images/add-circle.png")}
                                          className="img-fluid"
                                        />
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p>Thank you page design</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="dashbordrightbody-list1 registration_radio">
                            <div className="registration_sec ">
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 1 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(1)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>

                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 2 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(2)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 3 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(3)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 4 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(4)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 5 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(5)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="mt-4">
                  <Accordion.Header>
                    <p>Live </p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      </div>
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p>Autoplay</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="Live_accbody">
                          <div class="tab_content_11">
                            <div className="tab_flex">
                              <div
                                className={`tab_content_1_sub ${
                                  ActiveAutoplayTab === "tab1" ? "active" : ""
                                }`}
                                onClick={toggleAutoplayTab1}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="AutoplayOptions"
                                    id="noAutoplay"
                                    data-toggle="collapse"
                                    data-target="#AutoplayImg1"
                                    aria-expanded="false"
                                    aria-controls="AutoplayImg1"
                                    checked={ActiveAutoplayTab === "tab1"} // Check if ActiveAutoplayTab is "tab1"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="noAutoplay"
                                  >
                                    <h6>
                                      {" "}
                                      WebinarJam's default confirmation page
                                    </h6>
                                    <p>
                                      When users land on your webinar the video
                                      will play automatically. Some browser's
                                      autoplay policy forbids the use of
                                      autoplay videos with sound.
                                    </p>
                                  </label>
                                </div>
                              </div>

                              <div
                                className={`tab_content_1_sub2 ${
                                  ActiveAutoplayTab === "tab2" ? "active" : ""
                                }`}
                                onClick={toggleAutoplayTab2}
                              >
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="AutoplayOptions"
                                    id="sendAutoplay"
                                    data-toggle="collapse"
                                    data-target="#AutoplayImg2"
                                    aria-expanded="false"
                                    aria-controls="AutoplayImg2"
                                    checked={ActiveAutoplayTab === "tab2"} // Check if ActiveAutoplayTab is "tab2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="sendAutoplay"
                                  >
                                    <h6> Your own custom page</h6>
                                    <p>
                                      The video will not be played until the
                                      users decide to click on play.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="tab-img">
                              {ShowAutoplay1 && (
                                <div className="collapse show ">
                                  <img
                                    src={require("../assets/images/autoplay_img.png")}
                                    className="img-fluid"
                                    alt="Autoplay Image 1"
                                  />
                                </div>
                              )}

                              {ShowAutoplay2 && (
                                <div className="collapse show ">
                                  <img
                                    src={require("../assets/images/autoplay_img2.png")}
                                    className="img-fluid"
                                    alt="Autoplay Image 2"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {ShowAutoplay2 && (
                            <Row className="select_box_1">
                              <Col lg="6">
                                <p>WebinarJam's default confirmation page</p>
                                <h6>
                                  Waiting room could be customized by selecting
                                  an image and text.
                                </h6>
                              </Col>
                              <Col lg="6">
                                <p>Call to action text</p>
                                <Form.Control
                                  type="Text"
                                  id="inputPassword5"
                                  aria-describedby="passwordHelpBlock"
                                  placeholder="START THE BROADCAST"
                                  className="placeholder"
                                />
                              </Col>
                            </Row>
                            // <div
                            //   className="collapse multi-collapse select_box_1"
                            //   id="AutoplayImg2"
                            // >
                            //   <Form.Label>Recipient's email</Form.Label>
                            //   <Form.Select aria-label="Default select example">
                            //     <option>Select item</option>
                            //     <option value="1">One</option>
                            //     <option value="2">Two</option>
                            //     <option value="3">Three</option>
                            //   </Form.Select>
                            // </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <p>Countdown page design</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="dashbordrightbody-list1 registration_radio">
                            <div className="registration_sec ">
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 1 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(1)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>

                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 2 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(2)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 3 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(3)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 4 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(4)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 5 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(5)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <p>Live room design</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="dashbordrightbody-list1 registration_radio">
                            <div className="registration_sec ">
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 1 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(1)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>

                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 2 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(2)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 3 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(3)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 4 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(4)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img_1.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                              <a
                                href="#"
                                className={`registration_body  ${
                                  activeBody === 5 ? "active" : ""
                                }`}
                                onClick={() => handleBodyClick(5)}
                              >
                                <img
                                  src={require("../assets/images/registration_imgs/user_img.png")}
                                  className="img-fluid"
                                />
                                <div className="registration_info mt-3">
                                  <p>
                                    Template name : <span>Vaf 001</span>{" "}
                                    <img
                                      src={require("../assets/images/registration_imgs/eye_icon.png")}
                                      className="img-fluid"
                                    />{" "}
                                  </p>

                                  <div className="registration_info_1">
                                    <img
                                      src={require("../assets/images/registration_imgs/pencil_square.png")}
                                      className="img-fluid"
                                    />
                                    <p>Customize</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          <p>Polls and quizzes</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="live-content">
                            <p>
                              If you are planning to run any survey or quiz
                              during your webinar, you might want to
                              pre-configure it now. Once in the live room, you
                              will be able to pop them up in front of your
                              audience with the click of the mouse.
                            </p>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleShowPoll}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Add New Poll
                              </Link>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>
                          <p>Product offers</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="live-content">
                            <p>
                              If you are planning to run any survey or quiz
                              during your webinar, you might want to
                              pre-configure it now. Once in the live room, you
                              will be able to pop them up in front of your
                              audience with the click of the mouse.
                            </p>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleProductofferShow}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Add New Offer
                              </Link>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>
                          <p>Video injections</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="live-content">
                            <p>
                              Video injections allow you to play a video clip
                              midway through your presentation. The system will
                              seamlessly switch from your live video/audio
                              signal to the video signal, and back to live when
                              the video is over. Pre-configure your video
                              injections now, so you can play them at any time
                              during your live broadcast with the click of a
                              button.
                            </p>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleVideoInjectionShow}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Add New Video
                              </Link>
                            </div>
                          </div>{" "}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>
                          <p>File handouts</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="live-content">
                            <p>
                              Upload now the files you might want to share later
                              on with your participants so, once in the live
                              room, you can conveniently do so with the click of
                              the mouse.
                            </p>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleUpload_fileShow}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Add New File
                              </Link>
                            </div>
                          </div>{" "}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header>
                          <p>Slide presentations</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {" "}
                          <div className="live-content">
                            <p>
                              If you're planning to run a slide presentation
                              during your webinar, you might want to
                              pre-configure it now. Once in the live room, you
                              will be able to initiate the presentation with the
                              click of the mouse. Read this article to learn how
                              to upload your presentation file.
                            </p>
                            <div>
                              <Link
                                to="#"
                                className="primary_btn primary_btn2 me-4"
                                onClick={handleUpload_slideShow}
                              >
                                <svg
                                  width="8"
                                  height="12"
                                  viewBox="0 0 8 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.5 1L6.5 6L1.5 11"
                                    stroke="#001F2A"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Add New Presentation
                              </Link>
                            </div>
                          </div>{" "}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="accItem">
                  <Accordion.Header>
                    <p>Replay</p>
                    <div className="accordion-header-content">
                      <div className="dashborad_button">
                        {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                      </div>
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Accordion
                      defaultActiveKey="0"
                      flush
                      className="inner-accMain"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <p> Webinar replay recording</p>
                          <div className="accordion-header-content">
                            <div className="dashborad_button">
                              {/* <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Cancel
                                            </Link>
                                            <Link to="#" className="primary_btn" >
                                                <img
                                                    src={require("../assets/images/chevron-right.png")}
                                                    height="16"
                                                    width="16"
                                                    className="img-fluid"
                                                />
                                                Confirm
                                            </Link> */}
                            </div>
                            <img
                              src={require("../assets/images/accordion-config.png")}
                              className="img-fluid"
                            />{" "}
                            <p>Configured</p>
                            <Link className="accordionedit-btn">Edit</Link>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div class="d-flex align-items-start Auto_subscription">
                            <div
                              class="nav nav-pills nav_pills1 nav-fill me-3"
                              id=" replay_page"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div
                                class="nav-link nav_link1 active"
                                id="replay_page_tab"
                                data-bs-toggle="pill"
                                data-bs-target="#p-replay_page_tab"
                                // type="button"
                                role="tab"
                                aria-controls="v-replay_page_tab"
                                aria-selected="true"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="replay_page_check"
                                    checked
                                  />
                                  <label
                                    class="form-check-label"
                                    for="replay_page_check"
                                  >
                                    <h6>
                                      No, do not allow access to the replay page
                                    </h6>
                                    <p>
                                      The system will block access to the Replay
                                      Room so nobody can watch the replay video.
                                    </p>
                                  </label>
                                </div>
                              </div>
                              <div
                                class="nav-link nav_link1 "
                                id="replay_page_tab-2"
                                data-bs-toggle="pill"
                                data-bs-target="#p-replay_page_tab-2"
                                type="button"
                                role="tab"
                                aria-controls="v-replay_page_tab-2"
                                aria-selected="false"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="replay_page_check-1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="replay_page_check-1"
                                  >
                                    <h6>
                                      {" "}
                                      Yes, allow access to the replay page
                                    </h6>
                                    <p>
                                      You will be able to send your attendees to
                                      the Replay Room to watch the recordings of
                                      your live session.
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="tab-content" id="replay_page">
                              <div
                                class="tab-pane fade show active"
                                id="p-replay_page_tab"
                                role="tabpanel"
                                aria-labelledby="replay_page_tab"
                              >
                                <img
                                  src={require("../assets/images/Replay_img.png")}
                                  className="img-fluid"
                                />
                              </div>
                              <div
                                class="tab-pane fade"
                                id="p-replay_page_tab-2"
                                role="tabpanel"
                                aria-labelledby="replay_page_tab-2"
                              >
                                <img
                                  src={require("../assets/images/Replay_img_2.png")}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="footer_img mt-5">
                <a href="#">
                  {" "}
                  <img src={nextImg} alt="img" className="img-fluid" />
                </a>
                <a href="#">
                  {" "}
                  <img
                    src={require("../assets/images/registration_imgs/blue_arrow.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
            <Innerfooter />
          </div>
        </div>
      </div>

      {/* //popup */}
      <Modal show={Polls} onHide={handlePollsClose} className="model_polls">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>Poll or quiz</Modal.Title>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Poll question</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            value="Enter the poll question"
          />
          <Form.Label htmlFor="inputPassword5">Option 1</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            placeholder="Enter the poll option"
          />
          <Form.Label htmlFor="inputPassword5">Option 2</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            placeholder="Enter the poll option"
          />
          <div className="Add_option">
            <img
              src={require("../assets/images/acc-btn.png")}
              className="img-fluid"
            />
            <span>Add another option</span>
          </div>
          <div className="model_btn">
            <Link to="#" className="model_btn_1 flex-grow-1">
              Cancel
            </Link>
            <Link to="#" className="model_btn_1 flex-grow-1">
              Save
            </Link>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={Productoffer}
        onHide={handleProductofferClose}
        className="model_Productoffer"
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>Product offer</Modal.Title>
        <Modal.Body>
          <Row>
            <Col lg="6">
              {" "}
              <Form.Label htmlFor="inputPassword5">Name the offer</Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Enter the offer name"
              />
              <Form.Label htmlFor="inputPassword5">Offer headline</Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Enter the headline"
              />
              <Form.Label htmlFor="inputPassword5">Offer image</Form.Label>
              <div class="file-drop-area">
                <span class="choose-file-button">
                  {" "}
                  <img
                    src={require("../assets/images/file_imge.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <span class="file-message">Upload image</span>
                <input class="file-input" type="file" multiple />
              </div>
              <p className="file-explain">
                Image is optional, Max 1MB. Valid formats: JPG, JPEG, GIF and
                PNG
              </p>
            </Col>
            <Col lg="6">
              <Form.Label htmlFor="inputPassword5">
                Text above the button
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Enter the text above the button"
              />
              <Form.Label htmlFor="inputPassword5">
                Text inside the button
              </Form.Label>
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Example: Add to cart"
              />

              <Form.Label htmlFor="inputPassword5">Button link</Form.Label>
              <div className="button-switch">
                <InputGroup className="button_link">
                  <InputGroup.Text id="basic-addon1">
                    http(s)://
                  </InputGroup.Text>
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>

                <div>
                  <div className="switch-control">
                    <Form.Check type="switch" id="all_timezone" />
                  </div>
                  <Form.Label htmlFor="inputPassword5" className="new-tab">
                    New Tab
                  </Form.Label>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6">
              <div className="Broadcast_sales mb-3">
                <div>
                  <h6>Broadcast sales in real time</h6>
                  <p>
                    A brief "So and so has just purchased" message will be
                    visible by anyone in the room. The idea is to encourage
                    interaction and social proof as the sales start to roll in.
                  </p>
                </div>
                <div className="switch-control ">
                  <Form.Check type="switch" id="all_timezone" />
                </div>
              </div>
              <div className="Broadcast_sales mb-3">
                <div>
                  <h6>Apply scarcity in your offer</h6>
                  <p>
                    Limit the number of copies available to encourage sales and
                    prompt action.
                  </p>
                  <div className="units">
                    <p>
                      00 <span> Units</span>
                    </p>
                  </div>
                </div>
                <div className="switch-control">
                  <Form.Check type="switch" id="all_timezone" />
                </div>
              </div>
              <div className="Broadcast_sales mb-3">
                <div>
                  <h6>Apply urgency in your offer</h6>
                  <p>
                    A countdown to offer expiration will be publicly displayed,
                    upon which the offer will be taken down.
                  </p>
                </div>
                <div className="switch-control">
                  <Form.Check type="switch" id="all_timezone" />
                </div>
              </div>
              <div className="img_time">
                <div className="Available_units units">
                  <p>00 hr</p>
                </div>
                <div className="Available_units units">
                  <p>00 min</p>
                </div>
                <div className="Available_units units">
                  <p>00 sec</p>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="border-col">
                <img
                  src={require("../assets/images/live_img.png")}
                  alt="img"
                  className="img-fluid"
                />
                <p>Here comes your text above the button</p>
                <div className="add-cart-btn">
                  <Link to="#" className="model_btn_1 flex-grow-1">
                    Add cart
                  </Link>
                </div>
                <div className="img_time">
                  <div className="Available_units units">
                    <p>
                      00 <span>Available units</span>
                    </p>
                  </div>
                  <div className="Available_units units">
                    <p>00 : 00 : 00</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className="model_btn">
            <Link to="#" className="model_btn_1 flex-grow-1">
              Cancel
            </Link>
            <Link to="#" className="model_btn_1 flex-grow-1">
              Save
            </Link>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={VideoInjection}
        onHide={handleVideoInjectionClose}
        className="model_VideoInjection"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>Video Injection</Modal.Title>
        <Modal.Body>
          <div>
            {" "}
            <Form.Label htmlFor="inputPassword5">Name of the video</Form.Label>
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              placeholder="Enter the name of the video..."
            />
            <p className="file-explain">For your reference only</p>
            <Form.Label htmlFor="inputPassword5">Button link</Form.Label>
            <div className="button-switch">
              <InputGroup className="button_link">
                <InputGroup.Text id="basic-addon1">http(s)://</InputGroup.Text>
                <Form.Control
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={Upload_file}
        onHide={handleUpload_fileClose}
        className="model_Upload_file"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>Upload a file</Modal.Title>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">Name of the file</Form.Label>
          <div className="model_flex">
            {" "}
            <Form.Control
              type="text"
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              placeholder="Enter the name of the file..."
            />
            <div class="file-drop-area">
              <span class="choose-file-button">
                {" "}
                <img
                  src={require("../assets/images/file_imge.png")}
                  alt="img"
                  className="img-fluid"
                />
              </span>
              <span class="file-message">Upload file</span>
              <input class="file-input" type="file" multiple />
            </div>
          </div>
          <p className="file-explain text-end">Max size: 80MB</p>
        </Modal.Body>
      </Modal>
      <Modal
        show={Upload_slide}
        onHide={handleUpload_slideClose}
        className="model_Upload_slide"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>Slide presentations</Modal.Title>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">
            Upload slide presentation
          </Form.Label>
          <div className="model_flex_1">
            {" "}
            <div className="first-column ">
              <Form.Control
                type="text"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Enter the name of the slide presentation"
              />
              <p className="file-explain ">
                Upload problems? <Link>Read this.</Link>
              </p>
            </div>
            <div className="second-column ">
              <div class="file-drop-area">
                <span class="choose-file-button">
                  {" "}
                  <img
                    src={require("../assets/images/file_imge.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <span class="file-message">Upload file</span>
                <input class="file-input" type="file" multiple />
              </div>
              <p className="file-explain text-end">
                Max size: 80MB PPT, PPTX, KeyNote, PDF
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ConfigrationPage;
