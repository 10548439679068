import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
import nextImg from "../assets/images/side-icon.svg";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
import BasicFullconfig from "../components/fullconfigraton/basicsetting-fullconfig";
import RegistrationFullconfig from "../components/fullconfigraton/registration-fullconfig";
import Notificationfullconfig from "../components/fullconfigraton/notification-fullconfig";
import Webinarschedulefullconfig from "../components/fullconfigraton/webinarschedule-fullconfig";
import Othersettingfullconfig from "../components/fullconfigraton/Othersetting-fullconfig";
function FullConfigration() {
  return (
    <div className="dashboard-page">
      <div className="dashboard">
        <Innerpagenavbar />
        <div className="dashbord-right mt-2">
          <div className="dashbord-right-body-1">
            <div className="registration_nav_sec">
              <Scrollbars
                className="custom-scrollbar"
                style={{ width: "100%", height: 100 }}
                renderTrackVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    className="scrollbar-track vertical"
                    style={{ ...style }}
                  />
                )}
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    className="scrollbar-thumb vertical"
                    style={{ ...style }}
                  />
                )}
              >
                <ul className="registration_list">
                  <li>
                    <div className="registration_nav_content active">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/registration_imgs/notepad.png")}
                          className="img-fluid top-head"
                        />
                        <img
                          src={require("../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked"
                        />
                      </div>
                      <p>Configuration</p>
                      <img
                        src={require("../assets/images/registration_imgs/checked.png")}
                        className="img-fluid top-checked1"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="registration_nav_content active">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/registration_imgs/calendar-tick.png")}
                          className="img-fluid top-head"
                        />
                        <img
                          src={require("../assets/images/registration_imgs/checked.png")}
                          className="img-fluid top-checked"
                        />
                      </div>
                      <p>Schedules</p>
                      <img
                        src={require("../assets/images/registration_imgs/checked.png")}
                        className="img-fluid top-checked1"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="registration_nav_content ">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/registration_imgs/driver.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Registration</p>
                    </div>
                  </li>
                  <li>
                    <div className="registration_nav_content">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/registration_imgs/notification_2.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Othersetting</p>
                    </div>
                  </li>
                  <li>
                    <div className="registration_nav_content">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/registration_imgs/notification_2.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Notification</p>
                    </div>
                  </li>

                  <li>
                    <div className="registration_nav_content">
                      <div className="top-head-img">
                        <img
                          src={require("../assets/images/video.png")}
                          className="img-fluid top-head"
                        />
                      </div>
                      <p>Finish</p>
                      {/* <img
                    src={require("../assets/images/registration_imgs/checked.png")}
                    className="img-fluid"
                  /> */}
                    </div>
                  </li>
                </ul>
              </Scrollbars>
            </div>
            <Accordion
              className="accMain registration mt-4"
              defaultActiveKey={["0"]}
            >
              <Accordion.Item eventKey="0" className="accItem">
                <Accordion.Header>
                  <p>Configuration</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Cancel
                                        </Link>
                                        <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Confirm
                                        </Link> */}
                    </div>
                    <img
                      src={require("../assets/images/accordion-config.png")}
                      className="img-fluid"
                    />{" "}
                    <p>Configured</p>
                    <Link className="accordionedit-btn">Edit</Link>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <BasicFullconfig />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1" className="mt-4">
                <Accordion.Header>
                  <p>Webinar schedule </p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
<img
src={require("../assets/images/chevron-right.png")}
height="16"
width="16"
className="img-fluid"
/>
Cancel
</Link>
<Link to="#" className="primary_btn" >
<img
src={require("../assets/images/chevron-right.png")}
height="16"
width="16"
className="img-fluid"
/>
Confirm
</Link> */}
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Webinarschedulefullconfig />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="mt-4">
                <Accordion.Header>
                  <p>Registration </p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                    <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid"
                    />
                    Cancel
                </Link>
                <Link to="#" className="primary_btn" >
                    <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid"
                    />
                    Confirm
                </Link> */}
                      <img
                        src={require("../assets/images/accordion-config.png")}
                        className="img-fluid"
                      />{" "}
                      <p>Configured</p>
                      <Link className="accordionedit-btn">Edit</Link>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="inner-accMain">
                  <RegistrationFullconfig />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mt-4">
                <Accordion.Header>
                  <p>Other Settings</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Cancel
                                        </Link>
                                        <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Confirm
                                        </Link> */}
                    </div>
                    <img
                      src={require("../assets/images/accordion-config.png")}
                      className="img-fluid"
                    />{" "}
                    <p>Configured</p>
                    <Link className="accordionedit-btn">Edit</Link>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Othersettingfullconfig />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mt-4">
                <Accordion.Header>
                  <p>Notification</p>
                  <div className="accordion-header-content">
                    <div className="dashborad_button">
                      {/* <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Cancel
                                        </Link>
                                        <Link to="#" className="primary_btn" >
                                            <img
                                                src={require("../assets/images/chevron-right.png")}
                                                height="16"
                                                width="16"
                                                className="img-fluid"
                                            />
                                            Confirm
                                        </Link> */}
                    </div>
                    <img
                      src={require("../assets/images/accordion-config.png")}
                      className="img-fluid"
                    />{" "}
                    <p>Configured</p>
                    <Link className="accordionedit-btn">Edit</Link>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Notificationfullconfig />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="footer_img mt-5">
              <a href="#">
                {" "}
                <img src={nextImg} alt="img" className="img-fluid" />
              </a>
              <a href="#">
                {" "}
                <img
                  src={require("../assets/images/registration_imgs/blue_arrow.png")}
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
          <Innerfooter />
        </div>
      </div>
    </div>
  );
}
export default FullConfigration;
