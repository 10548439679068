import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerpagenavbar from "../innerpage-navbar";
import Innerfooter from "../inner-footer";
const Settingpage = () => {
  return (
    <>
      {/* <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5">
            <div className="dashbord-right-header">
              <p>Basic settings</p>
              <div>
                <Link to="#" className="primary_btn primary_btn2 me-4">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 1L6.5 6L1.5 11"
                      stroke="#001F2A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </Link>
                <Link to="#" className="primary_btn">
                  <img
                    src={require("../../assets/images/chevron-right.png")}
                    height="16"
                    width="16"
                    className="img-fluid"
                  />{" "}
                  Confirm
                </Link>
              </div>
            </div>
            <div className="white-box webinar-setting-content">
              <Row>
                <Col lg="2">
                  {" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    {" "}
                    <Form.Label>Webinar language</Form.Label>{" "}
                    <Form.Select aria-label="Default select example">
                      <option>English</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                    <p>The interface will be translated for your users</p>
                  </Form.Group>
                </Col>
                <Col lg="3">
                  {" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Webinar name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Example: Webinar for my free leads"
                    />
                    <p>
                      Private title. for your reference only. Max 70 characters.
                    </p>
                  </Form.Group>
                </Col>
                <Col lg="3">
                  {" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Webinar title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Example: How to lose weight in 6 weeks"
                    />
                    <p>Public title. Max 70 characters.</p>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  {" "}
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Webinar description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Example: All the tips and tricks to efficient diets and workouts"
                    />
                    <p>Public description. Max 150 characters.</p>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="webinar-setting-sub-head">
              <div className="webinar-setting-content1 webinar-width">
                <div>
                  <p className="webinar-setting-content1-head">Presenters</p>
                  <div className="white-box1 webinar-setting-sub">
                    <Row>
                      <Col lg="3">
                        {" "}
                        <img
                          src={require("../../assets/images/profile-img.png")}
                          className="img-fluid"
                        />{" "}
                      </Col>
                      <Col lg="5">
                        {" "}
                        <div>
                          <div className="name-content">
                            <p>Katy Perry</p>
                            <div className="verify">
                              {" "}
                              <img
                                src={require("../../assets/images/verify-img.png")}
                                className="img-fluid"
                              />{" "}
                              <p>Verified</p>
                            </div>
                          </div>
                          <p className="email-profile">kevin@peterson.com</p>
                        </div>
                      </Col>
                      <Col lg="4">
                        {" "}
                        <div className="profile-change">
                          <img
                            src={require("../../assets/images/setting-img.png")}
                            className="img-fluid"
                          />{" "}
                          <img
                            src={require("../../assets/images/voice-img.png")}
                            className="img-fluid"
                          />{" "}
                          <Link className="profile-edit">Edit</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="webinar-setting-content1 webinar-width1">
                <div>
                  <p className="webinar-setting-content1-head">
                    New Presenters
                  </p>
                  <div className="white-box1 webinar-setting-sub">
                    <img
                      src={require("../../assets/images/profile-img2.png")}
                      className="img-fluid"
                    />{" "}
                    <Row>
                      <Col lg="5">
                        {" "}
                        <Form.Control
                          type="text"
                          placeholder="Enter the first name and last name"
                        />
                      </Col>
                      <Col lg="7">
                        {" "}
                        <Form.Control
                          type="text"
                          placeholder="Enter the email..."
                        />
                      </Col>
                    </Row>
                    <Link className="profile-edit">Add</Link>
                  </div>
                </div>
              </div>
            </div>
            <Row className="alingn">
              <Col lg="5">
                <div className="webinar-setting-content1 webinar-width">
                  <div>
                    <p className="webinar-setting-content1-head">Presenters</p>
                    <div className="white-box1 webinar-setting-sub">
                      <Row>
                        <Col lg="3">
                          {" "}
                          <img
                            src={require("../../assets/images/profile-img.png")}
                            className="img-fluid"
                          />{" "}
                        </Col>
                        <Col lg="5">
                          {" "}
                          <div>
                            <div className="name-content">
                              <p>Katy Perry</p>
                              <div className="verify">
                                {" "}
                                <img
                                  src={require("../../assets/images/verify-img.png")}
                                  className="img-fluid"
                                />{" "}
                                <p>Verified</p>
                              </div>
                            </div>
                            <p className="email-profile">kevin@peterson.com</p>
                          </div>
                        </Col>
                        <Col lg="4">
                          {" "}
                          <div className="profile-change">
                            <img
                              src={require("../../assets/images/setting-img.png")}
                              className="img-fluid"
                            />{" "}
                            <img
                              src={require("../../assets/images/voice-img.png")}
                              className="img-fluid"
                            />{" "}
                            <Link className="profile-edit">Edit</Link>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="7">
                {" "}
                <div className="webinar-setting-content1 webinar-width1">
                  <div>
                    <p className="webinar-setting-content1-head">
                      New Presenters
                    </p>
                    <div className="white-box1 webinar-setting-sub">
                      <img
                        src={require("../../assets/images/profile-img2.png")}
                        className="img-fluid"
                      />{" "}
                      <Row>
                        <Col lg="5">
                          {" "}
                          <Form.Control
                            type="text"
                            placeholder="Enter the first name and last name"
                          />
                        </Col>
                        <Col lg="7">
                          {" "}
                          <Form.Control
                            type="text"
                            placeholder="Enter the email..."
                          />
                        </Col>
                      </Row>
                      <Link className="profile-edit">Add</Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Innerfooter /> */}
    </>
  );
};
export default Settingpage;
