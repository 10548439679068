import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../../components/inner-footer";
import Template_navbar from "../../components/template-navbar";
import ReactHtmlParser from 'react-html-parser';

//EDITOR
import { CKEditor, useCKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  InlineEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
//API
import { getRegistartionTemplate } from "../../action/user.action";
const Template_2 = () => {
  const { id, tid, meetingType } = useParams();

  //EDITOR
  let plugins = [
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
  ];
  let toolbar = [
    'undo', 'redo', '|',
    'heading', '|', 'bold', 'italic', '|',
    'link', 'insertTable', 'mediaEmbed', '|',
    'bulletedList', 'numberedList', 'indent', 'outdent'
  ];

  //REF
  let editorRef = useRef(null);
  //STATE
  const [editorObj, setEditorObj] = useState(null);
  const [templateValue, setTemplateValue] = useState(null);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);

  useEffect(() => {
    if (tid != "none") {
      getTemplate();
    }
  }, [tid])

  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: tid
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      if (status) {
        setTemplateValue(result?.result?.content);
      }
    } catch (err) {
      console.log(err, "err");
    } finally { }
  }

  //EXTRACT - CKEDITOR DATA
  useEffect(() => {
    if (templateValue) {
      for (let i = 0; i < document.getElementsByClassName('ck').length; i++) {
        console.log(document.getElementsByClassName('ck')[i]?.innerHTML?.toString())
        setTempData(tempData => [...tempData, document.getElementsByClassName('ck')[i]?.innerHTML?.toString()]);
      }
    }
  }, [templateValue]);


  return (
    <>
      <Template_navbar identifier={"template2"} webinarId={id} templateId={tid} />

      {(templateValue) && (
        <div style={{ display: "none" }}> {ReactHtmlParser(templateValue)} </div>
      )}

      {(tid != "none" && tempData?.length !== 0) ? (
        <div className="templateData">
          <section className="temp_2 template2-font">
            <Container>
              <Row>
                <Col lg={12}>
                  <div class="temp_2_head">

                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `${tempData?.[0]?.toString()}`,
                      }}
                    />

                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `${tempData?.[1]?.toString()}`,
                      }}
                    />


                  </div>
                </Col>
              </Row>
              <div class="temp_2_box">
                <Row>
                  <Col lg={6}>
                    <div className="mb-4">
                      <div class="meeting-schedule">
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[2]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[3]?.toString()}`,
                          }}
                        />

                        {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                        {(meetingType != 0 && meetingType != 2) && (
                          <div
                            id="normal-countdown"
                            class="">
                            <div class="webinar_countdown">
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Days</p>
                              </div>
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Hours</p>
                              </div>
                              <span>:</span>
                              <div className="webinar_timer">
                                <h3>00 </h3>
                                <p>Minutes</p>
                              </div>
                              <span>:</span>
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Seconds</p>
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile1.png")} alt="profile1" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[4]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[5]?.toString()}`,
                          }}
                        />

                      </div>
                    </div>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile2.png")} alt="profile2" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[6]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[7]?.toString()}`,
                          }}
                        />

                      </div>
                    </div>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile3.png")} alt="profile3" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[8]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[9]?.toString()}`,
                          }}
                        />

                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
              <div class="btn-enroll">
                <a href="#" id="joinlink"><button>Enroll Now</button></a>
              </div>
            </Container>
          </section>
        </div>
      ) : ((tid == "none") && (
        <div className="templateData">
          <section className="temp_2 template2-font">
            <Container>
              <Row>
                <Col lg={12}>
                  <div class="temp_2_head">

                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: "<h2>Lorem Ipsum is simply dummy text</h2>",
                      }}
                    />

                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `<p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s.Lorem Ipsum is simply dummy text of the
                        printing and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>`,
                      }}
                    />


                  </div>
                </Col>
              </Row>
              <div class="temp_2_box">
                <Row>
                  <Col lg={6}>
                    <div className="mb-4">
                      <div class="meeting-schedule">
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h4>Lorem Ipsum is simply dummy text</h4>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<ul>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Lorem Ipsum is simply dummy text</li>
                      </ul>`,
                          }}
                        />

                        {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                        {(meetingType != 0 && meetingType != 2) && (
                          <div
                            id="normal-countdown"
                            class="">
                            <div class="webinar_countdown">
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Days</p>
                              </div>
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Hours</p>
                              </div>
                              <span>:</span>
                              <div className="webinar_timer">
                                <h3>00 </h3>
                                <p>Minutes</p>
                              </div>
                              <span>:</span>
                              <div className="webinar_timer">
                                <h3>00</h3>
                                <p>Seconds</p>
                              </div>
                            </div>
                          </div>
                        )}

                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile1.png")} alt="profile1" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h6>Sample Name 1</h6>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text.
                      </p>`,
                          }}
                        />


                      </div>
                    </div>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile2.png")} alt="profile2" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h6>Sample Name 2</h6>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: ` <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text.
                      </p>`,
                          }}
                        />


                      </div>
                    </div>
                    <div class="profile-details">
                      <img src={require("../../assets/images/template_img/profile3.png")} alt="profile3" />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h6>Sample Name 3</h6>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text.
                      </p>`,
                          }}
                        />


                      </div>
                    </div>
                  </Col>

                </Row>
              </div>
              <div class="btn-enroll">
                <a href="#" id="joinlink"><button>Enroll Now</button></a>
              </div>
            </Container>
          </section>
        </div>
      ))}



      <Innerfooter />
    </>
  );
};
export default Template_2;
