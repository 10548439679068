import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//API
import { getRegistartionTemplate } from "../../action/user.action";

function TemplateFullconfig(props) {
  //PROPS
  const { webinarId, templateId, meetingType } = props;
  // alert(meetingType)
  //SAVE / EDIT - CONFIG
  //SAVE = "none"
  const OPT = templateId == 0 ? 0 : templateId;
  console.log("template id:=", templateId, OPT);

  const [templateData, setTemplateData] = useState([]);
  const templatePreviewData = new Array(
    {
      screen: require("../../assets/images/template_img/modal_img_2.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_3.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_4.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_5.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_6.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_7.jpg"),
    }
  );
  const [activeBody, setActiveBody] = useState(OPT);
  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };
  const [show1, setShow1] = useState(false);
  const [template, setTemplate] = useState("");
  const handleClose_1 = () => setShow1(false);
  const handleShow1 = (index) => {
    setShow1(true);
    setTemplatePreview(index);
  };
  const [show2, setShow2] = useState(false);
  const [templatePreview, setTemplatePreview] = useState(0);
  const handleClose_2 = () => setShow2(false);
  const handleShow2 = (template) => {
    setShow2(true);
    setTemplate(template);
  };

  useEffect(() => {
    getTemplate();
  }, []);
  //GET - ALL TEMPLATE
  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: "",
      webinarId: webinarId,
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      console.log(status, result, "status, result");
      if (status) {
        setTemplateData(result?.result);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
    }
  };

  console.log(templateData, "templateDatatemplateData");
  return (
    <>
      <div className="dashbordrightbody-list1 registration_radio">
        <div className="registration_sec ">
          {/* <a
            href="#"
            className={`registration_body select ${activeBody === 1 ? "active" : ""}`}
            onClick={() => handleBodyClick(1)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_1.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>
              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={handleShow2}>Customize</a>
              </div>
            </div>
          </a> */}

          {templateData.map((obj, index) => {
            return (
              <>
                {/* <a
                  href="#"
                  className={`registration_body ${
                    obj.custamiz_id ? "select" : ""
                  } ${activeBody === 2 ? "active" : ""}`}
                  onClick={() => handleBodyClick(2)}
                > */}
                <a
                  href="#"
                  className={`registration_body ${
                    OPT === index
                      ? "select"
                      : OPT === obj?.custamiz_id
                      ? "select"
                      : ""
                  }`}
                  onClick={() => handleBodyClick(index)}
                >
                  <div className="registration_sec_image">
                    <Image
                      src={`${templatePreviewData[index]?.screen}`}
                      className="img-fluid m-auto d-block"
                      style={{ width: "328px", height: "150px" }}
                    />
                  </div>
                  <div className="registration_info mt-3">
                    <a
                      href={`/template-viewer/${webinarId}/${
                        obj.custamiz_id ? obj.custamiz_id : obj?._id
                      }/${meetingType}`}
                    >
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">View template</Tooltip>
                        }
                      >
                        <p>
                          Template name : <span>Vaf 00{index + 1}</span>{" "}
                          <img
                            src={require("../../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>
                      </OverlayTrigger>
                    </a>

                    <div className="registration_info_1">
                      <img
                        src={require("../../assets/images/registration_imgs/pencil_square.png")}
                        className="img-fluid"
                      />
                      <a
                        href={`/${obj?.identifier}/${webinarId}/${
                          obj.custamiz_id ? obj.custamiz_id : obj?._id
                        }/${meetingType}`}
                      >
                        Customize
                      </a>
                    </div>
                  </div>
                </a>
              </>
            );
          })}

          {/* <a
            href="#"
            className={`registration_body  ${activeBody === 2 ? "active" : ""}`}
            onClick={() => handleBodyClick(2)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_2.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href={`/template6/${webinarId}/none`}>Customize</a>
              </div>
            </div>
          </a> */}
          {/* 
          <a
            href="#"
            className={`registration_body  ${activeBody === 3 ? "active" : ""}`}
            onClick={() => handleBodyClick(3)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_3.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={()=>{handleShow2('template2')}}>Customize</a>
              </div>
            </div>
          </a>
          <a
            href="#"
            className={`registration_body  ${activeBody === 4 ? "active" : ""}`}
            onClick={() => handleBodyClick(4)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_4.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={()=>{handleShow2('template3')}}>Customize</a>
              </div>
            </div>
          </a>
          <a
            href="#"
            className={`registration_body  ${activeBody === 5 ? "active" : ""}`}
            onClick={() => handleBodyClick(5)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_5.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={()=>{handleShow2('template4')}}>Customize</a>
              </div>
            </div>
          </a>
          <a
            href="#"
            className={`registration_body  ${activeBody === 6 ? "active" : ""}`}
            onClick={() => handleBodyClick(6)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_6.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={()=>{handleShow2('template5')}}>Customize</a>
              </div>
            </div>
          </a>
          <a
            href="#"
            className={`registration_body  ${activeBody === 7 ? "active" : ""}`}
            onClick={() => handleBodyClick(7)}
          >
            <div className="registration_sec_image">
              <img
                src={require("../../assets/images/template_img/modal_img_7.jpg")}
                className="img-fluid"
                style={{ width: "328px", height: "150px" }}
              />
            </div>
            <div className="registration_info mt-3">
              <p>
                Template name : <span>Vaf 001</span>{" "}
                <img
                  src={require("../../assets/images/registration_imgs/eye_icon.png")}
                  className="img-fluid"
                  onClick={handleShow1}
                />{" "}
              </p>

              <div className="registration_info_1">
                <img
                  src={require("../../assets/images/registration_imgs/pencil_square.png")}
                  className="img-fluid"
                />
                <a href="javascript:void(0)" onClick={()=>{handleShow2('template6')}}>Customize</a>
              </div>
            </div>
          </a> */}
        </div>
      </div>

      <Modal
        show={show1}
        onHide={handleClose_1}
        size="lg"
        className="template_modal_2"
        enforceFocus="false"
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            class="img-fluid mb-1 mt-1"
            src={templatePreviewData?.[templatePreview]?.screen}
            alt="group image"
          />
        </Modal.Body>
      </Modal>
      {/* <Modal show={show2} onHide={handleClose_2} className="template_customize">
        <Modal.Body>
          {(template === "template1") && (
            <Template_1 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template2") && (
            <Template_2 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template3") && (
            <Template_3 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template4") && (
            <Template_4 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template5") && (
            <Template_5 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

          {(template === "template6") && (
            <Template_6 handleClose_2={handleClose_2} tid={OPT} id={webinarId} />
          )}

        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default TemplateFullconfig;
