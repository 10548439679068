

// import packages
import axios from "axios";

// import config
import config from "../lib/config";

// import utils
import { getAuthorizationHeader } from "../utils/cookies";
import { isEmpty } from "../helper/helper";


const instance = axios.create({
    baseURL: config.API_URL,
});

instance.interceptors.request.use(function (config) {
    const token = getAuthorizationHeader();
    if (!isEmpty(token)) {

        console.log(token,'token')
        config.headers.Authorization = token;
    }
    return config;
});

export default instance;
