
import Cookies from 'js-cookie'

export const setCookies = async (token, rememberMe) => {
    rememberMe ?
        Cookies.set('user_token', token, { expires: 1 }) :
        Cookies.set('user_token', token)
}

export const getAuthorizationHeader = () => {

    const tkn =Cookies.get('user_token');
    if (tkn) {
        return tkn
    } else {
        return false
    }
}

export const clearCookies = () => {
    Cookies.remove('user_token')
    window.location.reload()
}