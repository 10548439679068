import React from "react";
function Innerfooter() {
  return (
    <footer className="innerfooter">
      <p>
        Privacy Policy<span>|</span>Terms & Conditions
      </p>
      <p>Copy right © 2024, Vaffix All rights reserved</p>
    </footer>
  );
}
export default Innerfooter;
