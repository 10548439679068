import React from "react";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Innerfooter from "../components/inner-footer";
import { useParams, useNavigate } from "react-router-dom";
function LastMinuteChecklist2() {
  const { id } = useParams();
   return (
    <>
      <section className="LastMinuteChecklist2">
        <Container>
          <div className="mt-5 mb-5">
            <a href="/">
              <img
                src={logo}
                className="img-fluid brand_logo mx-auto d-block"
                alt="logo"
              />
            </a>
          </div>
          <div className="white-box LastMinuteChecklist2-box">
            <h5>Test your video and audio</h5>
            <div className="head-def">
              <img
                src={require("../assets/images/audio_check_img.png")}
                className="img-fluid"
              />{" "}
              <div>
                <h6>We need to check your audio and video connection.</h6>
                <p>
                  Don't worry, it's just for calibration purposes. You'll have
                  the option to switch them ON/OFF whenever you want.
                </p>
              </div>
            </div>
            <div className="LastMinuteChecklist1-btn">
              <Link to={`/last-minute/step3/${id}`} className="primary_btn">
                <img
                  src={require("../assets/images/chevron-right.png")}
                  height="16"
                  width="16"
                  className="img-fluid"           
                />{" "}
                Continue
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Innerfooter />
    </>
  );
}
export default LastMinuteChecklist2;
