
import React from "react";

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);



const LiveChart = (props) => {

    const { xAxisData, chartData } = props
    const data = {
        labels: xAxisData,
        datasets: [
            {
                label: 'My First Dataset',
                data: chartData,
                fill: false,
                borderColor: 'rgba(75,192,192,1)',
                tension: 0.1
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart'
            }
        }
    };

    return <Line data={data} options={options} />;
};

export default LiveChart;