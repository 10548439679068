import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Navinner from "../components/nav-inner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
const ActiveRegistrant = () => {
  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-2">
            <div className="dashbord-right-body">
              <div className="dashbordrightbody-header">
                <p>My webinars</p>
              </div>
              <div className="dashbordrightbody-body">
                <div className="dashbordrightbody-list1 analytics active_select">
                  <div className="analytics_select">
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Webinar test
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Webinar Test</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Session
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All Session</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Event
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All Event</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <Link to="#" className="primary_btn">
                      <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid"
                      />
                      Go
                    </Link>
                  </div>
                </div>
                <div className="dashbordrightbody-list1 analytics active_select">
                  <div className="analytics_select">
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Live room behaviour
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Webinar Test</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Replay room behaviour
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All Session</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div className="select_box">
                      <label for="exampleFormControlInput1" class="form-label">
                        Purchase behaviour
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All Event</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <Link to="#" className="primary_btn">
                      <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid"
                      />
                      Go
                    </Link>
                  </div>
                  <div className="active_entries mt-3">
                    <img
                      src={require("../assets/images/registration_imgs/i_icon.png")}
                      className="img-fluid"
                    />
                    <p>No entries found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default ActiveRegistrant;
