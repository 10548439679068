import React from "react";
import logo from "../assets/images/logo.svg";
import { Await, Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Container, Modal } from "react-bootstrap";
import Innerfooter from "../components/inner-footer";

import { useEffect, useState, useRef } from "react";
import { toastAlert } from "../utils/toast";
import {
  create_Meeting,
  getSingle_Webinardetails,
} from "../action/user.action";
import { decryptString, isEmpty } from "../helper/helper";
import PropagateLoader from "react-spinners/PropagateLoader";
import io from "socket.io-client";
import config from "../lib/config";
import { useSelector } from "react-redux";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

function LastMinuteChecklist3() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [mystream, setmystream] = useState(null);
  const [videoswitch, setvideo] = useState(true);
  const [audioswitch, setaudio] = useState(true);

  const [isLiveStrime, setIsLiveStream] = useState(false);
  const [webinar_Deatils, setWebinatDetails] = useState({});
  const [checkAudioVideo, setCheckAudioVideo] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showMeetingmodal, setshowMeetingmodal] = useState(false);

  const [redirectURL, setRedirectURL] = useState("");

  const handlemodalClose = () => {
    setshowMeetingmodal(false);
  };

  const handleRedirect = () => {
    window.location.href = redirectURL;
  };

  const userDetails = useSelector((state) => state.User);

  const myvideo = useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isStreaming, setIsStreaming] = useState(false);

  const mediaStreamRef = useRef({});
  const socketRef = useRef({});
  const mediaRecorderRef = useRef({});

  const getWebinarDetails = async () => {
    try {
      const ids = await decryptString(id, true);

      console.log(JSON.parse(ids), "ids");
      let payload = JSON.parse(ids);

      const { result, status } = await getSingle_Webinardetails(payload);

      console.log(result, status, "result, status");
      if (result.result.youtubeLiveStream || result.result.FacebookLiveStream) {
        await setIsLiveStream(true);
      }
      if (status) {
        setWebinatDetails(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWebinarDetails();
  }, []);

  useEffect(() => {
    const handleReconnect = async () => {
      console.log("Reconnecting to server...");
      if (socketRef.current[userDetails._id]) {
        socketRef.current[userDetails._id].disconnect();
      }
      socketRef.current[userDetails._id] = await io(config.API_URL, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 20000,
      });
      socketRef.current[userDetails._id].on("connect", () => {
        console.log("Connected to server");
      });

      socketRef.current[userDetails._id].on("connect_error", (err) => {
        console.error("Socket connection error:", err);
      });

      socketRef.current[userDetails._id].on("disconnect", (reason) => {
        console.log(`Socket disconnected. Reason: ${reason}`);
        setIsStreaming((prev) => ({ ...prev, [userDetails._id]: false }));
      });
    };

    handleReconnect();

    return () => {
      if (socketRef.current[userDetails._id]) {
        stopStreaming(userDetails);
      }
    };
  }, [userDetails]);

  // useEffect(() => {
  //   if (checkAudioVideo) {
  //     navigator.mediaDevices
  //       .getUserMedia({ video: true, audio: true })
  //       .then((stream) => {
  //         myvideo.current.srcObject = stream;
  //         myvideo.current.autoplay = true;
  //         myvideo.current.muted = false;
  //         setmystream(stream);
  //       });
  //   }
  // }, [checkAudioVideo]);

  const startStreaming = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          frameRate: { ideal: 30 },
        },
      });

      // const screenStream = await navigator.mediaDevices.getDisplayMedia({
      //   video: true,
      // });

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const combinedStream = new MediaStream([
        ...screenStream.getTracks(),
        ...audioStream.getTracks(),
      ]);

      mediaStreamRef.current[userDetails._id] = combinedStream;

      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "video/webm;codecs=vp9,opus",
        videoBitsPerSecond: 2500000, // Set maximum video bitrate
      });

      mediaRecorderRef.current[userDetails._id] = mediaRecorder;

      console.log(mediaRecorder, "mediaRecorderRef");

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("Sending media data, size:", event.data.size);
          event.data.arrayBuffer().then((buffer) => {
            if (socketRef.current[userDetails._id]) {
              socketRef.current[userDetails._id].emit("media-data", buffer);
            }
          });
        }
      };

      mediaRecorder.start(1000); // Send data every second

      let ids = await decryptString(id, true);

      const data = {
        user_id: userDetails._id,
        ids: JSON.parse(ids),
      };

      console.log(data,"datadatadatadata");

      socketRef.current[userDetails._id].emit(
        "start-stream",
        JSON.stringify(data)
      );

      setIsStreaming((prev) => ({ ...prev, [userDetails._id]: true }));
    } catch (err) {
      console.error("Error capturing screen and audio:", err);
    }
  };

  const stopStreaming = () => {
    if (socketRef.current[userDetails._id]) {
      socketRef.current[userDetails._id].emit("stop-stream");
      socketRef.current[userDetails._id].disconnect();
      delete socketRef.current[userDetails._id];
    }
    if (mediaRecorderRef.current[userDetails._id]) {
      mediaRecorderRef.current[userDetails._id].stop();
      delete mediaRecorderRef.current[userDetails._id];
    }
    if (mediaStreamRef.current[userDetails._id]) {
      mediaStreamRef.current[userDetails._id]
        .getTracks()
        .forEach((track) => track.stop());
      delete mediaStreamRef.current[userDetails._id];
    }
    setIsStreaming((prev) => ({ ...prev, [userDetails._id]: false }));
    window.location.href = `/webinar/`;

  };

  // const startStreaming = async () => {
  //   try {
  //     // Connect to the Socket.IO server

  //     let ids = await decryptString(id, true);

  //     console.log(JSON.parse(ids), "ids");

  //     const api = config.API_URL;
  //     console.log(api, "api");
  //     if (!socketRef.current) {
  //       socketRef.current = io(api);
  //     }

  //     console.log(socketRef, "api-------1");

  //     // Request screen and audio capture
  //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
  //       video: true,
  //     });
  //     const audioStream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     });

  //     // Combine screen and audio streams
  //     const combinedStream = new MediaStream([
  //       ...screenStream.getTracks(),
  //       ...audioStream.getTracks(),
  //     ]);

  //     mediaStreamRef.current = combinedStream;

  //     // Initialize MediaRecorder and start recording
  //     const mediaRecorder = new MediaRecorder(combinedStream, {
  //       mimeType: "video/webm;codecs=vp9,opus",
  //       videoBitsPerSecond: 2500000,
  //     });

  //     mediaRecorder.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         console.log("Sending media data, size:", event.data.size);
  //         // Convert Blob to ArrayBuffer for better compatibility
  //         event.data.arrayBuffer().then((buffer) => {
  //           // Emit media data to the server
  //           socketRef.current.emit('media-data', {
  //             userId: userDetails._id,
  //             buffer: buffer
  //           });
  //         }).catch(err => {
  //           console.error("Error converting data to ArrayBuffer:", err);
  //         });
  //       }
  //     };

  //     mediaRecorder.start(1000); // Send data every second

  //     // Notify server to start streaming

  //     const data = {
  //       user_id: userDetails._id,
  //       ids: JSON.parse(ids),
  //     };

  //     // socketRef.current.sockets.to(userDetails._id.toString()).emit("start-stream", JSON.stringify(data));
  //     socketRef.current.emit("start-stream", JSON.stringify(data)); // Replace with dynamic client ID if needed
  //     setIsStreaming(true);

  //     // Cleanup function
  //     return () => {
  //       console.log("stopsharing");
  //       mediaRecorder.stop();
  //       mediaStreamRef.current.getTracks().forEach((track) => track.stop());
  //       socketRef.current.emit("stop-stream", JSON.stringify(data));
  //       // socketRef.current.disconnect(JSON.stringify(data));
  //       socketRef.current = null;
  //     };
  //   } catch (err) {
  //     console.error("Error capturing screen and audio:", err);
  //   }
  // };

  // const stopStreaming = async () => {
  //   const api = config.API_URL;
  //   if (!socketRef.current) {
  //     socketRef.current = io(api);
  //   }
  //   let ids = await decryptString(id, true);

  //   const data = {
  //     user_id: userDetails._id,
  //     ids: JSON.parse(ids),
  //   };

  //   if (mediaStreamRef.current) {
  //     mediaStreamRef.current.getTracks().forEach((track) => track.stop());
  //   }

  //   console.log(data, "stopStreaming", socketRef);
  //   // if (socketRef.current) {
  //   socketRef.current.emit("stop-stream", JSON.stringify(data)); // Notify server to stop stream for the user
  //   // }

  //   setIsStreaming(false);
  // };

  const handleVideo = () => {
    if (videoswitch) {
      setvideo(false);
      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "video") {
          track.enabled = false;
        }
      });
    } else {
      setvideo(true);
      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "video") {
          track.enabled = true;
        }
      });
    }
  };

  const handleAudio = () => {
    if (audioswitch) {
      setaudio(false);
      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "audio") {
          track.enabled = false;
        }
      });
    } else {
      setaudio(true);
      mystream.getTracks().forEach(function (track) {
        if (track.readyState === "live" && track.kind === "audio") {
          track.enabled = true;
        }
      });
    }
  };

  const navigateBack = async () => {
    navigate("/webinar");
  };

  const stop_av = () => {
    mystream.getTracks().forEach(function (track) {
      if (track.readyState === "live" && track.kind === "video") {
        track.enabled = false;
      }
    });

    mystream.getTracks().forEach(function (track) {
      if (track.readyState === "live" && track.kind === "audio") {
        track.enabled = false;
      }
    });
  };

  const createMeeting = async () => {
    try {
      // stop_av();
      const ids = await decryptString(id, true);

      console.log(JSON.parse(ids), "ids");
      let payload = JSON.parse(ids);

      if (
        webinar_Deatils.youtubeLiveStream ||
        webinar_Deatils.FacebookLiveStream
      ) {
        alert(
          "Verify the YouTube or Facebook Live streams using their respective apps."
        );

        await startStreaming();
      }
      // setLoader(true);

      const { status, result } = await create_Meeting(payload);
      console.log(status, result, "status, result--------------------------");

      if (status) {
        if (result.success) {
          setLoader(false);
          console.log("saran");

          // window.open(result.result.moderator_URL, "_blank");
          // let link = document.createElement('a');
          // link.href = result.result.moderator_URL;
          // link.setAttribute('target', '_blank');
          // link.click();

          // return
         
            const url = result.result.moderator_URL;
            if (url && typeof url === "string") {
              
              const newWindow = window.open(url, "_blank");
              if (!newWindow) {
                
                setRedirectURL(url);
                showMeetingmodal(true);
              }
            } else {
              console.error("Invalid URL:", url);
            }
        
          // window.open(result.result.moderator_URL, "_blank");
          toastAlert("success", "Meeting started");
        } else {
          toastAlert("success", result.message);
          setLoader(false);
        }
      } else {
        // setLoader(false);

        // window.open(result.result.moderator_URL, "_blank");
        toastAlert("error", result.message);
        setLoader(false);
        // }
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      {loader && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#0d2a35"}
            loading={loader}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <section className="LastMinuteChecklist3">
        <Container>
          <div className="mt-5 mb-5">
            <a href="/">
              <img
                src={logo}
                className="img-fluid brand_logo mx-auto d-block"
                alt="logo"
              />
            </a>
          </div>
          <div className="white-box LastMinuteChecklist3-box">
            <h5>Test your video and audio</h5>
            <div className="head-def">
              <div className="box1">
                <Form.Label htmlFor="text1">Video</Form.Label>
                <Form.Control
                  type="text"
                  id="text1"
                  aria-describedby="passwordHelpBlock"
                  placeholder="FaceTime HD Camera"
                  disabled={true}
                />
                <Form.Label htmlFor="text1">Audio</Form.Label>
                <Form.Control
                  type="text"
                  id="text1"
                  placeholder="Default  - Internal Microphone"
                  aria-describedby="passwordHelpBlock"
                  disabled={true}
                />

                <button
                  className="btn_1"
                  onClick={() =>
                    isLiveStrime ? handleShow() : createMeeting()
                  }
                >
                  Confirm
                </button>
                {/* {
                  checkAudioVideo ? <>
                    <button className="btn_1" onClick={navigateBack}>Back</button>
                  </> :
                    <>
                      <p>Are you receiving and hearing your signal?</p>
                      <button className="btn_1" onClick={navigateBack}>No</button>
                      <button className="btn_2" type="button" onClick={() => setCheckAudioVideo(true)}>yes</button>
                    </>
                } */}
              </div>

              {!checkAudioVideo ? (
                <div className="box1">
                  <img
                    src={require("../assets/images/video-img.png")}
                    className="img-fluid vdo_img"
                  />{" "}
                  <div className="video_conf">
                    <p>James Anderson</p>
                    <img
                      src={require("../assets/images/icon_1.png")}
                      className="img-fluid"
                    />{" "}
                    <img
                      src={require("../assets/images/icon_2.png")}
                      className="img-fluid"
                    />{" "}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "400px",
                      marginTop: "10px",
                    }}
                  >
                    <video
                      ref={myvideo}
                      style={{
                        width: "500px",
                        height: "400px",
                        transform: "scaleX(-1)",
                      }}
                    ></video>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      onClick={handleVideo}
                      style={{
                        width: "3rem",
                        height: "3rem",
                        borderRadius: "50%",
                        border: "none",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                      title={videoswitch ? "Turn off video" : "Turn on video"}
                    >
                      {videoswitch ? (
                        <img
                          src={require("../assets/images/icon_1.png")}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={require("../assets/images/icon.png")}
                          className="img-fluid"
                        />
                      )}
                    </button>
                    <button
                      onClick={handleAudio}
                      style={{
                        marginLeft: "10px",
                        width: "3rem",
                        height: "3rem",
                        borderRadius: "50%",
                        border: "none",
                        backgroundColor: "grey",
                        color: "white",
                      }}
                      title={audioswitch ? "Turn off audio" : "Turn on audio"}
                    >
                      {audioswitch ? (
                        <img
                          src={require("../assets/images/icon_2.png")}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={require("../assets/images/icon_3.png")}
                          className="img-fluid"
                        />
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <Innerfooter />
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                    <div>

                    </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage">
                <h2 className="mb-3">Go Live</h2>
                <p>Click to start the live stream</p>
              </div>
              <center>
                <button onClick={createMeeting} className="primary_btn mt-3">
                  Start Live
                </button>

                <button onClick={stopStreaming} className="primary_btn mt-3">
                  Stop
                </button>
              </center>
              {/*
                </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showMeetingmodal}
        onHide={handlemodalClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="finish_model"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className=" instant-meeting-head">
            <div className=" instant-meeting-box completed_meeting">
              {/* <div className="white-box">
                    <div>

                    </div> */}
              <center></center>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <p>Webinar Session Has Completed</p> */}
                <img
                  src={require("../assets/images/group.png")}
                  className="img-fluid"
                />{" "}
              </div>
              <div className="finishpage">
                <h2 className="mb-3">Go Live</h2>
                <p>Click to start the live stream</p>
              </div>
              <center>
                <p>
                  Unable to open the URL in a new tab.{" "}
                  <button onClick={handleRedirect}>
                    Click here to proceed
                  </button>
                </p>
              </center>
              {/*
                </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default LastMinuteChecklist3;
