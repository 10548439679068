import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Innerfooter from "../../components/inner-footer";
import Template_navbar from "../../components/template-navbar";
import ReactHtmlParser from 'react-html-parser';
//EDITOR
import { CKEditor, useCKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  InlineEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
//API
import { getRegistartionTemplate } from "../../action/user.action";
const Template_6 = () => {
  const { id, tid, meetingType } = useParams();

  //EDITOR
  let plugins = [
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo
  ];
  let toolbar = [
    'undo', 'redo', '|',
    'heading', '|', 'bold', 'italic', '|',
    'link', 'insertTable', 'mediaEmbed', '|',
    'bulletedList', 'numberedList', 'indent', 'outdent'
  ];

  //REF
  let editorRef = useRef(null);
  //STATE
  const [editorObj, setEditorObj] = useState(null);
  const [templateValue, setTemplateValue] = useState(null);
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);

  useEffect(() => {
    if (tid != "none") {
      getTemplate();
    }
  }, [tid])

  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: tid
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      if (status) {
        setTemplateValue(result?.result?.content);
      }
    } catch (err) {
      console.log(err, "err");
    } finally { }
  }

  //EXTRACT - CKEDITOR DATA
  useEffect(() => {
    if (templateValue) {
      for (let i = 0; i < document.getElementsByClassName('ck').length; i++) {
        console.log(document.getElementsByClassName('ck')[i]?.innerHTML?.toString())
        setTempData(tempData => [...tempData, document.getElementsByClassName('ck')[i]?.innerHTML?.toString()]);
      }
    }
  }, [templateValue]);
  return (
    <>
      <Template_navbar identifier={"template6"} webinarId={id} templateId={tid} />

      {(templateValue) && (
        <div style={{ display: "none" }}> {ReactHtmlParser(templateValue)} </div>
      )}

      {(tid != "none" && tempData?.length !== 0) ? (
        <div className="templateData">
          <section class="temp_6_head1 template6-font">

            <Container>

              <div class="temp_6_sub">
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `${tempData?.[0]?.toString()}`,
                  }}
                />
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `${tempData?.[1]?.toString()}`,
                  }}
                />

                <a href="#" id="joinlink"><button>Enroll Now</button></a>
              </div>
            </Container>
          </section>
          <section class="temp_6_head2 template6-font">
            <Container>
              <Row>
                <Col lg={6}>
                  <div class="temp_6_head2_sub">
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `${tempData?.[2]?.toString()}`,
                      }}
                    />
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `${tempData?.[3]?.toString()}`,
                      }}
                    />
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `${tempData?.[4]?.toString()}`,
                      }}
                    />


                  </div>
                </Col>
                <Col lg={6}>
                  <div class="temp_6_head2_sub2">
                    <div class="countdown_temp6">
                      {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                      {(meetingType != 0 && meetingType != 2) && (
                      <div
                        id="normal-countdown"
                      >
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Days</p>
                        </div>
                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Hours</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3> 00 </h3>
                          <p>Minutes</p>
                        </div>

                        <div className="webinar_timer time-sec">
                          <h3>00</h3>
                          <p>Seconds</p>
                        </div>
                      </div>
                      )}
                      {/* <a href="#" id="joinlink"><button>Enroll Now</button></a> */}
                    </div>
                    <div class="profile_details">
                      <img
                        src={require("../../assets/images/template_img/temp6-profile1.png")}
                        alt="temp6-profile1"
                      />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[5]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[6]?.toString()}`,
                          }}
                        />

                      </div>
                    </div>
                    <div class="profile_details">
                      <img
                        src={require("../../assets/images/template_img/temp6-profile2.png")}
                        alt="temp6-profile2"
                      />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[7]?.toString()}`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `${tempData?.[8]?.toString()}`,
                          }}
                        />

                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Container>

          </section>

        </div>
      ) : ((tid == "none") && (
        <div className="templateData">

          <section class="temp_6_head1 template6-font">

            <Container>

              <div class="temp_6_sub">
                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `<h2>Lorem Ipsum is simply dummy text</h2>`,
                  }}
                />

                <CKEditor
                  editor={InlineEditor}
                  config={{
                    toolbar: toolbar,
                    plugins: plugins,
                    initialData: `<p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s.Lorem Ipsum is simply dummy text of the
                            printing and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s.
                          </p>`,
                  }}
                />


                <a href="#" id="joinlink"><button>Enroll Now</button></a>
              </div>
            </Container>
          </section>
          <section class="temp_6_head2 template6-font">
            <Container>
              <Row>
                <Col lg={6}>
                  <div class="temp_6_head2_sub">
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `<h4>Lorem Ipsum is simply dummy text</h4>`,
                      }}
                    />
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `<p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s.Lorem Ipsum is simply
                            dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text ever since
                            the 1500s.
                          </p>`,
                      }}
                    />
                    <CKEditor
                      editor={InlineEditor}
                      config={{
                        toolbar: toolbar,
                        plugins: plugins,
                        initialData: `<ul>
                            <li>
                              Sed ut perspiciatis unde omnis iste natus error sit
                              voluptatem accusantium doloremque laudantium, totam rem
                              aperiam
                            </li>
                            <li>
                              Sed ut perspiciatis unde omnis iste natus error sit
                              voluptatem accusantium doloremque laudantium, totam rem
                              aperiam
                            </li>
                            <li>
                              Sed ut perspiciatis unde omnis iste natus error sit
                              voluptatem accusantium doloremque laudantium, totam rem
                              aperiam
                            </li>
                          </ul>`,
                      }}
                    />



                  </div>
                </Col>
                <Col lg={6}>
                  <div class="temp_6_head2_sub2">
                    <div class="countdown_temp6">
                      {/* TIMER NOT SHOW - FOR (INSTANT/ALWAYSON) */}
                      {(meetingType != 0 && meetingType != 2) && (
                        <div
                          id="normal-countdown"
                        >
                          <div className="webinar_timer time-sec">
                            <h3>00</h3>
                            <p>Days</p>
                          </div>
                          <div className="webinar_timer time-sec">
                            <h3>00</h3>
                            <p>Hours</p>
                          </div>

                          <div className="webinar_timer time-sec">
                            <h3> 00 </h3>
                            <p>Minutes</p>
                          </div>

                          <div className="webinar_timer time-sec">
                            <h3>00</h3>
                            <p>Seconds</p>
                          </div>
                        </div>
                      )}

                      {/* <a href="#" id="joinlink"><button>Enroll Now</button></a> */}
                    </div>
                    <div class="profile_details">
                      <img
                        src={require("../../assets/images/template_img/temp6-profile1.png")}
                        alt="temp6-profile1"
                      />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h6>Sample Name 1</h6>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text.
                          </p>`,
                          }}
                        />


                      </div>
                    </div>
                    <div class="profile_details">
                      <img
                        src={require("../../assets/images/template_img/temp6-profile2.png")}
                        alt="temp6-profile2"
                      />
                      <div>
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<h6>Sample Name 1</h6>`,
                          }}
                        />
                        <CKEditor
                          editor={InlineEditor}
                          config={{
                            toolbar: toolbar,
                            plugins: plugins,
                            initialData: `<p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text.
                          </p>`,
                          }}
                        />


                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Container>

          </section>

        </div>
      ))}






      <Innerfooter />
    </>
  );
};
export default Template_6;
