import React, { useEffect, useState } from "react";
import { Completed_meeting_Details } from "../../action/user.action";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { Container } from "react-bootstrap";
import endmeeting from "../../assets/images/Completed_meeting.svg";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
    margin: "0 auto",
    borderColor: "red",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginLeft: "-32px",
    marginTop: "-32px",
    zIndex: "99999",
};

const CompletedMeeting = () => {
    const { id } = useParams();

    console.log(id, "webinar  analytics");

    const navigate = useNavigate()
    const [infoData, setInfoData] = useState({})

    const [initialState, setInitialState] = useState({});
    const [chartData, setChartData] = useState([]);
    const [xAxisData, setXaxisData] = useState([]);
    const [loader, setLoader] = useState(false);



    const getCompleted_Meetings = async () => {
        try {
            const payload = {
                id: id,
            };

            setLoader(true)
            const { status, result } = await Completed_meeting_Details(payload);

            console.log(status, result, "sakthi ----- dashboard");
            if (status) {

                setTimeout(() => {
                    window.location.reload()
                    // setInfoData({ ...result.dashboardLink })
                    // setLoader(false)
                }, 3000)

                // for (let i of result.dashboardDate.result) {
                //     console.log(i, 'iiiiiiiii')
                //     chartData.push(i.users)
                //     xAxisData.push(i.intervalStart)
                // }
                // result.result.session_Length = result.dashboardDate.session_length
                // setInitialState(result.result)
            } else {

                // setTimeout(() => {
                setInfoData({ ...result.dashboardLink })
                setLoader(false)
                // }, 3000)
            }
        } catch (error) {
            // setInfoData({ ...result.dashboardLink })


            console.log(error);
        }
    };

    console.log(chartData, xAxisData, "xAxisData");
    useEffect(() => {
        if (id) {
            getCompleted_Meetings();
        }
    }, [id]);

    const InfoHandler = () => {
        console.log("WebinarAnalytics", infoData);

        // return
        navigate("/WebinarAnalytics", { state: infoData });
    }
    return (
        <div>
            {loader && (
                <div className="loadBefore">
                    <PropagateLoader
                        color={"#0d2a35"}
                        loading={loader}
                        cssOverride={override}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
            <Container>
                <div className=" instant-meeting-head">
                    <div className="mt-5 mb-5">
                        <a href="/">
                            <img
                                src={logo}
                                className="img-fluid brand_logo mx-auto d-block"
                                alt="logo"
                            />
                        </a>
                    </div>
                    <div className="white-box1 instant-meeting-box completed_meeting">
                        {/* <div className="white-box">
                            <div>

                            </div> */}
                        <center>
                            <h2 className="mb-3">Webinar Details Updated Soon...</h2>
                        </center>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {/* <p>Webinar Session Has Completed</p> */}

                            <img src={endmeeting} />
                        </div>
                        <center>
                            {
                                loader ?
                                    <button className="primary_btn mt-3">
                                        Waiting ....
                                    </button> :
                                    <button onClick={() => InfoHandler()} className="primary_btn mt-3">
                                        Go to dashboard
                                    </button>
                            }

                        </center>
                        {/* </div> */}
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default CompletedMeeting;
