import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";
import Navinner from "../components/nav-inner";
import Innerfooter from "../components/inner-footer";
import Innerpagenavbar from "../components/innerpage-navbar";
const RegistrationPage = () => {
  const [activeBody, setActiveBody] = useState(null);

  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />
          <div className="dashbord-right mt-5 mb-5">
            <div className="dashbord-right-header">
              <p>My webinars</p>
              <Link to="#" className="primary_btn">
                <img
                  src={require("../assets/images/chevron-right.png")}
                  height="16"
                  width="16"
                  className="img-fluid"
                />{" "}
                Add Webinar
              </Link>
            </div>
            <div className="dashbord-right-body">
              <div className="dashbordrightbody-header">
                <p>Webinars</p>
                <div>
                  <ul>
                    <li>
                      <a href="#">Edit</a>
                    </li>
                    <li>
                      <a href="#">Clone</a>
                    </li>
                    <li>
                      <a href="#">Delete</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dashbordrightbody-body">
                <div className="dashbordrightbody-list1 registration_radio">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Enable Split Testing
                    </label>
                    <img
                      src={require("../assets/images/registration_imgs/question_mark.png")}
                      className="img-fluid"
                    />
                  </div>
                  <div className="registration_sec mt-5">
                    <a
                      href="#"
                      className={`registration_body  ${
                        activeBody === 1 ? "active" : ""
                      }`}
                      onClick={() => handleBodyClick(1)}
                    >
                      <img
                        src={require("../assets/images/registration_imgs/user_img.png")}
                        className="img-fluid"
                      />
                      <div className="registration_info mt-3">
                        <p>
                          Template name : <span>Vaf 001</span>{" "}
                          <img
                            src={require("../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>

                        <div className="registration_info_1">
                          <img
                            src={require("../assets/images/registration_imgs/pencil_square.png")}
                            className="img-fluid"
                          />
                          <p>Customize</p>
                        </div>
                      </div>
                    </a>

                    <a
                      href="#"
                      className={`registration_body  ${
                        activeBody === 2 ? "active" : ""
                      }`}
                      onClick={() => handleBodyClick(2)}
                    >
                      <img
                        src={require("../assets/images/registration_imgs/user_img_1.png")}
                        className="img-fluid"
                      />
                      <div className="registration_info mt-3">
                        <p>
                          Template name : <span>Vaf 001</span>{" "}
                          <img
                            src={require("../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>

                        <div className="registration_info_1">
                          <img
                            src={require("../assets/images/registration_imgs/pencil_square.png")}
                            className="img-fluid"
                          />
                          <p>Customize</p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className={`registration_body  ${
                        activeBody === 3 ? "active" : ""
                      }`}
                      onClick={() => handleBodyClick(3)}
                    >
                      <img
                        src={require("../assets/images/registration_imgs/user_img.png")}
                        className="img-fluid"
                      />
                      <div className="registration_info mt-3">
                        <p>
                          Template name : <span>Vaf 001</span>{" "}
                          <img
                            src={require("../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>

                        <div className="registration_info_1">
                          <img
                            src={require("../assets/images/registration_imgs/pencil_square.png")}
                            className="img-fluid"
                          />
                          <p>Customize</p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className={`registration_body  ${
                        activeBody === 4 ? "active" : ""
                      }`}
                      onClick={() => handleBodyClick(4)}
                    >
                      <img
                        src={require("../assets/images/registration_imgs/user_img_1.png")}
                        className="img-fluid"
                      />
                      <div className="registration_info mt-3">
                        <p>
                          Template name : <span>Vaf 001</span>{" "}
                          <img
                            src={require("../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>

                        <div className="registration_info_1">
                          <img
                            src={require("../assets/images/registration_imgs/pencil_square.png")}
                            className="img-fluid"
                          />
                          <p>Customize</p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className={`registration_body  ${
                        activeBody === 5 ? "active" : ""
                      }`}
                      onClick={() => handleBodyClick(5)}
                    >
                      <img
                        src={require("../assets/images/registration_imgs/user_img.png")}
                        className="img-fluid"
                      />
                      <div className="registration_info mt-3">
                        <p>
                          Template name : <span>Vaf 001</span>{" "}
                          <img
                            src={require("../assets/images/registration_imgs/eye_icon.png")}
                            className="img-fluid"
                          />{" "}
                        </p>

                        <div className="registration_info_1">
                          <img
                            src={require("../assets/images/registration_imgs/pencil_square.png")}
                            className="img-fluid"
                          />
                          <p>Customize</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <Innerfooter />
      </div>
    </>
  );
};
export default RegistrationPage;
