import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Footer from "../components/Footer";
import {
  faEye,
  faEyeSlash,
  faSkating,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

function NewUser() {
  return (
    <>
      <section className="login_page newuser">
        <Container>
          <a href="/">
            <img
              src={logo}
              className="img-fluid brand_logo mx-auto d-block"
              alt="logo"
            />
          </a>

          <div className="login_content mt-5">
            <h4 className="text-center">Session Title</h4>
            <InputGroup className="mb-3 mt-5">
              <InputGroup.Text id="basic-addon1">
                {" "}
                <img
                  src={require("../assets/images/user_img.png")}
                  className="img-fluid"
                />
              </InputGroup.Text>
              <Form.Control
                placeholder="Username"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {" "}
                <img
                  src={require("../assets/images/envelope_img.png")}
                  className="img-fluid"
                />
              </InputGroup.Text>
              <Form.Control
                placeholder="User email"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {" "}
                <img
                  src={require("../assets/images/clock_img.png")}
                  className="img-fluid"
                />
              </InputGroup.Text>
              <Form.Control
                placeholder="Session time"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                {" "}
                <img
                  src={require("../assets/images/presenter_img.png")}
                  className="img-fluid"
                />
              </InputGroup.Text>
              <Form.Control
                placeholder="Presenter name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <button to="" className="second-btn">
              Start Session
            </button>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default NewUser;
