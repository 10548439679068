



import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMeetingDetails, getWebinarDetails } from "../../action/user.action";


const initialState = {}

export const Meeting_Details = createAsyncThunk('Meeting', async (id) => {
    const { status, result } = await getMeetingDetails()
    if (status) {
        return result.result
    }
})

const MeetingSlice = createSlice({
    name: "Meeting",
    initialState,
    reducers: {
        setMeeting: (state, action) => {
            state = action.payload
            return state
        },
    },
    extraReducers: (build) => {
        build.addCase(Meeting_Details.fulfilled, (state, action) => {
            state = action.payload
            return state
        })
    }
})

export const { setMeeting } = MeetingSlice.actions
export default MeetingSlice.reducer