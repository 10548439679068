import React from "react";
import logo from "../assets/images/logo.svg";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Innerfooter from "../components/inner-footer";
function LastMinuteChecklist1() {
  const { id } = useParams();
 
  return (
    <>
      <section className="LastMinuteChecklist1">
        <Container>
          <div className="mt-5 mb-5">
            <a href="/">
              <img
                src={logo}
                className="img-fluid brand_logo mx-auto d-block"
                alt="logo"
              />
            </a>
          </div>
          <div className="white-box LastMinuteChecklist1-box">
            <h5>Last-minute checklist!</h5>
            <div className="head-def">
              <Row>
                <Col lg="6" md="6">
                  {" "}
                  <div className="box1">
                    <div className="def1">
                      <img
                        src={require("../assets/images/img1.png")}
                        className="img-fluid"
                      />{" "}
                      <div>
                        <h6>Free up resources</h6>
                        <p>
                          Optimize your computer's performance by closing all
                          other applications, browser tabs, and unnecessary
                          programs.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" md="6">
                  {" "}
                  <div className="box1">
                    <div className="def1">
                      <img
                        src={require("../assets/images/img2.png")}
                        className="img-fluid"
                      />{" "}
                      <div>
                        <h6>Wear headphones</h6>
                        <p>
                          Use headphones to reduce audio feedback between your
                          speakers and microphone.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" md="6">
                  {" "}
                  <div className="box1">
                    <div className="def1">
                      <img
                        src={require("../assets/images/img3.png")}
                        className="img-fluid"
                      />{" "}
                      <div>
                        <h6>Internet connection</h6>
                        <p>
                          Limit usage of your home internet connection.
                          Streaming Netflix, gaming, and downloads could
                          decrease your bandwidth.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6" md="6">
                  {" "}
                  <div className="box1">
                    <div className="def1">
                      <img
                        src={require("../assets/images/img4.png")}
                        className="img-fluid"
                      />{" "}
                      <div>
                        <h6>Avoid distractions</h6>
                        <p>
                          Minimize distractions and background noise during your
                          broadcast: silence your phone, Skype, email, and close
                          the door!
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="LastMinuteChecklist1-btn">
              <Link to={`/last-minute/step2/${id}`} className="primary_btn">
                <img
                  src={require("../assets/images/chevron-right.png")}
                  height="16"
                  width="16"
                  className="img-fluid"                 
                />{" "}
                Continue
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <Innerfooter />
    </>
  );
}
export default LastMinuteChecklist1;
