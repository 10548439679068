import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button, InputGroup, Col, Row, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";

import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  Create_Webinar,
  create_Meeting,
  delete_Webinar,
  searchMeetings,
  sendOtpEmail,
  updateUserDeatails,
  updateYoutube_key,
  user_resetPassword,
  verifyOtp,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString, isEmpty } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";
import { getUserDetails } from "../redux/features/userdetails";

const initalform = {
  facebookkey: "",
  youtubekey: "",
  firstname: "",
  lastname: "",
  emailid: "",
  otp: "",
};

const InnerSettings = () => {
  const user_deatils = useSelector((state) => state.User);

  const dispatch = useDispatch();
  console.log(user_deatils, "user_deatils");

  const [initialValue, setintialValue] = useState(initalform);
  const [streamKey, setStreamKey] = useState({
    youtubekey: "",
    facebookkey: "",
  });
  const [isOTPSent, setIsOtpSent] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [timer, setTimer] = useState(300);
  const [error, setError] = useState();
  const [isDisableResend, setIsDisableResend] = useState(true); // Disable resend button state
  const [resendTimer, setResendTimer] = useState(120); // 2-minute resend disable timer

  const { youtubekey, firstname, lastname, emailid, facebookkey } =
    initialValue;

  // OTP Countdown Timer (5 minutes)
  useEffect(() => {
    let countdown;
    if (isOTPSent) {
      countdown = setInterval(() => {
        setTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(countdown);
            setIsOtpSent(false);
            return 0; // Stop the countdown at 0
          }
          return prevTime - 1;
        });
      }, 1000); // Countdown interval of 1 second
    } else {
      setTimer(300); // Reset timer when OTP is not sent
    }

    // Cleanup interval on component unmount or when `isOTPSent` changes
    return () => clearInterval(countdown);
  }, [isOTPSent]);


  // Resend button disable timer (2 minutes)
  useEffect(() => {
    let resendCountdown;
    if (isDisableResend) {
      resendCountdown = setInterval(() => {
        setResendTimer((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(resendCountdown);
            setIsDisableResend(false); // Re-enable the button after 2 minutes
            return 120; // Reset resend timer
          }
          return prevTime - 1;
        });
      }, 1000); // Countdown interval of 1 second
    }

    // Cleanup interval on component unmount or when `isDisableResend` changes
    return () => clearInterval(resendCountdown);
  }, [isDisableResend]);



  // Format timer to MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (user_deatils) {
      setintialValue({
        ...initialValue,
        facebookkey: user_deatils.FaceBook_key,
        youtubekey: user_deatils.YouTube_key,
        firstname: user_deatils.userName,
        lastname: user_deatils.lastName,
        emailid: user_deatils.email,
      });
    }
  }, []);

  const updateYoutube = async () => {
    try {
      if (isDisable) {
        return false;
      }

      console.log(streamKey, "streamKeystreamKeystreamKey");
      if (streamKey.youtubekey == "" && streamKey.facebookkey == "") {
        toastAlert("error", "Key required");
        return false;
      }

      console.log(streamKey, "streamKey");
      if (
        user_deatils.YouTube_key == streamKey.youtubekey &&
        user_deatils.FaceBook_key == streamKey.facebookkey
      ) {
        return false;
      }

      setIsDisable(true);
      const { result, status } = await updateYoutube_key(streamKey);

      console.log(result, status, "result, status");
      if (status) {
        toastAlert("success", result.message);

        dispatch(getUserDetails());
        setintialValue({
          ...initialValue,
          facebookkey:
            streamKey.facebookkey == ""
              ? user_deatils.FaceBook_key
              : streamKey.facebookkey,
          youtubekey:
            streamKey.youtubekey == ""
              ? user_deatils.YouTube_key
              : streamKey.youtubekey,
        });
        setIsDisable(false);
      } else {
        toastAlert("error", result.message);
        setIsDisable(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const profileValidation = async () => {
    const error = {};

    console.log(initialValue.emailid, "initialValue");

    // Ensure fields are not empty or undefined
    if (!initialValue.emailid?.trim() && initialValue.emailid == '') {
      error.emailid = "Email is required";
    }
    if (!initialValue.firstname?.trim()) {
      error.firstname = "Firstname is required";
    }
    if (!initialValue.lastname?.trim()) {
      error.lastname = "Lastname is required";
    }

    return error;
  };


  const updateUser = async () => {
    try {
      console.log("isDisable", isDisable)
      if (isDisable) {
        return false;
      }
      console.log("after");
      const error = await profileValidation();

      console.log(error, "error______11111");
      if (Object.keys(error).length !== 0) {
        setError(error);
        return false;
      }
      setIsDisable(true);

      if (isOTPSent) {
        const { result, status } = await verifyOtp(initialValue);
        console.log(result, status, "result, status");
        console.log(status, "status--215");
        console.log(result, "result--216");
        if (status) {
          toastAlert("success", result.message);
          setIsOtpSent(false);
          dispatch(getUserDetails());
          setIsDisable(false);
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      }
      else {
        const { result, status } = await updateUserDeatails(initialValue);
        console.log(result, status, "result, status");
        if (status) {
          if (result.isEmailSent) {
            setIsOtpSent(true);
            toastAlert("success", result.message);
            setIsDisable(false);
          } else {
            // userupdated same 
            setIsOtpSent(false);
            console.log("setIsOtpSent", setIsOtpSent)
            toastAlert("success", result.message);
            dispatch(getUserDetails());
            setIsDisable(false);
          }
        } else {
          toastAlert("error", result.message);
          setIsDisable(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsDisable(false);
    }
  };

  // Handle Resend OTP
  const resend_otp = async () => {
    try {
      setIsDisableResend(true); // Disable resend button for 2 minutes
      setResendTimer(120); // Start 2-minute countdown
      setTimer(300); // Reset the OTP timer to 5 minutes

      const { result, status } = await sendOtpEmail(initialValue); // Simulate your resend OTP API call
      if (status) {
        toastAlert("success", result.message);
        setIsOtpSent(true); // Start the OTP timer again
      } else {
        toastAlert("error", result.message);
      }
    } catch (error) {
      console.log(error);
      toastAlert("error", "Failed to resend OTP");
      setIsDisableResend(false); // Re-enable button in case of error
    }
  };



  const streamchangeHadler = (e) => {
    const { id, value } = e.target;
    setStreamKey({ ...streamKey, [id]: value });
  };

  const changeHadler = (e) => {
    const { id, value } = e.target;
    console.log(id, value, "----226")
    setintialValue({ ...initialValue, [id]: value });
  };


  //password changes
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const InitialFormValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formValue, setFormValue] = useState(InitialFormValue);

  const [passmessage, setpassmessage] = useState("");

  //password icon
  const [showOPwd, setShowOPwd] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showconfPwd, setShowconfPwd] = useState(false);

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    var errormsg = {};
    if (!strengthChecks.length) {
      errormsg = "Password must contain at least 8 characters";
    } else if (!strengthChecks.hasUpperCase) {
      errormsg = "Password must contain at least one uppercase letter";
    } else if (!strengthChecks.hasLowerCase) {
      errormsg = "Password must contain at least one lowercase letter";
    } else if (!strengthChecks.hasDigit) {
      errormsg = "Password must contain at least one numeric character";
    } else if (!strengthChecks.hasSpecialChar) {
      errormsg = "Password must contain at least one special character";
    }
    setpassmessage(errormsg);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
          ? "Medium"
          : "Weak";

    // setPassword(passwordValue);
    // setProgress(`${(verifiedList.length / 5) * 100}%`);
    // setMessage(strength);

    // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (formValue.newPassword.trim() == "") {
        validateError.newPassword = "New password is required";
      }

      if (formValue.oldPassword.trim() == "") {
        validateError.oldPassword = "Old password is required";
      }
      else if (Object.keys(passmessage).length != 0) {
        validateError.newPassword = passmessage;
      }
      if (formValue.confirmPassword.trim() == "") {
        validateError.confirmPassword = "Confirm password is required";
      } else if (formValue.newPassword != formValue.confirmPassword) {
        validateError.confirmPassword =
          "Password and confirm password doesn't match";
      }

      setError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  //model
  const [showPassword, setShowPassword] = useState(false);



  const changesHadler = async (e) => {
    try {
      const { id, value } = e.target;
      console.log(id, value);

      setFormValue({ ...formValue, [id]: value });

      if (id == "newPassword") {
        handlePassword(value);
      }

    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordShow = () => {
    console.log("clik")
    setShowPassword(true)
  }
  const handlePasswordClose = () => setShowPassword(false);

  const submit_Handler = async (e) => {

    try {
      console.log("formValue===", formValue)
      setloading(true);
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      console.log("err si", errorsSize);
      if (errorsSize == 0) {
        setloading(true);
        const { status, result } = await user_resetPassword(formValue);

        if (status) {
          toastAlert("success", result.message);
          handlePasswordClose();
        } else {
          setError(result.message);
          toastAlert("error", result.message)
        }
      }
    } catch (error) {
      toastAlert("error", "Something went wrong,please try again later");
    } finally {
      setloading(false);
    }
  };

  console.log("isDisable--------", isDisable)
  console.log("isOTPSent", isOTPSent)
  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard">
          <Innerpagenavbar />

          <div className="dashbord-right  mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding">
              <div className="inner_home_shadow p-4">
                <div className="inner_home_shadow inner_settings_shadow inner_settings_alignment">
                  <div className="settings_display">
                    {youtubekey == "" ? (
                      <Row>
                        <Col lg="4" md="4" sm="4" className="p-0 m-auto ">
                          <div className="img_position_basic">
                            <Form.Control
                              id="image"
                              type="file"
                              className="img_upload"
                              accept=".png,.jpg,.jpeg,.webp"
                            // value={ }
                            />

                            <>
                              <img
                                src={require("../assets/images/profile-img2.png")}
                                className="img-fluid image-dp"
                                width={"120px"}
                                height={"120px"}
                              />
                              <img
                                src={require("../assets/images/edit.png")}
                                class=" img-fluid image_edit"
                              />
                            </>
                          </div>
                        </Col>

                        <Col lg="8" md="8" sm="8" className=" m-auto ">
                          <Row>
                            <Col lg="12" className="profile_info mb-3">
                              <Form.Label>YouTube Streaming Key</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter YouTube key"
                                id="youtubekey"
                                // value={youtubekey}
                                onChange={streamchangeHadler}
                              />
                            </Col>

                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={updateYoutube}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid me-2"
                              />{" "}
                              Update
                            </Link>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="inner_profile_settings">
                        <div className="reset_code">
                          <img
                            src={require("../assets/images/u_tube.png")}
                            className="img-fluid"
                          />
                          <p>YouTube Streaming Key (Do not share)</p>
                          <img
                            src={require("../assets/images/icon_4.png")}
                            className="img-fluid"
                          />
                        </div>
                        <h5 className="mt-3">{youtubekey}</h5>
                        <button
                          onClick={() =>
                            setintialValue({
                              ...initialValue,
                              youtubekey: "",
                            })
                          }
                        >
                          Reset code
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="settings_display">
                    {facebookkey == "" ? (
                      <Row>
                        <Col lg="4" md="4" sm="4" className="p-0 m-auto ">
                          <div className="img_position_basic">
                            <Form.Control
                              id="image"
                              type="file"
                              className="img_upload"
                              accept=".png,.jpg,.jpeg,.webp"
                            // value={ }
                            />

                            <>
                              <img
                                src={require("../assets/images/profile-img2.png")}
                                className="img-fluid image-dp"
                                width={"120px"}
                                height={"120px"}
                              />
                              <img
                                src={require("../assets/images/edit.png")}
                                class=" img-fluid image_edit"
                              />
                            </>
                          </div>
                        </Col>

                        <Col lg="8" md="8" sm="8" className=" m-auto ">
                          <Row>
                            <Col lg="12" className="profile_info mb-3">
                              <Form.Label> Facebook streaming key</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Facebook key "
                                id="facebookkey"
                                // value={youtubekey}
                                onChange={streamchangeHadler}
                              />
                            </Col>

                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={updateYoutube}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid me-2"
                              />{" "}
                              Update
                            </Link>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="inner_profile_settings">
                        <div className="reset_code">
                          <img
                            src={require("../assets/images/facebook-1.png")}
                            className="img-fluid"
                          />
                          <p>
                            {" "}
                            FB-1666829050782784-0-AbzWsWjvkmj5Ixyz(Do not share)
                          </p>
                          <img
                            src={require("../assets/images/icon_4.png")}
                            className="img-fluid"
                          />
                        </div>
                        <h5 className="mt-3">{facebookkey}</h5>
                        <button
                          onClick={() =>
                            setintialValue({
                              ...initialValue,
                              facebookkey: "",
                            })
                          }
                        >
                          Reset code
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="inner_fields mt-4"></div>
                </div>

                <div className="inner_home_shadow inner_settings_shadow">
                  <div className="inner_settings_head">
                    <p>Contact details</p>
                    <button to="#" className="primary_btn" onClick={updateUser}>
                      <img
                        src={require("../assets/images/chevron-right.png")}
                        height="16"
                        width="16"
                        className="img-fluid me-2"
                      />{" "}
                      Update
                    </button>
                  </div>
                  <div className="inner_fields mt-4 mb-3">
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/person_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="First name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={firstname}
                          id="firstname"
                          onChange={changeHadler}
                        />
                      </InputGroup>

                      <div className="error_msg ">
                        {error && error.firstname && (
                          <span style={{ color: "red" }}>
                            {error.firstname}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/person_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Last name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={lastname}
                          id="lastname"
                          onChange={changeHadler}
                        />
                      </InputGroup>

                      <div className="error_msg ">
                        {error && error.lastname && (
                          <span style={{ color: "red" }}>{error.lastname}</span>
                        )}
                      </div>
                    </div>
                    <div className="error_input">
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/mail_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Email address"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={emailid}
                          id="emailid"
                          onChange={(e) => changeHadler(e)}
                        />
                      </InputGroup>
                      <div className="error_msg ">
                        {error && error.emailid && (
                          <span style={{ color: "red" }}>{error.emailid}</span>
                        )}
                      </div>
                    </div>
                    {/* {isOTPSent && (
                      <InputGroup className=" input-box-1 ">
                        <InputGroup.Text id="basic-addon3">
                          <img
                            src={require("../assets/images/mail_img.png")}
                            className="img-fluid"
                          />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="OTP"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          // value={emailid}
                          id="otp"
                          onChange={changeHadler}
                        />
                      </InputGroup>
                    )} */}


                    {/* {isOTPSent && (
                      <>
                        <InputGroup className="input-box-1">
                          <InputGroup.Text id="basic-addon3">
                            <img
                              src={require("../assets/images/mail_img.png")}
                              className="img-fluid"
                              alt="Mail Icon"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="OTP"
                            aria-label="OTP"
                            aria-describedby="basic-addon2"
                            id="otp"
                            onChange={changeHadler}
                          />

                          <InputGroup.Text style={{ fontSize: '14px', color: '#001221' }}>
                            {formatTime(timer)}
                          </InputGroup.Text>
                        </InputGroup>
                        <button to="#" className="primary_btn" onClick={resend_otp}>
                          Resend
                        </button>


                      </>
                    )} */}

                    {isOTPSent && (
                      <>
                        <InputGroup className="input-box-1">
                          <InputGroup.Text id="basic-addon3">
                            <img
                              src={require("../assets/images/mail_img.png")}
                              className="img-fluid"
                              alt="Mail Icon"
                            />
                          </InputGroup.Text>
                          <Form.Control
                          type="number"
                            placeholder="OTP"
                            aria-label="OTP"
                            aria-describedby="basic-addon2"
                            id="otp"
                            onChange={changeHadler}
                          />

                          <InputGroup.Text style={{ fontSize: '14px', color: '#001221' }}>
                            {formatTime(timer)} {/* Display the OTP countdown */}
                          </InputGroup.Text>
                        </InputGroup>

                        {/* Resend Button (only show after 2 minutes) */}
                        {!isDisableResend && (
                          <button
                            className="primary_btn"
                            onClick={resend_otp}
                          >
                            Resend {/* Show only when not disabled */}
                          </button>
                        )}

                        {/* {isDisableResend && (
                          <p> {formatTime(resendTimer)}</p>
                        )} */}
                      </>
                    )}

                  </div>
                </div>

                <div className="inner_home_shadow inner_settings_shadow">
                  <div className="inner_settings_head">
                    <p>Sign In </p>
                  </div>

                  {/* <div className="inner_settings_details mt-4">
                    <p>Sign-In Email</p>
                    <span>sat***@gmail.com</span>
                    <Link to="#" className="primary_btn">
                      Edit
                    </Link>
                  </div> */}
                  <div className="inner_settings_details mt-3">
                    <p>Sign-In Password</p>
                    <span>***</span>

                    <Link onClick={handlePasswordShow} className="primary_btn">
                      Edit
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showPassword} onHide={handlePasswordClose}>
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title" >Password Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="12">
              <div className="text-box">
                <label
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  Old Password <span style={{ color: "red" }}>*</span>
                </label>
                <div className="inner-accMain">
                  <InputGroup className="mb-3 input-box-1 ">
                    <Form.Control
                      type={showOPwd ? "text" : "password"}
                      class="form-control"
                      id="oldPassword"
                      placeholder="Enter your Old Password"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={changesHadler}

                    />

                    <InputGroup.Text id="basic-addon2">
                      {showOPwd ? (
                        <i
                          className="fa-regular fa-eye"
                          onClick={() => setShowOPwd(false)}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash"
                          onClick={() => setShowOPwd(true)}
                        ></i>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  {error && error.oldPassword ? (
                    <span style={{ color: "red" }}>{error.oldPassword}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="text-box">
                <label
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  New Password <span style={{ color: "red" }}>*</span>
                </label>
                <div className="inner-accMain">
                  <InputGroup className="mb-3 input-box-1 ">
                    <Form.Control
                      type={showPwd ? "text" : "password"}
                      class="form-control"
                      id="newPassword"
                      placeholder="Enter your New Password"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={changesHadler}

                    />

                    <InputGroup.Text id="basic-addon2">
                      {showPwd ? (
                        <i
                          className="fa-regular fa-eye"
                          onClick={() => setShowPwd(false)}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash"
                          onClick={() => setShowPwd(true)}
                        ></i>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  {error && error.newPassword ? (
                    <span style={{ color: "red" }}>{error.newPassword}</span>
                  ) : (
                    ""
                  )}

                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="text-box">
                <label
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </label>
                <div className="inner-accMain">
                  <InputGroup className="mb-3 input-box-1 ">
                    <Form.Control
                      type={showconfPwd ? "text" : "password"}
                      class="form-control"
                      id="confirmPassword"
                      placeholder="Enter your Confirm Password"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={changesHadler}

                    />

                    <InputGroup.Text id="basic-addon2">
                      {showconfPwd ? (
                        <i
                          className="fa-regular fa-eye"
                          onClick={() => setShowconfPwd(false)}
                        ></i>
                      ) : (
                        <i
                          className="fa-regular fa-eye-slash"
                          onClick={() => setShowconfPwd(true)}
                        ></i>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                {error && error.confirmPassword ? (
                  <span style={{ color: "red" }}>{error.confirmPassword}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Link className="primary_btn" onClick={() => submit_Handler(formValue)}>
            Submit
          </Link>

        </Modal.Footer>
      </Modal>
    </>
  );
};
export default InnerSettings;
